import { createReducer } from '@reduxjs/toolkit'
import { SET_PROMOCODES } from './actions'
import { SUCCESS, REQUEST, FAILURE } from '../../constants/statuses'

const initialState = {
  promocodes: [],
  pagination: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  isLoading: false,
  isError: false
}

export const promocodesReducer = createReducer(initialState, {
  [SET_PROMOCODES[SUCCESS]]: (state, action) => {
    state.promocodes = action.payload.data.codes
    state.pagination.amount = action.payload.data.amount
    state.pagination.page = action.payload.paging.page
    state.pagination.perPage = action.payload.paging.count
    state.isLoading = false
  },

  [SET_PROMOCODES[REQUEST]]: (state, action) => {
    state.isError = false
    state.isLoading = true
  },

  [SET_PROMOCODES[FAILURE]]: (state, action) => {
    state.isLoading = false
    state.isError = true
  }
})
