import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _getClaims,
  _editClaim
} from 'api/http/claims'

export const SET_CLAIMS = createRequestStatuses('SET_CLAIMS')
export const getClaims = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_CLAIMS,
    _getClaims,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const editClaim = (data, callbackSuccess, callbackError) => 
  makeSimpleRequest(
    _editClaim,
    data,
    callbackSuccess,
    callbackError,
  )