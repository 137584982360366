import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import editIcon from 'assets/edit.png'

const Status = ({ status }) => {
  switch (status) {
    case 'created':
      return <span style={{ color: palette.status.sended }}>Создан</span>
    case 'approved':
      return <span style={{ color: palette.status.done }}>Принят</span>
    case 'rejected':
      return <span style={{ color: palette.status.failed }}>Отклонен</span>
    default:
      return 'Статус недоступен'
  }
}

const CashRequests = ({ 
  classes, 
  requests, 
  openEdit
}) => {

  const formatDate = (date) => {
    const createdAt = date.split('T')[0]
    const newDate = createdAt.split('-')[2] + '.' + createdAt.split('-')[1] + '.' + createdAt.split('-')[0]

    return newDate
  }

  return requests.map((request) => (
    <TableRow key={request.id}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {formatDate(request.createdAt)}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {request.specialist.fullName}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {request.specialist.speciality.title}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {request.amount}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        <Status status={request.status} />
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openEdit(request)
          }}
        >
          <img src={editIcon} alt="edit" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(CashRequests)