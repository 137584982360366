import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { palette } from 'styles/colors'
import * as Yup from 'yup'
import Input from 'components/Input'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'

const promocodeSchema = Yup.object().shape({
  code: Yup.string()
    .min(3, 'Промокод слишком короткий')
    .max(8, 'Промокод слишком длинный')
    .required('Это обязательное поле'),
  title: Yup.string(),
  isActive: Yup.boolean()
})

const Promocode = ({ classes, editedObj, handleSubmit, isLoading }) => {
  const formik = useFormik({
    initialValues: {
      code: editedObj ? editedObj.code : '',
      title: editedObj ? editedObj.title : '',
      isActive: editedObj ? editedObj.isActive : false
    },
    validationSchema: promocodeSchema,
    onSubmit: (...props) => handleSubmit(...props)
  })

  useEffect(() => {
    document.title = editedObj
      ? `Редактирование ${editedObj.fullName}`
      : 'Создание промокода'
    return () => (document.title = 'Промокоды')
  }, [])

  return (
    <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
      {editedObj ? <div className={classes.line}>{editedObj.code}</div> : <></>}
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          Промокод:
        </Typography>
        <Input
          formik={formik}
          fieldName={'code'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          Название:
        </Typography>
        <Input
          formik={formik}
          fieldName={'title'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      {editedObj && (
        <Checkbox label="Активен" formik={formik} fieldName={'isActive'} />
      )}
      <div className={classes.buttons}>
        <Button
          text={'Сохранить'}
          otherStyles={{
            width: '220px',
            height: '40px',
            backgroundColor: palette.white,
            border: `1px solid ${palette.blue}`,
            borderRadius: '10px',
            color: palette.blue,
            marginRight: '1em',
            marginLeft: '1em'
          }}
          type={'submit'}
          loader={isLoading}
        />
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  line: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  buttons: {
    textAlign: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    },
    'label + .MuiInput-formControl': {
      marginTop: 0
    },
    '.MuiFormHelperText-root': {
      margin: '-21px 0',
      marginTop: '3px'
    }
  }
})

export default withStyles(styles)(Promocode)
