import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import { useDispatch, useSelector } from 'react-redux'
import NoContent from 'components/NoContent'
import TableWrapper from 'components/Table'
import Menu from 'components/Menu'
import { columnsCash } from 'data/columns'
import ModalWrapper from 'components/Modals/ModalWrapper'
import ChangeStatus from 'components/Modals/ChangeStatus'
import {
  getClaimsItems,
  getIsLoadingClaims,
  getClaimsPagination
} from 'redux/selectors/claims'
import {
  editClaim as editClaimAction,
  getClaims
} from 'redux/modules/claims/actions'

const CashRequests = ({ classes }) => {
  const dispatch = useDispatch()

  const claims = useSelector(getClaimsItems)
  const isRequestsLoading = useSelector(getIsLoadingClaims)
  const claimsPagination = useSelector(getClaimsPagination)

  const fetchClaims = (page = claimsPagination.page) => {
    dispatch(
      getClaims(
        {},
        () => {},
        () => {},
        {
          page,
          count: claimsPagination.perPage
        }
      )
    )
  }

  useEffect(() => {
    fetchClaims(claimsPagination.page)
  }, [])

  const [isOpenedEdit, setIsOpenedEdit] = useState(false)
  const [cash, setCash] = useState(null)

  const openEdit = (cash) => {
    setCash(cash)
    setIsOpenedEdit(true)
  }

  const closeEdit = () => {
    setIsOpenedEdit(false)
  }

  const [isLoading, setIsLoading] = useState(false)

  const editStatus = (values) => {
    setIsLoading(true)
    dispatch(
      editClaimAction(
        {
          id: values.id,
          status: values.status
        },
        (data) => {
          fetchClaims(claimsPagination.page)
          closeEdit()
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  return (
    <div className={classes.wrapper}>
      <Menu />
      <div className={classes.table}>
        {claims.length > 0 || isRequestsLoading ? (
          <>
            <div className={classes.tableWrapper}>
              <TableWrapper
                page={claimsPagination.page}
                count={claimsPagination.perPage}
                amount={claimsPagination.amount}
                items={claims}
                onChangePage={fetchClaims}
                isLoading={isRequestsLoading}
                columns={columnsCash}
                tableType={'cash'}
                actions={{
                  openEdit: openEdit
                }}
              />
            </div>
          </>
        ) : (
          <>
            <NoContent text={'Нет запросов на вывод средств'} />
          </>
        )}
      </div>
      <ModalWrapper
          title={'Редактировать статус'}
          isOpened={isOpenedEdit}
          onClose={() => closeEdit()}
          style={{ width: '800px' }}
        >
          <ChangeStatus 
            handleSubmit={editStatus} 
            editedObj={cash} 
            isLoading={isLoading} 
          />
        </ModalWrapper>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  tableWrapper: {
    padding: '0 2em'
  }
})

export default withStyles(styles)(CashRequests)
 