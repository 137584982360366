import axios from '../index'

export const _getNotifications = (data, paging) =>
  axios.get(`/messages?page=${paging.page}&count=${paging.count}`, data)

export const _createNotification = (data) => axios.post('/messages', data)

export const _uploadBanner = (data) =>
  axios.post(`/advertisings`, data.file, {
    'Content-Type': 'multipart/form-data'
  })