import axios from '../index'

export const _getPharmacies = (data, paging) => axios.get(`/pharmacies/?page=${paging.page}&count=${paging.count}&phrase=${paging.phrase}`, data)

export const _createPharmacy = (data) => axios.post(`/pharmacies/`, data)

export const _editPharmacy = (data) => axios.patch(`/pharmacies/${data.id}`, data)

export const _deletePharmacy = (data) => axios.delete(`/pharmacies/${data.id}`, data)

export const _uploadMedia = (data) =>
  axios.post(`/pharmacies/${data.id}/image`, data.image, {
    'Content-Type': 'multipart/form-data'
  })

export const _uploadFile = (data) => axios.post(`/pharmacies/create_by_file`, data.file, {
  'Content-Type': 'multipart/form-data'
})