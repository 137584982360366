import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import deleteIcon from 'assets/delete.png'
import Button from 'components/Button'

const Patients = ({ 
  classes, 
  admissions, 
  openDetails,
  openDelete
}) => {
  return admissions.map((admission) => (
    <TableRow key={admission.id}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {admission.receptionDate}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        <Button
          text={'Открыть медкарту приема'}
          otherStyles={{ background: 'none', boxShadow: 'none', margin: '0' }}
          onClick={() => openDetails({ ...admission })}
        />
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {admission.specialist.fullName.split(' ')[0] + ' ' + admission.specialist.fullName.split(' ')[1]}
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(Patients)
