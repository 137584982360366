import React, { useState } from 'react'
import { IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Menu, MenuItem, Divider, MenuList } from '@material-ui/core'

const MenuOptions = ({
  openMedcard,
  openReviews,
  openComplaints,
  openRefferal,
  openMark,
  reciption,
  openDeclineReception,
  openChangeTime
}) => {
  const [selectElement, setSelectElement] = useState(null)
  const open = Boolean(selectElement)

  const options = openReviews
    ? [
        {
          title: 'Открыть медкарту',
          value: 'medcard'
        },
        {
          title: 'Открыть отзыв',
          value: 'review'
        },
        {
          title: 'Открыть жалобу',
          value: 'complaints'
        },
        {
          title: 'Открыть направление',
          value: 'refferal'
        },
        {
          title: 'Поставить оценку',
          value: 'adminMark'
        },
        {
          title: 'Перенести прием',
          value: 'changeReception'
        },
        {
          title: 'Отмена приема',
          value: 'declineReception'
        }
      ]
    : [
        {
          title: 'Открыть медкарту',
          value: 'medcard'
        }
      ]

  const handleClick = (event) => {
    setSelectElement(event.currentTarget)
  }

  const handleClose = (event, reciption) => {
    event.target.getAttribute('value') === 'medcard'
      ? openMedcard(reciption)
      : event.target.getAttribute('value') === 'review'
      ? openReviews(reciption)
      : event.target.getAttribute('value') === 'declineReception'
      ? openDeclineReception(reciption)
      : event.target.getAttribute('value') === 'complaints'
      ? openComplaints(reciption)
      : event.target.getAttribute('value') === 'refferal'
      ? openRefferal(reciption)
      : event.target.getAttribute('value') === 'adminMark'
      ? openMark(reciption)
      : event.target.getAttribute('value') === 'changeReception'
      ? openChangeTime(reciption)
      : setSelectElement(null)
    setSelectElement(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={selectElement}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200
          }
        }}
      >
        {options.map((option, index) => (
          <div key={index}>
            <MenuItem
              value={option.value}
              key={option.title}
              onClick={(event) => handleClose(event, { ...reciption })}
            >
              {option.title}
            </MenuItem>
            {index === 0 ? <Divider style={{ margin: '0 16px' }} /> : ''}
          </div>
        ))}
      </Menu>
    </>
  )
}
const styles = () => ({
  '@global': {
    '.MuiMenu-list': {
      border: `1px solid ${palette.blue}`,
      borderBottomWidth: '2px',
      borderRadius: '4px'
    }
  }
})

export default withStyles(styles)(MenuOptions)
