import React, { useState, useRef, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { palette } from 'styles/colors'
import * as Yup from 'yup'
import Button from 'components/Button'
import Input from 'components/Input'
import InputMask from 'react-input-mask'

const specSchema = Yup.object().shape({
  isLimited: Yup.string().required('Это обязательное поле'),
  price: Yup.number()
    .integer()
    .min(1, 'Некорректная цена')
    .required('Это обязательное поле'),
  duration: Yup.number()
    .integer()
    .min(1, 'Некорректный срок действия')
    .required('Это обязательное поле')
})

const specSchemaLimited = specSchema.shape({
  receptionLimit: Yup.number()
    .integer()
    .min(1, 'Некорректное количество')
    .required('Это обязательное поле')
})

const Subscription = ({ classes, editedObj, handleSubmit, isLoading }) => {
  let typeField = ''
  const [typeValue, setTypeValue] = useState(
    editedObj ? editedObj.isLimited : true
  )
  const [isLimited, setIsLimited] = useState(editedObj ? editedObj?.isLimited : true)

  const formik = useFormik({
    initialValues: {
      isLimited,
      price: editedObj ? editedObj?.price : '',
      duration: editedObj ? editedObj?.duration : '',
      receptionLimit: editedObj ? editedObj?.receptionLimit : ''
    },
    validationSchema: !isLimited ? specSchema : specSchemaLimited,
    onSubmit: (...props) => handleSubmit(...props)
  })

  const onChange = (data, type) => {
    formik.setFieldValue(type, data.target.value)
  }

  const fields = [
    {
      id: 'isLimited',
      title: 'Тип абонемента:'
    },
    {
      id: 'price',
      title: 'Стоимость абонемента:'
    },
    {
      id: 'duration',
      title: 'Срок действия (мес):'
    },
    {
      id: 'receptionLimit',
      title: 'Количество приемов:'
    }
  ]

  const handleChange = (event) => {
    let value = event.target.value === 'true' ? true : false
    formik.setFieldValue('isLimited', value)
    formik.setFieldValue('receptionLimit', 0)
    setIsLimited(value)
    setTypeValue(value)
  }

  return (
    <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
      {fields.map((field) => (
        <div className={classes.field} key={field.id}>
          {field.id !== 'isLimited' ? (
            <>
              <Typography
                className={
                  field.id === 'receptionLimit' && !formik.values.isLimited
                    ? classes.titleDisable
                    : classes.title
                }
                variant="subtitle1"
                style={
                  field.id === 'price'
                    ? { width: '350px' }
                    : field.id === 'duration'
                    ? { width: '300px' }
                    : { width: '310px' }
                }
              >
                {field.title}
              </Typography>
              <InputMask
                mask={'99999'}
                maskPlaceholder={''}
                value={
                  formik.values[field.id]
                    ? formik.values[field.id].toString()
                    : ''
                }
                onChange={(data) => onChange(data, (typeField = field.id))}
                disabled={
                  field.id === 'receptionLimit' && !formik.values.isLimited
                }
              >
                <Input
                  formik={formik}
                  fieldName={field.id}
                  variant={'standard'}
                  otherStyles={{ width: '100%' }}
                  standard
                />
              </InputMask>
            </>
          ) : (
            <>
              <Typography
                className={classes.title}
                variant="subtitle1"
                style={{ width: '150px' }}
              >
                {field.title}
              </Typography>
              <FormControl>
                <RadioGroup
                  name="type"
                  className={classes.group}
                  value={typeValue}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Лимитный"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Безлимитный"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        </div>
      ))}
      <div className={classes.buttons}>
        <Button
          text={'Сохранить'}
          otherStyles={{
            width: '280px',
            height: '45px',
            backgroundColor: palette.blueButton,
            color: palette.white,
            margin: '2em 1em'
          }}
          type={'submit'}
          loader={isLoading}
        />
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end'
    // justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  titleDisable: {
    margin: '0 20px 10px 0',
    color: palette.grey
  },
  buttons: {
    textAlign: 'center'
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2px'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    },
    '.MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'inset'
    },
    '.MuiRadio-colorSecondary.Mui-checked': {
      color: palette.blue
    },
    'label + .MuiInput-formControl': {
      marginTop: 0
    },
    '.MuiFormHelperText-root': {
      margin: '-21px 0',
      marginTop: '3px'
    }
  }
})

export default withStyles(styles)(Subscription)
