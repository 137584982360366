import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Calendar from 'arui-feather/calendar'
import { palette } from 'styles/colors'
import { Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/Button'
import config from 'api/config'

const ExportReceptions = ({ classes }) => {
  const [datesRange, setDatesRange] = useState([])
  const [startDate, setStartDate] = useState('00.00.00')
  const [endDate, setEndDate] = useState('00.00.00')
  const [month, setMonth] = useState(Date.now())

  const calendarChange = (date) => {
    datesRange.length < 2
      ? setDatesRange((prevValues) => [...prevValues, date])
      : setDatesRange([date])
  }

  const formatDate = (date) => {
    let formatSelectedDay =
      (date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()) +
      '.' +
      (date.getMonth() + 1 >= 10
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '.' +
      date.getFullYear().toString().substring(2, 4)

    return formatSelectedDay
  }

  useEffect(() => {
    if (datesRange[0]) {
      let selectedStartDate = new Date(datesRange[0])
      setStartDate(formatDate(selectedStartDate))
    } else {
      setStartDate('00.00.00')
    }
    if (datesRange[1]) {
      let selectedEndDate = new Date(datesRange[1])
      setEndDate(formatDate(selectedEndDate))
    } else {
      setEndDate('00.00.00')
    }
  }, [datesRange])

  const saveReception = () => {
    let dateStart =
      '20' +
      startDate.split('.')[2] +
      '-' +
      startDate.split('.')[1] +
      '-' +
      startDate.split('.')[0]
    let dateEnd =
      '20' +
      endDate.split('.')[2] +
      '-' +
      endDate.split('.')[1] +
      '-' +
      endDate.split('.')[0]

    let a = document.createElement('a')
    a.href = `${config.axios.baseURL}/download_list_receptions?date_start=${dateStart}&date_end=${dateEnd}`
    a.download = `receptions.xlsx`
    a.target = '_blank'
    a.click()
  }

  return (
    <form onSubmit={saveReception}>
      <div className={classes.wrapper}>
        <div className={classes.calendarBox}>
          <Typography className={classes.calendarBoxTitle} variant="subtitle1">
            Выберите диапазон дат
          </Typography>
          <Calendar
            value={datesRange[0]}
            onValueChange={(newDate) => calendarChange(newDate)}
            onMonthChange={(newMonth) => setMonth(newMonth)}
            eventDays={datesRange}
            className={classes.calendar}
            month={month}
            showToday={true}
          />
        </div>
        <div className={classes.dateRange}>
          <Typography
            style={{ fontWeight: 'bold', marginBottom: '10px' }}
            variant="subtitle1"
          >
            Диапазон дат:
          </Typography>
          <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
            c {startDate} по {endDate}
          </Typography>
        </div>
        <div className={classes.buttons}>
          <Button
            text={'Экспортировать'}
            otherStyles={{
              width: '280px',
              height: '45px',
              backgroundColor: palette.blueButton,
              color: palette.white,
              margin: '2em 1em'
            }}
            type={'submit'}
          />
        </div>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    width: '320px'
  },
  calendarBox: {
    paddingTop: '20px',
    borderRadius: '20px',
    width: '100%',
    boxShadow: '0px 2px 14px rgba(101, 101, 101, 0.1)'
  },
  calendarBoxTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '10px'
  },
  calendar: {
    borderRadius: '20px'
  },
  dateRange: {
    marginTop: '15px'
  },
  '@global': {
    '.calendar__day_state_today': {
      border: `1px solid ${palette.blueButton} !important`
    },
    '.calendar__day_state_current': {
      background: `${palette.blueButton} !important`
    },
    '.calendar_theme_alfa-light .calendar__day_state_today': {
      boxShadow: 'none'
    },
    '.calendar_theme_alfa-light .calendar__name_month, .calendar_theme_alfa-light .calendar__name_year':
      {
        backgroundColor: palette.calendar
      },
    '.calendar__name_month, .calendar__name_year': {
      fontSize: '15px'
    },
    '.calendar__event': {
      visibility: 'hidden'
    },
    '.calendar__day_event': {
      background: `${palette.blueButton} !important`,
      color: palette.white
    }
  }
})

export default withStyles(styles)(ExportReceptions)
