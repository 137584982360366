import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { palette } from 'styles/colors'
import * as Yup from 'yup'
import Input from 'components/Input'
import Button from 'components/Button'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Select from 'components/Select'

const specSchema = Yup.object().shape({
  login: Yup.string()
    .min(4, 'Логин слишком короткий')
    .max(40, 'Логин слишком длинный')
    .required('Это обязательное поле'),
  password: Yup.string()
    .min(4, 'Пароль слишком короткий')
    .max(20, 'Пароль слишком длинный'),
  fullName: Yup.string()
    .min(5, 'ФИО слишком короткое')
    .max(40, 'ФИО слишком длинное'),
  status: Yup.string()
    .min(4, 'Статус слишком короткий')
    .max(10, 'Статус слишком длинный')
    .required('Это обязательное поле')
})

const Moderator = ({ classes, editedObj, handleSubmit, isLoading }) => {
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      login: editedObj ? editedObj.login : '',
      password: editedObj ? editedObj.password : '',
      fullName: editedObj ? editedObj.fullName : '',
      status: editedObj ? editedObj.status : ''
    },
    validationSchema: specSchema,
    onSubmit: (...props) => handleSubmit(...props)
  })

  const selectValues = [
    {
      id: 0,
      name: 'Модератор',
      value: 'moderator'
    },
    {
      id: 1,
      name: 'Оператор колл-центра',
      value: 'call'
    }
  ]

  useEffect(() => {
    document.title = editedObj
      ? `Модератор ${editedObj.fullName}`
      : 'Создание модератора'
    return () => (document.title = 'Модераторы')
  }, [])

  return (
    <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
      {editedObj ? (
        <div className={classes.line}>{editedObj.fullName}</div>
      ) : (
        <></>
      )}
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          Логин:
        </Typography>
        <Input
          formik={formik}
          fieldName={'login'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          ФИО:
        </Typography>
        <Input
          formik={formik}
          fieldName={'fullName'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      {!editedObj ? (
        <div className={classes.field}>
          <Typography className={classes.title} variant="subtitle1">
            Пароль:
          </Typography>
          <Input
            formik={formik}
            fieldName={'password'}
            variant={'standard'}
            otherStyles={{ width: '100%' }}
            type={showPassword ? 'text' : 'password'}
            standard
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ color: palette.blue }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
      ) : (
        <></>
      )}
      <div className={classes.field}>
        <Typography
          className={classes.title}
          variant="subtitle1"
          style={{ margin: '0 20px 5px 0' }}
        >
          Статус:
        </Typography>
        <Select
          formik={formik}
          selectValues={selectValues}
          fieldName={'status'}
          otherStyles={{ width: '100%', margin: 0 }}
          variant={'standard'}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          text={'Сохранить'}
          otherStyles={{
            width: '220px',
            height: '40px',
            backgroundColor: palette.white,
            border: `1px solid ${palette.blue}`,
            borderRadius: '10px',
            color: palette.blue,
            marginRight: '1em',
            marginLeft: '1em'
          }}
          type={'submit'}
          loader={isLoading}
        />
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  line: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  buttons: {
    textAlign: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    },
    'label + .MuiInput-formControl': {
      marginTop: 0
    },
    '.MuiFormHelperText-root': {
      margin: '-21px 0',
      marginTop: '3px'
    }
  }
})

export default withStyles(styles)(Moderator)
