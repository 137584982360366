import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _getSubscriptions,
  _createSubscription,
  _editSubscription
} from 'api/http/subscriptions'

export const SET_SUBSCRIPTIONS = createRequestStatuses('SET_SUBSCRIPTIONS')
export const getSubscriptions = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_SUBSCRIPTIONS,
    _getSubscriptions,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const createSubscription = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _createSubscription, 
    data, 
    callbackSuccess, 
    callbackError
  )

export const editSubscription = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _editSubscription, 
    data, 
    callbackSuccess, 
    callbackError
  )

export const SET_SWITCH = 'SET_SWITCH'