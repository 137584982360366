import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import deleteIcon from 'assets/delete.png'
import Button from 'components/Button'

const Patients = ({ 
  classes, 
  patients, 
  openDelete, 
  openEdit 
}) => {
  return patients.map((patient) => (
    <TableRow key={patient.id}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.fullName}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.phone}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.isSigned ? (
          <span style={{ color: palette.status.done }}>Оплачен</span>
        ) : (
          <span style={{ color: palette.status.failed }}>Не оплачен</span>
        )}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        <Button
          text={'Подробнее'}
          otherStyles={{ background: 'none', boxShadow: 'none', margin: '0' }}
          onClick={() => openEdit({ ...patient })}
        />
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openDelete(patient)
          }}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(Patients)
