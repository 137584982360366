import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import editIcon from 'assets/edit.png'

const Courses = ({ classes, appointments, openEdit }) => {
  return appointments.map((appointment) => (
    <TableRow key={appointment.id} className={classes.wrapper}>
      <TableCell
        style={{ width: '150px', borderColor: palette.black }}
        align="center"
      >
        {appointment.receptionTime.split(':')[0] +
          ':' +
          appointment.receptionTime.split(':')[1]}
      </TableCell>
      <TableCell
        style={{ width: '150px', borderColor: palette.black }}
        align="center"
      >
        {appointment.user.fullName
          ? appointment.user.fullName.split(' ').length > 1
            ? appointment.user.fullName.split(' ')[0] +
              ' ' +
              appointment.user.fullName.split(' ')[1]
            : appointment.user.fullName
          : 'Нет имени'}
      </TableCell>
      <TableCell
        style={{ width: '150px', borderColor: palette.black }}
        align="center"
      >
        {appointment.user.phone}
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton onClick={() => openEdit({ ...appointment })}>
          <img src={editIcon} alt="edit" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({
  // wrapper: {
  //   width: '70%',
  // }
})

export default withStyles(styles)(Courses)
