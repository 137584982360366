import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  TableHead,
  CircularProgress
} from '@material-ui/core'
import Specialists from 'components/Tables/Specialists'
import Educations from 'components/Tables/Educations'
import Works from 'components/Tables/Works'
import Courses from 'components/Tables/Courses'
import Appointments from 'components/Tables/Appointments'
import Patients from 'components/Tables/Patients'
import History from 'components/Tables/History'
import Clinics from 'components/Tables/Clinics'
import Pharmacies from 'components/Tables/Pharmacies'
import Moderators from 'components/Tables/Moderators'
import Notifications from 'components/Tables/Notifications'
import Subscriptions from 'components/Tables/Subscriptions'
import CashRequests from 'components/Tables/CashRequests'
import Reciptions from 'components/Tables/Reciptions'
import SecondOpinionReceptions from 'components/Tables/SecondOpinionReceptions'
import ImportPatients from 'components/Tables/ImportPatients'
import Promocodes from './Tables/Promocodes'

const TableWrapper = ({
  classes,
  columns,
  isLoading,
  tableType,
  page,
  count,
  amount,
  items,
  onChangePage,
  onChangePerPage,
  actions,
  otherStyles
}) => {
  const emptyRows = count - items.length

  const handleChangePage = (event, newPage) => {
    console.log('')

    onChangePage(newPage + 1)
  }

  const handleChangePerPage = (event) => {
    onChangePerPage(event.target.value)
  }

  const empySize = useCallback(() => {
    switch (tableType) {
      case 'notifications':
        return 40
      case 'specialists':
        return 40
      case 'appointments':
        return 30
      case 'patients':
        return 20
      case 'history':
        return 20
      case 'clinics':
        return 30
      case 'pharmacies':
        return 30
      case 'moderators':
        return 20
      case 'subscriptions':
        return 20
      case 'cash':
        return 20
      case 'reciptions':
        return 20
      case 'secondOpinionReceptions':
        return 20
      case 'importPatients':
        return 20
      case 'promocodes':
        return 20
      default:
        break
    }
  }, [tableType])

  const renderContentTable = useCallback(() => {
    switch (tableType) {
      case 'specialists':
        return (
          <Specialists
            specialists={items}
            editSpecialistsName={actions.editSpecialistsName}
            openDelete={actions.openDelete}
            openDeleteSignature={actions.openDeleteSignature}
            fetchSpecialists={onChangePage}
          />
        )
      case 'educations':
        return (
          <Educations
            educations={items}
            openEditEducation={actions.openEditEducation}
            openDelete={actions.openDelete}
          />
        )
      case 'works':
        return (
          <Works
            works={items}
            openEditWork={actions.openEditWork}
            openDelete={actions.openDelete}
          />
        )
      case 'courses':
        return (
          <Courses
            courses={items}
            openEditCourse={actions.openEditCourse}
            openDelete={actions.openDelete}
          />
        )
      case 'appointments':
        return (
          <Appointments
            appointments={items}
            openEdit={actions.editAppointment}
          />
        )
      case 'patients':
        return (
          <Patients
            patients={items}
            openDelete={actions.openDelete}
            openEdit={actions.openEdit}
          />
        )
      case 'history':
        return (
          <History
            admissions={items}
            openDetails={actions.openDetails}
            openDelete={actions.openDelete}
          />
        )
      case 'clinics':
        return (
          <Clinics
            clinics={items}
            openEdit={actions.openEdit}
            openDelete={actions.openDelete}
          />
        )
      case 'pharmacies':
        return (
          <Pharmacies
            pharmacies={items}
            openEdit={actions.openEdit}
            openDelete={actions.openDelete}
          />
        )
      case 'moderators':
        return (
          <Moderators
            moderators={items}
            openEdit={actions.openEdit}
            openResetPassword={actions.openResetPassword}
            openDelete={actions.openDelete}
            changeChekbox={actions.changeChekbox}
          />
        )
      case 'notifications':
        return (
          <Notifications
            notifications={items}
            openTitle={actions.openTitle}
            openDelete={actions.openDelete}
          />
        )
      case 'subscriptions':
        return (
          <Subscriptions
            subscriptions={items}
            openEdit={actions.openEdit}
            handleSwitch={actions.handleSwitch}
          />
        )
      case 'cash':
        return <CashRequests requests={items} openEdit={actions.openEdit} />
      case 'reciptions':
        return (
          <Reciptions
            reciptions={items}
            openMedcard={actions.openMedcard}
            openReviews={actions.openReviews}
            openComplaints={actions.openComplaints}
            openRefferal={actions.openRefferal}
            openMark={actions.openMark}
            openDeclineReception={actions.openDeclineReception}
            openChangeTime={actions.openChangeTime}
          />
        )
      case 'secondOpinionReceptions':
        return (
          <SecondOpinionReceptions
            secondOpinionReceptions={items}
            openMedcard={actions.openMedcard}
            openDelete={actions.openDelete}
          />
        )
      case 'importPatients':
        return <ImportPatients patients={items} />
      case 'promocodes':
        return (
          <Promocodes
            promocodes={items}
            openEdit={actions.openEdit}
            openDelete={actions.openDelete}
          />
        )
      default:
        break
    }
  }, [tableType, items, actions])

  return (
    <TableContainer
      component={Paper}
      className={classes.wrapper}
      style={{ ...otherStyles }}
    >
      <Table aria-label="custom pagination table" padding="none">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                style={{
                  fontWeight: '600',
                  color: palette.grey,
                  borderColor: palette.black,
                  paddingBottom: '0.5em'
                }}
                align={column.align}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {isLoading ? (
          <TableBody>
            <TableRow style={{ height: empySize() * count }}>
              <TableCell colSpan={columns.length} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>{renderContentTable()}</TableBody>
        )}
        {tableType !== 'educations' &&
        tableType !== 'works' &&
        tableType !== 'courses' &&
        tableType !== 'appointments' &&
        tableType !== 'importPatients' ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={columns.length}
                count={amount}
                rowsPerPage={count}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePerPage}
                style={{ borderColor: palette.black }}
              />
            </TableRow>
          </TableFooter>
        ) : (
          <></>
        )}
      </Table>
    </TableContainer>
  )
}

const styles = () => ({
  wrapper: {
    width: '100%',
    padding: '1em 0',
    boxShadow: 'none'
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '250%'
  },
  label: {}
})

export default withStyles(styles)(TableWrapper)
