import { createReducer } from '@reduxjs/toolkit'
import { 
  SET_SUBSCRIPTIONS,
  SET_SWITCH
} from './actions'
import { SUCCESS, REQUEST, FAILURE } from '../../constants/statuses'

const initialState = {
  subscriptions: [],
  pagination: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  isLoading: false,
  isError: false
}

export const subscriptionsReducer = createReducer(initialState, {
  [SET_SUBSCRIPTIONS[SUCCESS]]: (state, action) => {
    state.subscriptions = action.payload.data.tariffs
    state.pagination.amount = action.payload.data.amount
    state.pagination.page = action.payload.paging.page
    state.pagination.perPage = action.payload.paging.count
    state.isLoading = false
  },

  [SET_SUBSCRIPTIONS[REQUEST]]: (state, action) => {
    state.isError = false
    state.isLoading = true
  },

  [SET_SUBSCRIPTIONS[FAILURE]]: (state, action) => {
    state.isLoading = false
    state.isError = true
  },

  SET_SWITCH: (state, action) => {
    let newArray = state.subscriptions
    newArray = [...state.subscriptions, {
      id: action.payload.value.id,
      isLimited: action.payload.value.isLimited,
      receptionLimit: action.payload.value.receptionLimit,
      price: action.payload.value.price,
      isActive: !action.payload.value.isActive,
      duration: action.payload.value.duration
    }]
    newArray.splice(action.payload.index, 1)

    state.subscriptions = newArray
  }
})