import axios from '../index'

export const _getReceptions = (data, paging) => {
  let url = `/receptions?page=${paging.page}&count=${paging.count}&phrase=${paging.phrase}`
  if (data.specialistId) url += `&specialist_id=${data.specialistId}`
  if (data.isReferraled) url += `&is_referraled=${data.isReferraled}`
  if (data.success) url += `&success=${data.success}`
  if (data.today) url += `&today=${data.today}`
  if (data.receptionType) url += `&reception_type=${data.receptionType}`
  return axios.get(url, data)
}

export const _getSecondOpinionReceptions = (data, paging) =>
  axios.get(
    `/special_receptions/?page=${paging.page}&count=${paging.count}`,
    data
  )

export const _editReception = (data) =>
  axios.patch(`/receptions/${data.id}?change_mode=${data.mode}`, data)

export const _editSecondOpinionReception = (data) =>
  axios.patch(`/special_receptions/${data.id}?change_mode=${data.mode}`, data)

export const _declineReception = (data) =>
  axios.delete(`/receptions/${data.receptionId}`, data)

export const _editReceptionFields = (data) =>
  axios.patch(`/receptions/${data.receptionId}/extended_fields`, data)

export const _deleteSecondOpinion = (data) =>
  axios.delete(`/special_receptions/${data.id}`, data)

export const _downloadReception = (data) => axios.get(`/receptions/${data.id}/composition`, data)
