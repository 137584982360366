import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'

const ImportStatus = ({ classes, status = 'success' }) => {
  return (
    <span className={classes[status]}>
      {status === 'success'
        ? 'Успешно'
        : status === 'failed'
        ? 'Загружено с ошибкой'
        : 'Загружается'}
    </span>
  )
}

const styles = () => ({
  success: {
    color: 'green'
  },
  in_progress: {
    color: palette.blueButton
  },
  failed: {
    color: palette.red
  }
})

export default withStyles(styles)(ImportStatus)
