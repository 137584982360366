import { combineReducers } from 'redux'
import { authReducer as auth } from './auth/reducer'
import { moderatorsReducer as moderators } from './moderators/reducer'
import { patientsReducer as patients } from './patients/reducer'
import { clinicsReducer as clinics } from './clinics/reducer'
import { pharmaciesReducer as pharmacies } from './pharmacies/reducer'
import { specialistsReducer as specialists } from './specialists/reducer'
import { notificationsReducer as notifications } from './notifications/reducer'
import { subscriptionsReducer as subscriptions } from './subscriptions/reducer'
import { claimsReducer as claims } from './claims/reducer'
import { receptionsReducer as receptions } from './receptions/reducer'
import { promocodesReducer as promocodes } from './promocodes/reducer'

export default combineReducers({
  auth,
  moderators,
  patients,
  clinics,
  pharmacies,
  specialists,
  notifications,
  subscriptions,
  claims,
  receptions,
  promocodes
})
