import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _getPharmacies,
  _createPharmacy,
  _editPharmacy,
  _deletePharmacy,
  _uploadMedia,
  _uploadFile
} from 'api/http/pharmacies'

export const SET_PHARMACIES = createRequestStatuses('SET_PHARMACIES')
export const getPharmacies = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_PHARMACIES,
    _getPharmacies,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const createPharmacy = (data, callbackSuccess, callbackError) => 
  makeSimpleRequest(
    _createPharmacy, 
    data, 
    callbackSuccess, 
    callbackError
  )

export const editPharmacy = (data, callbackSuccess, callbackError) => 
  makeSimpleRequest(
    _editPharmacy, 
    data, 
    callbackSuccess, 
    callbackError
  )

export const deletePharmacy = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _deletePharmacy, 
    data, 
    callbackSuccess, 
    callbackError
  )

export const uploadMedia = (data, callbackSuccess, callbackError) => 
  makeSimpleRequest(
    _uploadMedia,
    data,
    callbackSuccess,
    callbackError,
  )

export const uploadFile = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _uploadFile,
    data,
    callbackSuccess,
    callbackError,
  )