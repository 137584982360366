import React, { useCallback, useState } from 'react'
import { TableRow, TableCell, IconButton, Input } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import editIcon from 'assets/edit.png'
import deleteIcon from 'assets/delete.png'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import { Check } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeCarouselNumber,
  getSpecialists
} from 'redux/modules/specialists/actions'
import { getSpecialistsPagination } from 'redux/selectors/specialists'
import config from 'api/config'

const Specialist = ({
  classes,
  specialist,
  editSpecialistsName,
  openDelete,
  openDeleteSignature,
  goToReceptions,
  fetchSpecialists
}) => {
  const dispatch = useDispatch()

  const [number, setNumber] = useState(specialist.carouselNumber ?? 0)

  const specialistsPagination = useSelector(getSpecialistsPagination)

  const saveNumber = (specialist) => {
    dispatch(
      changeCarouselNumber(
        {
          carouselNumber: Number(number),
          specialistId: specialist.id
        },
        () => {
          fetchSpecialists(
            specialistsPagination.page,
            specialistsPagination.perPage
          )
        },
        (error) => {}
      )
    )
  }

  const downloadSign = (specialist) => {
    let a = document.createElement('a')
    a.href = `${config.axios.baseImgUrl}${specialist.signature}`
    a.download = `signature_${specialist.fullName}.png`
    a.target = '_blank'
    a.click()
  }

  return (
    <TableRow key={specialist.id}>
      <TableCell
        style={{ width: '130px', borderColor: palette.black }}
        align="center"
      >
        {specialist.fullName}
      </TableCell>
      <TableCell
        style={{ width: '130px', borderColor: palette.black }}
        align="center"
      >
        {specialist.isDuty
          ? 'Дежурный врач'
          : specialist.specialities.length > 0
          ? specialist.specialities.length > 1
            ? `${specialist.specialities[0].title} ...`
            : specialist.specialities[0].title
          : 'Не указана специальность'}
      </TableCell>
      <TableCell
        style={{ width: '130px', borderColor: palette.black }}
        align="center"
      >
        <Button
          text={'Перейти к приемам'}
          onClick={() => goToReceptions(specialist)}
          otherStyles={{
            width: '200px',
            backgroundColor: palette.blueButton,
            color: palette.white,
            marginBottom: '1em',
            marginTop: '1em'
          }}
        />
      </TableCell>
      <TableCell
        style={{ width: '50px', borderColor: palette.black }}
        align="center"
      >
        <Input
          onChange={(e) => setNumber(e.target.value)}
          value={number}
          style={{ width: 40 }}
        />
        <IconButton
          onClick={() => {
            saveNumber(specialist)
          }}
        >
          <Check />
        </IconButton>
      </TableCell>
      <TableCell
        style={{ width: '130px', borderColor: palette.black }}
        align="center"
      >
        <Button
          text={'Скачать'}
          onClick={() => downloadSign(specialist)}
          otherStyles={{
            width: '100px',
            backgroundColor: specialist.signature ? palette.blueButton : palette.daysDisables,
            color: palette.white,
            marginBottom: '1em',
            marginTop: '1em'
          }}
          disabled={!specialist.signature}
        />
        <IconButton
          onClick={() => {
            openDeleteSignature(specialist)
          }}
          disabled={!specialist.signature}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
      <TableCell
        style={{ width: '40px', borderColor: palette.black }}
        align="center"
      >
        <IconButton onClick={() => editSpecialistsName({ ...specialist })}>
          <img src={editIcon} alt="edit" width="15" />
        </IconButton>
      </TableCell>
      <TableCell
        style={{ width: '50px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openDelete(specialist)
          }}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const Specialists = ({
  classes,
  specialists,
  editSpecialistsName,
  openDelete,
  openDeleteSignature,
  fetchSpecialists
}) => {
  const router = useHistory()

  const goToReceptions = useCallback((specialist) => {
    router.push({ pathname: `/receptions`, state: { id: specialist.id } })
  }, [])

  return specialists.map((specialist) => (
    <Specialist
      goToReceptions={goToReceptions}
      specialist={specialist}
      openDelete={openDelete}
      editSpecialistsName={editSpecialistsName}
      openDeleteSignature={openDeleteSignature}
      fetchSpecialists={fetchSpecialists}
    />
  ))
}
const styles = () => ({})

export default withStyles(styles)(Specialists)
