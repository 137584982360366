import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _getClinics,
  _createClinic,
  _editClinic,
  _uploadMedia,
  _deleteClinic,
  _uploadFile
} from 'api/http/clinics'

export const SET_CLINICS = createRequestStatuses('SET_CLINICS')
export const getClinics = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_CLINICS,
    _getClinics,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const createClinic = (data, callbackSuccess, callbackError) => 
  makeSimpleRequest(
    _createClinic,
    data,
    callbackSuccess,
    callbackError,
  )

export const editClinic = (data, callbackSuccess, callbackError) => 
  makeSimpleRequest(
    _editClinic,
    data,
    callbackSuccess,
    callbackError,
  )

export const uploadMedia = (data, callbackSuccess, callbackError) => 
  makeSimpleRequest(
    _uploadMedia,
    data,
    callbackSuccess,
    callbackError,
  )

export const deleteClinic = (data, callbackSuccess, callbackError) => 
  makeSimpleRequest(
    _deleteClinic,
    data,
    callbackSuccess,
    callbackError,
  )

export const uploadFile = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _uploadFile,
    data,
    callbackSuccess,
    callbackError,
  )