import { createReducer } from '@reduxjs/toolkit'
import { SET_RECEPTIONS, SET_SECOND_OPINION_RECEPTIONS } from './actions'
import { SUCCESS, REQUEST, FAILURE } from '../../constants/statuses'

const initialState = {
  receptions: [],
  pagination: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  isLoading: false,
  isError: false,
  secondOpinionReceptions: [],
  secondOpinionPagination: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  secondOpinionLoading: false,
  secondOpinionError: false
}

export const receptionsReducer = createReducer(initialState, {
  [SET_RECEPTIONS[SUCCESS]]: (state, action) => {
    state.receptions = action.payload.data.receptions
    state.receptions = state.receptions.map((reception) => {
      // reception.medicines.map((medicine) => {
      //   medicine.dosage = `${medicine.dosage}${
      //     medicine.dosageUnit !== '' ? ' ' + medicine.dosageUnit : ''
      //   }`
      //   medicine.period = `${medicine.period}${
      //     medicine.periodUnit !== '' ? ' ' + medicine.periodUnit : ''
      //   }`
      //   return medicine
      // })
      return reception
    })
    state.pagination.amount = action.payload.data.amount
    state.pagination.page = action.payload.paging.page
    state.pagination.perPage = action.payload.paging.count
    state.isLoading = false
  },

  [SET_RECEPTIONS[REQUEST]]: (state, action) => {
    state.isError = false
    state.isLoading = true
  },

  [SET_RECEPTIONS[FAILURE]]: (state, action) => {
    state.isLoading = false
    state.isError = true
  },

  [SET_SECOND_OPINION_RECEPTIONS[SUCCESS]]: (state, action) => {
    state.secondOpinionReceptions = action.payload.data.receptions
    state.secondOpinionReceptions = state.secondOpinionReceptions.map(
      (reception) => {
        // reception.medicines.map((medicine) => {
        //   medicine.dosage = `${medicine.dosage}${
        //     medicine.dosageUnit !== '' ? ' ' + medicine.dosageUnit : ''
        //   }`
        //   medicine.period = `${medicine.period}${
        //     medicine.periodUnit !== '' ? ' ' + medicine.periodUnit : ''
        //   }`
        //   return medicine
        // })
        return reception
      }
    )
    state.secondOpinionPagination.amount = action.payload.data.amount
    state.secondOpinionPagination.page = action.payload.paging.page
    state.secondOpinionPagination.perPage = action.payload.paging.count
    state.secondOpinionLoading = false
  },

  [SET_SECOND_OPINION_RECEPTIONS[REQUEST]]: (state, action) => {
    state.secondOpinionError = false
    state.secondOpinionLoading = true
  },

  [SET_SECOND_OPINION_RECEPTIONS[FAILURE]]: (state, action) => {
    state.secondOpinionLoading = false
    state.secondOpinionError = true
  }
})
