import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import NoContent from 'components/NoContent'
import TableWrapper from 'components/Table'
import Menu from 'components/Menu'
import ModalWrapper from 'components/Modals/ModalWrapper'
import ButtonCreate from 'components/ButtonCreate'
import { columnsSubscriptions } from 'data/columns'
import Subscription from 'components/Modals/Subscription'
import {
  getSubscriptionsItems,
  getSubscriptionsPagination,
  getIsLoadingSubscriptions
} from 'redux/selectors/subscriptions'
import {
  createSubscription as createSubscriptionAction,
  editSubscription as editSubscriptionAction,
  getSubscriptions
} from 'redux/modules/subscriptions/actions'

const Subscriptions = ({ classes }) => {
  const dispatch = useDispatch()

  const subscriptions = useSelector(getSubscriptionsItems)
  const isRequestsLoading = useSelector(getIsLoadingSubscriptions)
  const subscriptionsPagination = useSelector(getSubscriptionsPagination)

  const fetchSubscriptions = (page = subscriptionsPagination.page) => {
    dispatch(
      getSubscriptions(
        {},
        () => {},
        () => {},
        {
          page,
          count: subscriptionsPagination.perPage
        }
      )
    )
  }

  useEffect(() => {
    fetchSubscriptions(subscriptionsPagination.page)
  }, [])

  const [isLoading, setIsLoading] = useState(false)

  const [subscription, setSubscription] = useState('')
  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = (editedSubscription) => {
    setSubscription(editedSubscription)
    setIsOpenedEdit(true)
  }
  const closeEdit = () => {
    setIsOpenedEdit(false)
  }

  const editSubscription = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      editSubscriptionAction(
        {
          id: subscription.id,
          isLimited: values.isLimited,
          receptionLimit: values.receptionLimit,
          price: values.price,
          isActive: subscription.isActive,
          duration: values.duration
        },
        (data) => {
          fetchSubscriptions(subscriptionsPagination.page)
          closeEdit()
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const [isOpenedCreateSubscription, setIsOpenedCreateSubscription] = useState(false)

  const openCreateSubscription = () => {
    setIsOpenedCreateSubscription(true)
  }
  const closeCreateSubscription = () => {
    setIsOpenedCreateSubscription(false)
  }

  const createSubscription = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      createSubscriptionAction(
        {
          isLimited: values.isLimited,
          receptionLimit: values.receptionLimit,
          price: values.price,
          duration: values.duration
        },
        (data) => {
          fetchSubscriptions(1)
          setIsOpenedCreateSubscription(false)
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
        }
      )
    )
  }

  const handleSwitch = (event, value, index) => {
    dispatch(
      editSubscriptionAction(
        {
          id: value.id,
          isLimited: value.isLimited,
          receptionLimit: value.receptionLimit,
          price: value.price,
          isActive: event.target.checked,
          duration: value.duration
        },
        (data) => {
          dispatch({ type: 'SET_SWITCH', payload: { value, index }})
        },
        () => {}
      )
    )
  }

  return (
    <div className={classes.wrapper}>
      <Menu />
      <div className={classes.table}>
        {subscriptions.length > 0 || isRequestsLoading ? (
          <>
            <div className={classes.tableWrapper}>
              <TableWrapper
                page={subscriptionsPagination.page}
                count={subscriptionsPagination.perPage}
                amount={subscriptionsPagination.amount}
                items={subscriptions}
                onChangePage={fetchSubscriptions}
                isLoading={isRequestsLoading}
                columns={columnsSubscriptions}
                tableType={'subscriptions'}
                actions={{
                  openEdit: openEdit,
                  handleSwitch: handleSwitch
                }}
              />
            </div>
            <div className={classes.button}>
              <ButtonCreate
                openCreate={openCreateSubscription}
                text="Добавить абонемент"
              />
            </div>
          </>
        ) : (
          <>
            <NoContent text={'Нет абонементов'} />
            <div className={classes.button}>
              <ButtonCreate
                openCreate={openCreateSubscription}
                text="Добавить абонемент"
              />
            </div>
          </>
        )}
      </div>
      <ModalWrapper
        title={'Редактирование тарифа'}
        isOpened={isOpenedEdit}
        onClose={() => closeEdit()}
        style={{ width: '800px' }}
      >
        <Subscription 
          handleSubmit={editSubscription} 
          editedObj={subscription} 
          isLoading={isLoading}
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Создание тарифа'}
        isOpened={isOpenedCreateSubscription}
        onClose={() => closeCreateSubscription()}
        style={{ width: '800px' }}
      >
        <Subscription 
          handleSubmit={createSubscription} 
          isLoading={isLoading}
        />
      </ModalWrapper>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1.5em 0'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: '3em',
    right: '0'
  }
})

export default withStyles(styles)(Subscriptions)