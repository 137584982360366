import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Typography } from '@material-ui/core'
import Input from 'components/Input'
import Button from 'components/Button'
import { palette } from 'styles/colors'
import EditDate from 'components/Modals/EditDate'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Select from 'components/Select'
import {
  getSpecialistsItems,
  getSpecialistsPagination
} from 'redux/selectors/specialists'
import {
  editScheduleReceptions as editScheduleReceptionsAction,
  createScheduleReceptions as createScheduleReceptionsAction,
  getSpecialists
} from 'redux/modules/specialists/actions'
import * as Yup from 'yup'

const editSpecSchema = Yup.object().shape({
  complaints: Yup.string().min(10, 'Жалоба слишком короткая'),
  moderatorName: Yup.string().required('Имя обязательно для заполнения')
})

const EditAppointmentList = ({
  classes,
  appointments,
  user,
  specialist,
  freeTimes,
  close,
  isPatient,
  fetch,
  fetchShedules
}) => {
  const formik = useFormik({
    initialValues: {
      fullName: user ? user.fullName : '',
      phone: user ? user.phone : '',
      inn: user ? user.inn : '',
      fioSpec: specialist ? specialist.fullName : '',
      speciality:
        specialist && specialist.specialities.length > 0
          ? specialist.specialities[0].id
          : '',
      receptionTime: appointments ? appointments.receptionTime : '',
      receptionDate: appointments ? appointments.receptionDate : '',
      status: appointments ? appointments.status : '',
      callUrl: appointments ? appointments.callUrl : '',
      theme: appointments ? appointments.theme : '',
      recomendation: appointments ? appointments.recomendation : '',
      appointmentMark: appointments ? appointments.appointmentMark : '',
      appointmentDescription: appointments
        ? appointments.appointmentDescription
        : '',
      specialist: null,
      complaints: '',
      moderatorName: ''
    },
    enableReinitialize: true,
    validationSchema: !isPatient ? undefined : editSpecSchema,
    onSubmit: (values, { setFieldError }) =>
      !isPatient
        ? editScheduleReceptions(setFieldError)
        : createScheduleReceptions(setFieldError)
  })

  const dispatch = useDispatch()

  const spetialists = useSelector(getSpecialistsItems)
  const specialistsPagination = useSelector(getSpecialistsPagination)

  const fetchSpecialists = (page = specialistsPagination.page) => {
    dispatch(
      getSpecialists(
        {},
        () => {},
        () => {},
        {
          page: 1,
          count: !isPatient ? specialistsPagination.perPage : 1000,
          phrase: ''
        }
      )
    )
  }

  useEffect(() => {
    fetchSpecialists(specialistsPagination.page)
  }, [])

  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = () => {
    setIsOpenedEdit(true)
  }

  const closeEdit = () => {
    formik.setFieldValue('receptionTime', '')
    // formik.setFieldValue('receptionDate', '')
    setIsOpenedEdit(false)
  }

  const editScheduleReceptions = (setError) => {
    dispatch(
      editScheduleReceptionsAction(
        {
          mode: 'reception_dt',
          receptionId: appointments.id,
          userId: user.id,
          specialistId: specialist.id,
          specialityId: formik.values.speciality,
          callUrl: formik.values.callUrl,
          receptionDate: formik.values.receptionDate,
          receptionTime: formik.values.receptionTime,
          status: formik.values.status,
          theme: formik.values.theme,
          recomendation: formik.values.recomendation,
          appointmentMark: formik.values.appointmentMark,
          appointmentDescription: formik.values.appointmentDescription
        },
        () => {
          close()
          fetch(1, appointments.receptionDate)
          fetchShedules(
            appointments.id,
            formik.values.receptionDate.split('-')[0],
            formik.values.receptionDate.split('-')[1]
          )
          setError('receptionDate', '')
        },
        () => {
          setError('receptionDate', 'Невозможно записать на эту дату')
        }
      )
    )
  }

  const createScheduleReceptions = (setError) => {
    dispatch(
      createScheduleReceptionsAction(
        {
          receptionDate: formik.values.receptionDate,
          receptionTime: formik.values.receptionTime,
          userId: user.id,
          specialistId: formik.values.specialist.id,
          specialityId: formik.values.speciality,
          theme: formik.values.theme,
          status: formik.values.status,
          complaints: formik.values.complaints,
          moderatorName: formik.values.moderatorName
        },
        () => {
          close()
          setError('receptionDate', '')
        },
        () => {
          setError('receptionDate', 'Невозможно записать на эту дату')
        }
      )
    )
  }

  return (
    <>
      <Typography
        style={{ fontWeight: 'bold', marginBottom: '1em' }}
        variant="subtitle1"
      >
        О пациенте:
      </Typography>
      <Input
        formik={formik}
        label={'ФИО'}
        fieldName={'fullName'}
        otherStyles={{ width: '100%' }}
        disabled={true}
      />
      <Input
        formik={formik}
        label={'Номер телефона'}
        fieldName={'phone'}
        otherStyles={{ width: '100%' }}
        disabled={true}
      />
      <Input
        formik={formik}
        label={'ИНН'}
        fieldName={'inn'}
        otherStyles={{ width: '100%' }}
        disabled={true}
      />
      <Typography
        style={{ fontWeight: 'bold', marginBottom: '1em' }}
        variant="subtitle1"
      >
        О специалисте:
      </Typography>
      {!isPatient ? (
        <Input
          formik={formik}
          label={'ФИО'}
          fieldName={'fioSpec'}
          otherStyles={{ width: '100%' }}
          disabled={true}
        />
      ) : (
        <Select
          formik={formik}
          selectValues={spetialists}
          fieldName={'fioSpec'}
          otherStyles={{ width: '100%', margin: 0, height: '60px' }}
          title={'ФИО'}
          selectorType={'specialist'}
        />
      )}
      {formik.values?.specialist && (
        <Select
          formik={formik}
          selectValues={formik.values.specialist.specialities}
          fieldName={'speciality'}
          otherStyles={{ width: '100%' }}
          title={'Специальность'}
          selectorType={'specialityList'}
        />
      )}
      {/* <Input
        formik={formik}
        label={'Специальность'}
        fieldName={'speciality'}
        otherStyles={{ width: '100%' }}
        disabled={true}
      /> */}
      <div className={classes.button}>
        <Button
          text={'Далее'}
          otherStyles={{
            width: '160px',
            height: '40px',
            backgroundColor: palette.blue,
            color: palette.white,
            borderRadius: '10px',
            margin: '10px'
          }}
          onClick={openEdit}
          disabled={!formik.values.fioSpec}
        />
      </div>
      <ModalWrapper
        title={'Выберите дату'}
        isOpened={isOpenedEdit}
        onClose={() => closeEdit()}
        style={{ width: '800px' }}
      >
        <EditDate
          formik={formik}
          editedObj={appointments ? appointments : ''}
          specialist={specialist ? specialist : formik.values.specialist}
          times={freeTimes}
          editScheduleReceptions={
            !isPatient ? editScheduleReceptions : createScheduleReceptions
          }
          isPatient={isPatient}
        />
      </ModalWrapper>
    </>
  )
}

const styles = () => ({
  button: {
    display: 'flex',
    justifyContent: 'center'
  },

  select: {
    width: '100%'
  }
})

export default withStyles(styles)(EditAppointmentList)
