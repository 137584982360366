import React from 'react'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'

const Input = ({
  formik,
  fieldName,
  label,
  endAdornment,
  classes,
  otherStyles,
  standard,
  variant,
  date,
  maxDate,
  ...props
}) => {
  return (
    <TextField
      id={fieldName}
      name={fieldName}
      label={label}
      value={formik.values[fieldName]}
      onChange={formik.handleChange}
      error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
      helperText={formik.touched[fieldName] && formik.errors[fieldName]}
      variant={variant ? variant : 'outlined'}
      style={{ margin: '0.5em 0', ...otherStyles }}
      InputLabelProps={{
        classes: {
          root:
            formik.touched[fieldName] && Boolean(formik.errors[fieldName])
              ? classes.labelError
              : !standard
              ? classes.label
              : ''
        },
        style: {
          color: palette.black
        }
      }}
      InputProps={{
        classes: {
          notchedOutline:
            formik.touched[fieldName] && Boolean(formik.errors[fieldName])
              ? classes.error
              : !standard
              ? classes.input
              : ''
        },
        endAdornment: endAdornment,
        inputProps:
          date ? {max: maxDate} : {}
      }}
      {...props}
    />
  )
}

const styles = () => ({
  input: {
    borderWidth: '1px',
    borderColor: `${palette.blue} !important`,
    height: '50px', 
    top: 0,
  },
  error: {
    borderWidth: '1px',
    borderColor: `red !important`
  },
  label: {
    fontFamily: 'Inter',
    '&.Mui-focused': {
      color: palette.blue
    }
  },
  labelError: {
    '&.Mui-focused': {
      color: 'red'
    }
  },
  notchedOutline: {},
  '@global': {
    '.MuiFormLabel-root': {
      fontSize: '14px'
    },
    'input:-internal-autofill-selected': {
      '-webkit-text-fill-color': 'black !important',
      '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
      background: '-webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%)'
    }
  }
})

export default withStyles(styles)(Input)
