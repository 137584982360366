import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import Input from 'components/Input'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import 'yup-phone'
import { palette } from 'styles/colors'
import Select from 'components/Select'
import TableWrapper from 'components/Table'
import { columnsEducations, columnsWorks, columnsCourses } from 'data/columns'
import Delete from 'components/Modals/Delete'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Course from 'components/Modals/Course'
import Works from 'components/Modals/Works'
import Education from 'components/Modals/Education'
import NoContent from 'components/NoContent'
import {
  getSpecialist,
  getSpecialitiesItems,
  getSpecialitiesId
} from 'redux/selectors/specialists'
import { getSpecialities } from 'redux/modules/specialists/actions'
import { getUser } from 'redux/selectors/auth'

const Portfolio = ({
  classes,
  formik,
  onClose,
  handleSubmit,
  error,
  deleteTableValue,
  editTableValue,
  isLoading,
  createTableValue
}) => {
  const dispatch = useDispatch()

  const speciality = useSelector(getSpecialitiesItems)
  const specialist = useSelector(getSpecialist)
  const specialitiesId = useSelector(getSpecialitiesId)

  const user = useSelector(getUser)

  const fetchSpeciality = (page = 1) => {
    dispatch(
      getSpecialities(
        {},
        () => {},
        () => {},
        {
          page,
          count: 999
        }
      )
    )
  }

  useEffect(() => {
    fetchSpeciality(1)
  }, [])

  const degree = [
    {
      id: 0,
      name: 'Кандидат наук',
      value: 'candidate_of_sciences'
    },
    {
      id: 1,
      name: 'Доктор наук',
      value: 'doctor_of_science'
    },
    {
      id: 2,
      name: 'Не указано',
      value: 'not_specified'
    }
  ]

  const category = [
    {
      id: 0,
      name: 'Не указано',
      value: 'no_category'
    },
    {
      id: 1,
      name: 'Первая категория',
      value: 'first_category'
    },
    {
      id: 2,
      name: 'Вторая категория',
      value: 'second_category'
    },
    {
      id: 3,
      name: 'Высшая категория',
      value: 'highest_category'
    }
  ]

  const [isOpenedDelete, setIsOpenedDelete] = useState(false)
  const [deletedEducation, setDeletedEducation] = useState(null)
  const [type, setType] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)

  const openDelete = (education, type) => {
    setDeletedEducation(education)
    setType(type)
    setIsOpenedDelete(true)
  }

  const closeDelete = () => {
    setIsOpenedDelete(false)
  }

  const [isOpenedCreateCourse, setIsOpenedCreateCourse] = useState(false)

  const openCreateCourse = () => {
    setIsOpenedCreateCourse(true)
  }
  const closeCreateCourse = () => {
    setIsOpenedCreateCourse(false)
  }

  const [course, setCourse] = useState('')
  const [isOpenedEditCourse, setIsOpenedEditCourse] = useState(false)

  const openEditCourse = (editedCourse, deletedEducation, type) => {
    setDeletedEducation(deletedEducation)
    setCourse(editedCourse)
    setType(type)
    setIsOpenedEditCourse(true)
  }
  const closeEditCourse = () => {
    setIsOpenedEditCourse(false)
  }

  const [isOpenedCreateWorks, setIsOpenedCreateWorks] = useState(false)

  const openCreateWorks = () => {
    setIsOpenedCreateWorks(true)
    setWork(null)
  }
  const closeCreateWorks = () => {
    setIsOpenedCreateWorks(false)
  }

  const [isOpenedCreateEducation, setIsOpenedCreateEducation] = useState(false)

  const openCreateEducation = () => {
    setIsOpenedCreateEducation(true)
    setEducation(null)
  }
  const closeCreateEducation = () => {
    setIsOpenedCreateEducation(false)
  }

  const [work, setWork] = useState('')
  const [isOpenedEditWork, setIsOpenedEditWork] = useState(false)

  const openEditWork = (editedWork, deletedWork, type) => {
    setDeletedEducation(deletedWork)
    setWork(editedWork)
    setType(type)
    setIsOpenedEditWork(true)
  }
  const closeEditWork = () => {
    setIsOpenedEditWork(false)
  }

  const [education, setEducation] = useState('')
  const [isOpenedEditEducation, setIsOpenedEditEducation] = useState(false)

  const openEditEducation = (editedEducation, deletedEducation, type) => {
    setDeletedEducation(deletedEducation)
    setEducation(editedEducation)
    setType(type)
    setIsOpenedEditEducation(true)
  }
  const closeEditEducation = () => {
    setIsOpenedEditEducation(false)
  }

  console.log(speciality, specialist)

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title} variant="subtitle1">
        Квалификация:
      </Typography>
      <div className={classes.qualification}>
        <Select
          formik={formik}
          title={'Специальность'}
          selectValues={speciality}
          fieldName={'specialities'}
          selectorType={'speciality'}
          defaultValue={specialitiesId}
          multiple
        />
        <Select
          formik={formik}
          title={'Ученая степень'}
          selectValues={degree}
          fieldName={'degree'}
        />
        <Select
          formik={formik}
          title={'Категория'}
          selectValues={category}
          fieldName={'category'}
        />
        <Input
          formik={formik}
          fieldName={'experience'}
          label={'Стаж'}
          otherStyles={{ margin: '0' }}
        />
      </div>
      <div className={classes.error}>
        {(error || Object.keys(formik.errors).length > 0) && isSubmit
          ? 'Неверно введены данные. Вернитесь на предыдущую страницу'
          : ''}
      </div>
      {user.status !== 'call' && (
        <div className={classes.buttons}>
          <Button
            text="Сохранить"
            otherStyles={{
              backgroundColor: palette.blue,
              color: palette.white
            }}
            className={classes.button}
            onClick={() => {
              handleSubmit()
              setIsSubmit(true)
            }}
            loader={isLoading}
          />
        </div>
      )}
      <Typography className={classes.title} variant="subtitle1">
        Образование:
      </Typography>
      <div className={classes.tableWrapper}>
        {formik.values.portfolio.education.length > 0 ? (
          <>
            <TableWrapper
              items={formik.values.portfolio.education}
              isLoading={false}
              columns={columnsEducations}
              tableType={'educations'}
              actions={{
                openEditEducation: openEditEducation,
                openDelete: openDelete
              }}
            />
          </>
        ) : (
          <>
            <NoContent withoutMargin text={'Нет образований'} />
          </>
        )}

        {user.status !== 'call' && (
          <div className={classes.buttons}>
            <Button
              text="Добавить"
              otherStyles={{
                backgroundColor: palette.blue,
                color: palette.white
              }}
              className={classes.button}
              onClick={openCreateEducation}
            />
          </div>
        )}
      </div>
      <Typography className={classes.title} variant="subtitle1">
        Опыт работы:
      </Typography>
      <div className={classes.tableWrapper}>
        {formik.values.portfolio.workExperience.length > 0 ? (
          <>
            <TableWrapper
              items={formik.values.portfolio.workExperience}
              isLoading={false}
              columns={columnsWorks}
              tableType={'works'}
              actions={{
                openEditWork: openEditWork,
                openDelete: openDelete
              }}
            />
          </>
        ) : (
          <>
            <NoContent withoutMargin text={'Нет опыта работы'} />
          </>
        )}
        {user.status !== 'call' && (
          <div className={classes.buttons}>
            <Button
              text="Добавить"
              otherStyles={{
                backgroundColor: palette.blue,
                color: palette.white
              }}
              className={classes.button}
              onClick={openCreateWorks}
            />
          </div>
        )}
      </div>
      <Typography className={classes.title} variant="subtitle1">
        Курсы:
      </Typography>
      <div className={classes.tableWrapper}>
        {formik.values.portfolio.courses.length > 0 ? (
          <>
            <TableWrapper
              items={formik.values.portfolio.courses}
              isLoading={false}
              columns={columnsCourses}
              tableType={'courses'}
              actions={{
                openEditCourse: openEditCourse,
                openDelete: openDelete
              }}
            />
          </>
        ) : (
          <>
            <NoContent withoutMargin text={'Нет пройденных курсов'} />
          </>
        )}
        {user.status !== 'call' && (
          <div className={classes.buttons}>
            <Button
              text="Добавить"
              otherStyles={{
                backgroundColor: palette.blue,
                color: palette.white
              }}
              className={classes.button}
              onClick={openCreateCourse}
            />
          </div>
        )}
      </div>
      <ModalWrapper
        title={'Вы уверены?'}
        isOpened={isOpenedDelete}
        onClose={() => closeDelete()}
      >
        <Delete
          onDelete={() =>
            deleteTableValue(formik, deletedEducation, type, closeDelete)
          }
          onClose={() => closeDelete()}
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Добавление курса'}
        isOpened={isOpenedCreateCourse}
        onClose={() => closeCreateCourse()}
      >
        <Course
          formik={formik}
          handleSubmit={createTableValue}
          close={closeCreateCourse}
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Редактирование курса'}
        isOpened={isOpenedEditCourse}
        onClose={() => closeEditCourse()}
      >
        <Course
          formik={formik}
          editedObj={course}
          handleSubmit={handleSubmit}
          editTableValue={editTableValue}
          deletedEducation={deletedEducation}
          type={type}
          isEdit={true}
          close={closeEditCourse}
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Добавление опыта работы'}
        isOpened={isOpenedCreateWorks}
        onClose={() => closeCreateWorks()}
      >
        <Works
          formik={formik}
          handleSubmit={createTableValue}
          close={closeCreateWorks}
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Редактирование опыта работы'}
        isOpened={isOpenedEditWork}
        onClose={() => closeEditWork()}
      >
        <Works
          formik={formik}
          editedObj={work}
          handleSubmit={handleSubmit}
          editTableValue={editTableValue}
          deletedEducation={deletedEducation}
          type={type}
          isEdit={true}
          close={closeEditWork}
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Добавление образования'}
        isOpened={isOpenedCreateEducation}
        onClose={() => closeCreateEducation()}
      >
        <Education
          formik={formik}
          handleSubmit={createTableValue}
          deleteTableValue={deleteTableValue}
          close={closeCreateEducation}
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Редактирование образования'}
        isOpened={isOpenedEditEducation}
        onClose={() => closeEditEducation()}
      >
        <Education
          formik={formik}
          handleSubmit={handleSubmit}
          editedObj={education}
          editTableValue={editTableValue}
          deletedEducation={deletedEducation}
          type={type}
          isEdit={true}
          close={closeEditEducation}
        />
      </ModalWrapper>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    marginBottom: '2em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  qualification: {
    display: 'flex',
    margin: '1em 0'
  },

  title: {
    fontWeight: 'bold',
    marginLeft: '1em'
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  button: {
    width: '140px',
    height: '40px',
    borderRadius: '10px',
    boxShadow: 'none',
    marginLeft: '2em'
  },

  buttonSave: {
    display: 'flex',
    justifyContent: 'center'
  },

  error: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    color: `${palette.red}`
  },

  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '1000px !important'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    }
  }
})

export default withStyles(styles)(Portfolio)
