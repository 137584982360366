import axios from '../index'

export const _getClinics = (data, paging) =>
  axios.get(
    encodeURI(
      `/medical_institutions/?page=${paging.page}&count=${paging.count}&phrase=${paging.phrase}`
    ),
    data
  )

export const _createClinic = (data) =>
  axios.post('/medical_institutions/', data)

export const _editClinic = (data) =>
  axios.patch(`/medical_institutions/${data.id}`, data)

export const _uploadMedia = (data) =>
  axios.post(`/medical_institutions/${data.id}/image`, data.image, {
    'Content-Type': 'multipart/form-data'
  })

export const _deleteClinic = (data) =>
  axios.delete(`/medical_institutions/${data.id}`, data)

export const _uploadFile = (data) =>
  axios.post(`/medical_institutions/create_by_file`, data.file, {
    'Content-Type': 'multipart/form-data'
  })
