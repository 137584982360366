import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/Button'
import { palette } from 'styles/colors'
import ModalWrapper from 'components/Modals/ModalWrapper'
import EditAppointmentList from 'components/Modals/EditAppointmentList'
import {
  deleteScheduleReception as deleteScheduleReceptionAction,
} from 'redux/modules/specialists/actions'

const EditAppointment = ({ 
  classes, 
  appointments, 
  specialist, 
  freeTimes, 
  close, 
  fetch, 
  fetchShedules
}) => {
  const dispatch = useDispatch()

  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = () => {
    setIsOpenedEdit(true)
  }

  const closeEdit = () => {
    setIsOpenedEdit(false)
  }

  const deleteScheduleReception = () => {
    dispatch(
      deleteScheduleReceptionAction(
        { receptionId: appointments.id },
        () => {
          close()
          fetch(1, appointments.receptionDate)
        },
        () => {}
      )
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.line}>{appointments.user.fullName}</div>
      <div className={classes.line}>{appointments.user.phone}</div>
      <div className={classes.line}>{`Прием в ${appointments.receptionTime.split(':')[0] + ':' + appointments.receptionTime.split(':')[1]} y ${specialist.speciality ? specialist.speciality.jobTitle.toLowerCase() + 'а' : ''} ${specialist.fullName}`}</div>
      <div className={classes.buttons}>
        <Button 
          text={'Удалить'}
          otherStyles={{
            width: '200px',
            height: '40px',
            backgroundColor: palette.blue,
            color: palette.white,
            borderRadius: '10px',
            margin: '10px'
          }}
          onClick={deleteScheduleReception}
        />
        <Button 
          text={'Перенести'}
          otherStyles={{
            width: '200px',
            height: '40px',
            backgroundColor: palette.blue,
            color: palette.white,
            borderRadius: '10px',
            margin: '10px'
          }}
          onClick={openEdit}
        />
      </div>
      <ModalWrapper 
        title={'Запись'}
        isOpened={isOpenedEdit}
        onClose={() => closeEdit()}
        style={{ 'width': '800px' }}
      >
        <EditAppointmentList 
          appointments={appointments} 
          user={appointments.user}
          specialist={specialist} 
          freeTimes={freeTimes}
          close={close}
          fetch={fetch}
          fetchShedules={fetchShedules}
        />
      </ModalWrapper>
    </div>
  )
}

const styles = () => ({
  line: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export default withStyles(styles)(EditAppointment)