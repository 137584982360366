import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import { palette } from 'styles/colors'

const ButtonCreate = ({ openCreate, text }) => {
  return (
    <Button
      onClick={() => openCreate()}
      text={text}
      otherStyles={{
        backgroundColor: palette.blue,
        width: '260px',
        height: '60px',
        borderRadius: '100px',
        color: palette.white,
        marginRight: '2em',
        boxShadow: '0px 10px 20px rgba(0, 156, 205, 0.25)'
      }}
    />
  )
}

const styles = () => ({})

export default withStyles(styles)(ButtonCreate)
