import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import Button from 'components/Button'
import Input from 'components/Input'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import Checkbox from 'components/Checkbox'
import { editReceptionFields } from 'redux/modules/receptions/actions'

const editRefferalSchema = Yup.object().shape({
  refferal: Yup.string().nullable(),
  isReferraled: Yup.boolean().nullable()
})

const Refferal = ({ classes, onClose, reception }) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      refferal: reception.refferal,
      isReferraled: reception.isReferraled
    },
    validationSchema: editRefferalSchema,
    onSubmit: (values, { setFieldError }) => {
      dispatch(
        editReceptionFields(
          {
            isReferraled: values.isReferraled,
            refferal: values.refferal,
            receptionId: reception.id
          },
          (data) => {
            onClose(true)
          },
          (error) => {
            console.log('error', error)
            setFieldError('refferal', 'Ошибка при сохранении')
          }
        )
      )
    }
  })

  return (
    <form onSubmit={formik.handleSubmit} className={classes.root}>
      <Input
        formik={formik}
        label={'Направление'}
        fieldName={'refferal'}
        otherStyles={{ width: '100%' }}
      />
      <Checkbox
        label="Пациент направлен"
        formik={formik}
        fieldName={'isReferraled'}
      />
      <div className={classes.button}>
        <Button
          text={'Сохранить'}
          type="submit"
          fullWidth
          otherStyles={{
            width: '300px',
            height: '40px',
            backgroundColor: palette.blue,
            color: palette.white,
            borderRadius: '10px',
            margin: '10px'
          }}
        />
      </div>
    </form>
  )
}

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px'
  }
})

export default withStyles(styles)(Refferal)
