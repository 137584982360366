import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import deleteIcon from 'assets/delete.png'
import Button from 'components/Button'

const Clinics = ({ 
  classes, 
  clinics, 
  openDelete, 
  openEdit 
}) => {
  return clinics.map((clinic) => (
    <TableRow key={clinic.id}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {clinic.title}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {clinic.assignment === 'clinic' ? 'Клиника' : 'Лаборатория'}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        <Button
          text={'Подробнее'}
          otherStyles={{ background: 'none', boxShadow: 'none', margin: '0' }}
          onClick={() => openEdit({ ...clinic })}
        />
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openDelete(clinic)
          }}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(Clinics)