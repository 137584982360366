import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import { Check } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

const Checkbox = ({
  classes,
  label,
  formik,
  fieldName,
  isChecked,
  otherStyles,
  isDisabled,
  ...props
}) => {
  const checkButton = () => {
    formik.setFieldValue(`${fieldName}`, !formik.values[fieldName])
  }

  return (
    <div className={classes.checkbox} style={{ ...otherStyles }}>
      <IconButton
        style={{ width: '1em', height: '1em' }}
        className={isDisabled ? classes.iconDisabled : classes.icon}
        onClick={formik ? checkButton : null}
        {...props}
      >
        {formik && formik.values[fieldName] ? <Check /> : <></>}
        {isChecked ? <Check /> : <></>}
      </IconButton>
      {label ? (
        <p className={isDisabled ? classes.labelDisabled : classes.label}>
          {label}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}

const styles = () => ({
  checkbox: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    border: `1px solid ${palette.blue}`,
    borderRadius: '0px',
    color: palette.blue
  },
  iconDisabled: {
    border: `1px solid ${palette.grey}`,
    borderRadius: '0px',
    color: palette.blue
  },
  label: {
    fontSize: '14px',
    margin: '0 15px'
  },
  labelDisabled: {
    fontSize: '14px',
    margin: '0 15px',
    color: palette.grey
  }
})

export default withStyles(styles)(Checkbox)
