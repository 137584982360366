import { withStyles } from '@material-ui/core'
import {
  _createScheduleFullYear,
  _createScheduleFullYearDuty
} from 'api/http/specialists'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import Input from 'components/Input'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import InputMask from 'react-input-mask/lib/react-input-mask.development'
import { palette } from 'styles/colors'

const ScheduleFullYear = ({
  classes,
  selectedDate,
  specialist,
  closeModal
}) => {
  const currentDate = new Date(selectedDate)

  console.log('specialist', specialist)

  const isDuty = specialist.isDuty

  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues: {
      startMn: '',
      endMn: '',
      periodMn: '',
      isActiveMn: false,
      startTu: '',
      endTu: '',
      periodTu: '',
      isActiveTu: false,
      startWe: '',
      endWe: '',
      periodWe: '',
      isActiveWe: false,
      startTh: '',
      endTh: '',
      periodTh: '',
      isActiveTh: false,
      startFr: '',
      endFr: '',
      periodFr: '',
      isActiveFr: false,
      startSa: '',
      endSa: '',
      periodSa: '',
      isActiveSa: false,
      startSu: '',
      endSu: '',
      periodSu: '',
      isActiveSu: false
    },
    enableReinitialize: true,
    onSubmit: async (values, { setFieldError }) => {
      const weeksData = {
        0: {
          start: values.startMn ? values.startMn + ':00' : null,
          end: values.endMn ? values.endMn + ':00' : null,
          dutyDoctorMode: isDuty,
          is_active: values.isActiveMn,
          period: isDuty || values.periodMn === '' ? 0 : values.periodMn
        },
        1: {
          start: values.startTu ? values.startTu + ':00' : null,
          end: values.endTu ? values.endTu + ':00' : null,
          dutyDoctorMode: isDuty,
          is_active: values.isActiveTu,
          period: isDuty || values.periodTu === '' ? 0 : values.periodTu
        },
        2: {
          start: values.startWe ? values.startWe + ':00' : null,
          end: values.endWe ? values.endWe + ':00' : null,
          dutyDoctorMode: isDuty,
          is_active: values.isActiveWe,
          period: isDuty || values.periodWe === '' ? 0 : values.periodWe
        },
        3: {
          start: values.startTh ? values.startTh + ':00' : null,
          end: values.endTh ? values.endTh + ':00' : null,
          dutyDoctorMode: isDuty,
          is_active: values.isActiveTh,
          period: isDuty || values.periodTh === '' ? 0 : values.periodTh
        },
        4: {
          start: values.startFr ? values.startFr + ':00' : null,
          end: values.endFr ? values.endFr + ':00' : null,
          dutyDoctorMode: isDuty,
          is_active: values.isActiveFr,
          period: isDuty || values.periodFr === '' ? 0 : values.periodFr
        },
        5: {
          start: values.startSa ? values.startSa + ':00' : null,
          end: values.endSa ? values.endSa + ':00' : null,
          dutyDoctorMode: isDuty,
          is_active: values.isActiveSa,
          period: isDuty || values.periodSa === '' ? 0 : values.periodSa
        },
        6: {
          start: values.startSu ? values.startSu + ':00' : null,
          end: values.endSu ? values.endSu + ':00' : null,
          dutyDoctorMode: isDuty,
          is_active: values.isActiveSu,
          period: isDuty || values.periodSu === '' ? 0 : values.periodSu
        }
      }

      try {
        if (specialist.isDuty) {
          await _createScheduleFullYearDuty({
            specialistId: specialist.id,
            startDate: `${currentDate.getFullYear()}-${
              currentDate.getMonth() + 1
            }-${currentDate.getDate()}`,
            weekData: weeksData
          })
        } else {
          await _createScheduleFullYear({
            specialistId: specialist.id,
            startDate: `${currentDate.getFullYear()}-${
              currentDate.getMonth() + 1
            }-${currentDate.getDate()}`,
            weekData: weeksData
          })
        }

        closeModal()
      } catch (error) {
        setError('Невозможно создать расписание начиная с выбранной даты')
        setTimeout(() => {
          setError('')
        }, 5000)
      }
    }
  })

  let type = ''
  const onChange = (data, type) => {
    formik.setFieldValue(type, data.target.value)
  }

  return (
    <div>
      <div
        style={{
          marginTop: '1em',
          marginBottom: '1em',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            marginTop: '1em',
            marginBottom: '1em',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <p>
            Выбранная дата{' '}
            <span style={{ fontWeight: '700' }}>
              {moment(currentDate).format('DD.MM.YYYY')}
            </span>
          </p>
          {error && <div className={classes.error}>{error}</div>}
        </div>
        <Button
          text={'Сохранить'}
          otherStyles={{
            width: '200px',
            height: '40px',
            backgroundColor: palette.blueButton,
            color: palette.white,
            borderRadius: '10px',
            margin: '0'
          }}
          onClick={formik.handleSubmit}
        />
      </div>

      <div
        style={{
          marginTop: '1em',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div className={classes.work}>
          <p className={classes.blockWorkTitle}>Рабочее время (ПН)</p>
          <div className={classes.blockWork}>
            <p className={classes.blockWorkText}>С</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.startMn}
              onChange={(data) => onChange(data, (type = 'startMn'))}
            >
              <Input
                formik={formik}
                fieldName={'startMn'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
            <p className={classes.blockWorkText}>до</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.endMn}
              onChange={(data) => onChange(data, (type = 'endMn'))}
            >
              <Input
                formik={formik}
                fieldName={'endMn'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
          </div>
          {!isDuty && (
            <div className={classes.blockWork}>
              <p className={classes.blockWorkText}>Окна записи:</p>
              <Input
                formik={formik}
                fieldName={'periodMn'}
                otherStyles={{ width: '130px' }}
                type="number"
              />
              <p className={classes.blockWorkText}>минут</p>
            </div>
          )}
          <div className={classes.checkbox}>
            <Checkbox
              label="Рабочий день"
              formik={formik}
              fieldName={'isActiveMn'}
            />
          </div>
        </div>
        <div className={classes.work}>
          <p className={classes.blockWorkTitle}>Рабочее время (ВТ)</p>
          <div className={classes.blockWork}>
            <p className={classes.blockWorkText}>С</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.startTu}
              onChange={(data) => onChange(data, (type = 'startTu'))}
            >
              <Input
                formik={formik}
                fieldName={'startTu'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
            <p className={classes.blockWorkText}>до</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.endTu}
              onChange={(data) => onChange(data, (type = 'endTu'))}
            >
              <Input
                formik={formik}
                fieldName={'endTu'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
          </div>
          {!isDuty && (
            <div className={classes.blockWork}>
              <p className={classes.blockWorkText}>Окна записи:</p>
              <Input
                formik={formik}
                fieldName={'periodTu'}
                otherStyles={{ width: '130px' }}
                type="number"
              />
              <p className={classes.blockWorkText}>минут</p>
            </div>
          )}
          <div className={classes.checkbox}>
            <Checkbox
              label="Рабочий день"
              formik={formik}
              fieldName={'isActiveTu'}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: '0.5em',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div className={classes.work}>
          <p className={classes.blockWorkTitle}>Рабочее время (СР)</p>
          <div className={classes.blockWork}>
            <p className={classes.blockWorkText}>С</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.startWe}
              onChange={(data) => onChange(data, (type = 'startWe'))}
            >
              <Input
                formik={formik}
                fieldName={'startWe'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
            <p className={classes.blockWorkText}>до</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.endWe}
              onChange={(data) => onChange(data, (type = 'endWe'))}
            >
              <Input
                formik={formik}
                fieldName={'endWe'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
          </div>
          {!isDuty && (
            <div className={classes.blockWork}>
              <p className={classes.blockWorkText}>Окна записи:</p>
              <Input
                formik={formik}
                fieldName={'periodWe'}
                otherStyles={{ width: '130px' }}
                type="number"
              />
              <p className={classes.blockWorkText}>минут</p>
            </div>
          )}
          <div className={classes.checkbox}>
            <Checkbox
              label="Рабочий день"
              formik={formik}
              fieldName={'isActiveWe'}
            />
          </div>
        </div>
        <div className={classes.work}>
          <p className={classes.blockWorkTitle}>Рабочее время (ЧТ)</p>
          <div className={classes.blockWork}>
            <p className={classes.blockWorkText}>С</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.startTh}
              onChange={(data) => onChange(data, (type = 'startTh'))}
            >
              <Input
                formik={formik}
                fieldName={'startTh'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
            <p className={classes.blockWorkText}>до</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.endTh}
              onChange={(data) => onChange(data, (type = 'endTh'))}
            >
              <Input
                formik={formik}
                fieldName={'endTh'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
          </div>
          {!isDuty && (
            <div className={classes.blockWork}>
              <p className={classes.blockWorkText}>Окна записи:</p>
              <Input
                formik={formik}
                fieldName={'periodTh'}
                otherStyles={{ width: '130px' }}
                type="number"
              />
              <p className={classes.blockWorkText}>минут</p>
            </div>
          )}
          <div className={classes.checkbox}>
            <Checkbox
              label="Рабочий день"
              formik={formik}
              fieldName={'isActiveTh'}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: '0.5em',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div className={classes.work}>
          <p className={classes.blockWorkTitle}>Рабочее время (ПТ)</p>
          <div className={classes.blockWork}>
            <p className={classes.blockWorkText}>С</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.startFr}
              onChange={(data) => onChange(data, (type = 'startFr'))}
            >
              <Input
                formik={formik}
                fieldName={'startFr'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
            <p className={classes.blockWorkText}>до</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.endFr}
              onChange={(data) => onChange(data, (type = 'endFr'))}
            >
              <Input
                formik={formik}
                fieldName={'endFr'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
          </div>
          {!isDuty && (
            <div className={classes.blockWork}>
              <p className={classes.blockWorkText}>Окна записи:</p>
              <Input
                formik={formik}
                fieldName={'periodFr'}
                otherStyles={{ width: '130px' }}
                type="number"
              />
              <p className={classes.blockWorkText}>минут</p>
            </div>
          )}
          <div className={classes.checkbox}>
            <Checkbox
              label="Рабочий день"
              formik={formik}
              fieldName={'isActiveFr'}
            />
          </div>
        </div>
        <div className={classes.work}>
          <p className={classes.blockWorkTitle}>Рабочее время (СБ)</p>
          <div className={classes.blockWork}>
            <p className={classes.blockWorkText}>С</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.startSa}
              onChange={(data) => onChange(data, (type = 'startSa'))}
            >
              <Input
                formik={formik}
                fieldName={'startSa'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
            <p className={classes.blockWorkText}>до</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.endSa}
              onChange={(data) => onChange(data, (type = 'endSa'))}
            >
              <Input
                formik={formik}
                fieldName={'endSa'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
          </div>
          {!isDuty && (
            <div className={classes.blockWork}>
              <p className={classes.blockWorkText}>Окна записи:</p>
              <Input
                formik={formik}
                fieldName={'periodSa'}
                otherStyles={{ width: '130px' }}
                type="number"
              />
              <p className={classes.blockWorkText}>минут</p>
            </div>
          )}
          <div className={classes.checkbox}>
            <Checkbox
              label="Рабочий день"
              formik={formik}
              fieldName={'isActiveSa'}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: '0.5em',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div className={classes.work}>
          <p className={classes.blockWorkTitle}>Рабочее время (ВС)</p>
          <div className={classes.blockWork}>
            <p className={classes.blockWorkText}>С</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.startSu}
              onChange={(data) => onChange(data, (type = 'startSu'))}
            >
              <Input
                formik={formik}
                fieldName={'startSu'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
            <p className={classes.blockWorkText}>до</p>
            <InputMask
              mask={'99:99'}
              maskPlaceholder={''}
              value={formik.values.endSu}
              onChange={(data) => onChange(data, (type = 'endSu'))}
            >
              <Input
                formik={formik}
                fieldName={'endSu'}
                otherStyles={{ width: '130px' }}
              />
            </InputMask>
          </div>
          {!isDuty && (
            <div className={classes.blockWork}>
              <p className={classes.blockWorkText}>Окна записи:</p>
              <Input
                formik={formik}
                fieldName={'periodSu'}
                otherStyles={{ width: '130px' }}
                type="number"
              />
              <p className={classes.blockWorkText}>минут</p>
            </div>
          )}
          <div className={classes.checkbox}>
            <Checkbox
              label="Рабочий день"
              formik={formik}
              fieldName={'isActiveSu'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = () => ({
  work: {
    backgroundColor: palette.calendar,
    borderRadius: '20px',
    padding: '15px',
    marginRight: '10px'
    // margin: '20px 0'
  },
  blockWork: {
    display: 'flex',
    alignItems: 'center'
  },
  scheduleReceptions: {
    width: '400px'
  },
  blockWorkTitle: {
    fontWeight: '600',
    textAlign: 'center'
  },
  blockWorkText: {
    margin: '0 5px'
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em 0'
  },
  error: {
    color: palette.red,
    marginTop: '1em',
    marginBottom: '1em',
    fontSize: '14px',
    alignSelf: 'flex-end'
  }
})

export default withStyles(styles)(ScheduleFullYear)
