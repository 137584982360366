export const getSpecialistsItems = (state) => state.specialists.specialists
export const getSpecialistsPagination = (state) => state.specialists.pagination
export const getIsLoadingSpecialists = (state) => state.specialists.isLoading

export const getAllSpecialistsItems = (state) =>
  state.specialists.allSpecialists

export const getSpecialitiesItems = (state) => state.specialists.specialities

export const getSchedulesItems = (state) => state.specialists.shedules
export const getScheduleId = (state) => state.specialists.scheduleId
export const getScheduleReceptionsItems = (state) =>
  state.specialists.scheduleReceptions

export const getSpecialist = (state) => state.specialists.specialist

export const getSpecialitiesId = (state) => {
  if (!state.specialists.specialist) return []

  return state.specialists.specialist.specialities.map(
    (speciality) => speciality.id
  )
}

export const getRegionItems = (state) => state.specialists.regions
