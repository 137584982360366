import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import { palette } from 'styles/colors'

const ButtonUp = ({ openCreate, text }) => {
  return (
    <Button
      onClick={() => openCreate()}
      text={'↑'}
      otherStyles={{
        backgroundColor: palette.blue,
        width: '10px',
        height: '40px',
        color: palette.white,
        marginRight: '2em',
        boxShadow: '0px 10px 20px rgba(0, 156, 205, 0.25)',
        position: 'absolute',
        right: 5,
        bottom: 50
      }}
    />
  )
}

const styles = () => ({})

export default withStyles(styles)(ButtonUp)
