import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { palette } from 'styles/colors'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'components/Button'
import Input from 'components/Input'

const reviewSchema = Yup.object().shape({
  appointmentDescription: Yup.string().min(3, 'Отзыв слишком короткий'),
  appointmentMark: Yup.string()
})

const ReviewReception = ({ classes, editedObj, handleSubmit, isLoading }) => {
  const formik = useFormik({
    initialValues: {
      appointmentDescription: editedObj ? editedObj.appointmentDescription : '',
      appointmentMark: editedObj ? editedObj.appointmentMark : ''
    },
    validationSchema: reviewSchema,
    onSubmit: (values, { setFieldError }) => {
      const validMarks = ['1', '2', '3', '4', '5']

      if (!validMarks.includes(String(values.appointmentMark))) {
        setFieldError(
          'appointmentMark',
          'Оценка может быть в диапазоне от 1 до 5'
        )
        return
      }

      handleSubmit({ ...values })
    }
  })

  const [isEdit, setIsEdit] = useState(false)

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <Typography
          style={{ fontWeight: 'bold', marginRight: '1em' }}
          variant="h6"
        >
          Отзыв
        </Typography>
        {isEdit ? (
          <Input
            formik={formik}
            fieldName={'appointmentDescription'}
            variant={'standard'}
            otherStyles={{ width: '100%' }}
            standard
          />
        ) : (
          <Typography variant="subtitle1">
            {editedObj.appointmentDescription
              ? editedObj.appointmentDescription
              : 'Нет отзыва'}
          </Typography>
        )}
      </div>
      <div className={classes.block}>
        <Typography
          style={{ fontWeight: 'bold', marginRight: '1em' }}
          variant="h6"
        >
          Оценка
        </Typography>
        {isEdit ? (
          <Input
            formik={formik}
            fieldName={'appointmentMark'}
            variant={'standard'}
            otherStyles={{ width: '100%' }}
            standard
          />
        ) : (
          <Typography variant="subtitle1">
            {editedObj.appointmentMark
              ? editedObj.appointmentMark
              : 'Нет оценки'}
          </Typography>
        )}
      </div>
      <div className={classes.buttons}>
        {isEdit ? (
          <>
            <Button
              text={'Отменить'}
              handeAction={() => {
                setIsEdit(false)
                formik.setFieldValue(
                  'appointmentDescription',
                  editedObj ? editedObj.appointmentDescription : ''
                )
                formik.setFieldValue(
                  'appointmentMark',
                  editedObj ? editedObj.appointmentMark : ''
                )
              }}
              otherStyles={{
                width: '280px',
                height: '45px',
                backgroundColor: palette.blueButton,
                color: palette.white,
                margin: '0 1em 0 0'
              }}
            />
            <Button
              handeAction={formik.handleSubmit}
              text={'Сохранить'}
              otherStyles={{
                width: '280px',
                height: '45px',
                backgroundColor: palette.blueButton,
                color: palette.white,
                margin: '0 1em 0 0'
              }}
            />
          </>
        ) : (
          <Button
            text={'Редактировать'}
            handeAction={() => setIsEdit(true)}
            otherStyles={{
              width: '280px',
              height: '45px',
              backgroundColor: palette.blueButton,
              color: palette.white
            }}
          />
        )}
      </div>
      <div className={classes.buttons}>
        <Button
          text={'Удалить отзыв'}
          handeAction={() => handleSubmit()}
          otherStyles={{
            width: '280px',
            height: '45px',
            backgroundColor: palette.blueButton,
            color: palette.white,
            margin: '1em 0 1em 0'
          }}
          disabled={
            !editedObj.appointmentDescription && !editedObj.appointmentMark
          }
        />
      </div>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    width: '700px',
    margin: '2em 0 0 0'
  },

  block: {
    margin: '1em 0'
  },
  buttons: {
    textAlign: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    }
  }
})

export default withStyles(styles)(ReviewReception)
