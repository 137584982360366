import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import deleteIcon from 'assets/delete.png'
import Button from 'components/Button'

const Pharmacies = ({ 
  classes, 
  pharmacies, 
  openDelete, 
  openEdit 
}) => {
  return pharmacies.map((pharmacy) => (
    <TableRow key={pharmacy.id}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {pharmacy.title}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        <Button
          text={'Подробнее'}
          otherStyles={{ background: 'none', boxShadow: 'none', margin: '0' }}
          onClick={() => openEdit({ ...pharmacy })}
        />
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openDelete(pharmacy)
          }}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(Pharmacies)