import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { columnsNotifications } from 'data/columns'
import { useDispatch, useSelector } from 'react-redux'
import Menu from 'components/Menu'
import TableWrapper from 'components/Table'
import NoContent from 'components/NoContent'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Notification from 'components/Modals/Notification'
import {
  getNotificationsItems,
  getIsLoadingNotifications,
  getNotificationsPagination
} from 'redux/selectors/notifications'
import { getNotifications, uploadBanner } from 'redux/modules/notifications/actions'
import { palette } from 'styles/colors'
import Button from 'components/Button'
import CreateNotification from 'components/Modals/CreateNotification'
import UploadXls from 'components/UploadXls'

const Notifications = ({ classes }) => {
  const dispatch = useDispatch()

  const notifications = useSelector(getNotificationsItems)
  const isRequestsLoading = useSelector(getIsLoadingNotifications)
  const notificationsPagination = useSelector(getNotificationsPagination)

  const fetchNotifications = (
    page = notificationsPagination.page,
    newCount
  ) => {
    dispatch(
      getNotifications(
        {},
        () => {},
        () => {},
        {
          page,
          count: newCount || notificationsPagination.perPage
        }
      )
    )
  }

  useEffect(() => {
    fetchNotifications(notificationsPagination.page)
  }, [])

  useEffect(() => {
    document.title = 'Уведомления'
  }, [])

  const [notification, setNotification] = useState('')
  const [isOpenedTitle, setIsOpenedTitle] = useState(false)

  const openTitle = (editedNotification) => {
    setNotification(editedNotification)
    setIsOpenedTitle(true)
  }
  const closeTitle = (editedNotification) => {
    // setNotification(editedNotification)
    setIsOpenedTitle(false)
  }

  const [isOpenedCreate, setIsOpenedCreate] = useState(false)

  const openCreate = () => {
    setIsOpenedCreate(true)
  }
  const closeCreate = () => {
    setIsOpenedCreate(false)
    fetchNotifications(notificationsPagination.page)
  }

  const [isLoadingFile, setIsLoadingFile] = useState(false)

  const handleChange = (event) => {
    
    const formData = new FormData()
    formData.append('file', event.target.files[0])

    setIsLoadingFile(true)

    dispatch(
      uploadBanner(
        { file: formData },
        () => {
          setIsLoadingFile(false)
        },
        () => {
          setIsLoadingFile(false)
        }
      )
    )
  }

  return (
    <div className={classes.wrapper}>
      <Menu />
      <div className={classes.table}>
        <div className={classes.header}>
          <Button
            text={'Создать уведомление'}
            otherStyles={{
              width: '200px',
              height: '45px',
              backgroundColor: palette.blueButton,
              color: palette.white,
              marginRight: '2em',
              marginLeft: '2em',
              marginTop: 0
            }}
            handeAction={() => openCreate()}
          />
          <UploadXls
            exportText={'Загрузить рекламный баннер'}
            handleChange={handleChange}
            isLoading={isLoadingFile}
          />
        </div>
        {notifications.length > 0 || isRequestsLoading ? (
          <>
            <div className={classes.tableWrapper}>
              <TableWrapper
                page={notificationsPagination.page}
                count={notificationsPagination.perPage}
                amount={notificationsPagination.amount}
                items={notifications}
                onChangePage={fetchNotifications}
                onChangePerPage={(newCount) => fetchNotifications(1, newCount)}
                isLoading={isRequestsLoading}
                columns={columnsNotifications}
                tableType={'notifications'}
                actions={{
                  openTitle: openTitle
                }}
              />
            </div>
          </>
        ) : (
          <NoContent text={'Нет уведомлений'} />
        )}
      </div>
      <ModalWrapper
        title={notification.title}
        isOpened={isOpenedTitle}
        onClose={() => closeTitle()}
      >
        <Notification notification={notification} />
      </ModalWrapper>
      <ModalWrapper
        title={'Создание уведомления'}
        isOpened={isOpenedCreate}
        onClose={() => closeCreate()}
      >
        <CreateNotification onClose={() => closeCreate()} />
      </ModalWrapper>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1.5em 0'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})

export default withStyles(styles)(Notifications)
