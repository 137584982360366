import React from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'

const ImportPatients = ({ classes, patients }) => {
  return patients.map((patient) => (
    <TableRow key={patient.phone}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black, padding: '0.5em' }}
        align="center"
      >
        {patient.phone ?? '-'}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.inn ?? '-'}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.num ?? '-'}
      </TableCell>
      <TableCell
        style={{ width: '150px', borderColor: palette.black }}
        align="center"
      >
        {patient.fullName ?? '-'}
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(ImportPatients)
