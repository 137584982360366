import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Menu from 'components/Menu'
import Input from 'components/Input'
import Button from 'components/Button'
import { palette } from 'styles/colors'
import { InputAdornment, IconButton } from '@material-ui/core'
import { columnsModerator } from 'data/columns'
import { useFormik } from 'formik'
import searchIcon from 'assets/trailing.png'
import { Close } from '@material-ui/icons'
import TableWrapper from 'components/Table'
import NoContent from 'components/NoContent'
import Moderator from 'components/Modals/Moderator'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Delete from 'components/Modals/Delete'
import ButtonCreate from 'components/ButtonCreate'
import {
  getModeratorsItems,
  getModeratorsPagination,
  getIsLoadingModerators
} from 'redux/selectors/moderators'
import {
  createModerator as createModeratorAction,
  editModerator as editModeratorAction,
  deleteModerator as deleteModeratorAction,
  getModerators,
  SET_ACTIVE,
  SET_MODERATOR
} from 'redux/modules/moderators/actions'
import ResetPassword from 'components/Modals/ResetPassword'

const Moderators = ({ classes }) => {
  const dispatch = useDispatch()

  const moderators = useSelector(getModeratorsItems)
  const isRequestsLoading = useSelector(getIsLoadingModerators)
  const moderatorsPagination = useSelector(getModeratorsPagination)

  const formik = useFormik({
    initialValues: {
      search: ''
    },
    onSubmit: (values, { setFieldError }) => {
      fetchModerators(1)
    }
  })

  const fetchModerators = (page = moderatorsPagination.page, newCount) => {
    dispatch(
      getModerators(
        {},
        () => {},
        () => {},
        {
          page,
          count: newCount || moderatorsPagination.perPage,
          phrase: formik.values.search
        }
      )
    )
  }

  useEffect(() => {
    fetchModerators(moderatorsPagination.page)
  }, [])

  useEffect(() => {
    document.title = 'Модераторы'
  }, [])

  const [moderator, setModerator] = useState('')
  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = (editedModerator) => {
    setModerator(editedModerator)
    setIsOpenedEdit(true)
  }
  const closeEditModerator = (editedModerator) => {
    // setPharmacies(editedModerator)
    setIsOpenedEdit(false)
  }

  const [isOpenedResetPassword, setIsOpenedResetPassword] = useState(false)

  const openResetPassword = (editedModerator) => {
    setModerator(editedModerator)
    setIsOpenedResetPassword(true)
  }
  const closeResetPassword = () => {
    setIsOpenedResetPassword(false)
  }

  const [isOpenedDelete, setIsOpenedDelete] = useState(false)
  const [deletedModerator, setDeletedModerator] = useState(null)

  const openDelete = (moderator) => {
    setDeletedModerator(moderator)
    setIsOpenedDelete(true)
  }

  const closeDelete = () => {
    setDeletedModerator(null)
    setIsOpenedDelete(false)
  }

  const [isOpenedCreateModerator, setIsOpenedCreateModerator] = useState(false)

  const openCreateModerator = () => {
    setIsOpenedCreateModerator(true)
  }
  const closeCreateModerator = () => {
    setIsOpenedCreateModerator(false)
  }

  const [isLoading, setIsLoading] = useState(false)

  const createModerator = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      createModeratorAction(
        {
          login: values.login,
          password: values.password,
          fullName: values.fullName,
          status: values.status
        },
        (data) => {
          fetchModerators(1)
          setIsOpenedCreateModerator(false)
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
          if (error.status === 403) {
            setFieldError('login', 'Такой логин уже существует')
          }
        }
      )
    )
  }

  const editModerator = (values, { setFieldError }) => {
    dispatch(
      editModeratorAction(
        {
          id: moderator.id,
          login: values.login,
          password: values.password,
          fullName: values.fullName,
          status: values.status
        },
        () => {
          fetchModerators(moderatorsPagination.page)
          closeEditModerator()
          closeResetPassword()
        },
        () => setFieldError('login', 'Такой логин уже существует')
      )
    )
  }

  const deleteModerator = () => {
    dispatch(
      deleteModeratorAction(
        { id: deletedModerator.id },
        () => {
          fetchModerators(1)
          setIsOpenedDelete(false)
        },
        () => {}
      )
    )
  }

  const changeChekbox = (editModerator, type, index) => {
    dispatch(
      editModeratorAction(
        {
          id: editModerator.id,
          login: editModerator.login,
          fullName: editModerator.fullName,
          status:
            type === 'status'
              ? editModerator.status === 'call'
                ? 'moderator'
                : 'call'
              : editModerator.status,
          isActive:
            type === 'isActive'
              ? !editModerator.isActive
              : editModerator.isActive
        },
        () => {
          type === 'isActive'
            ? dispatch({ type: SET_ACTIVE, payload: { editModerator, index } })
            : dispatch({
                type: SET_MODERATOR,
                payload: { editModerator, index }
              })
        },
        () => {}
      )
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.wrapper}>
        <Menu />
        <div className={classes.table}>
          <div className={classes.search}>
            <Input
              formik={formik}
              fieldName={'search'}
              label={'Найти модератора'}
              endAdornment={
                formik.values.search.length > 0 ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle"
                      style={{
                        color: palette.blue,
                        width: '40px',
                        height: '40px'
                      }}
                      onClick={() => {
                        formik.setFieldValue('search', '')
                        formik.handleSubmit()
                      }}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
            />
            <Button
              text={'Найти'}
              otherStyles={{
                width: '200px',
                height: '45px',
                backgroundColor: palette.blueButton,
                color: palette.white,
                marginRight: '2em',
                marginLeft: '2em'
              }}
              type="submit"
            />
          </div>
          {moderators.length > 0 || isRequestsLoading ? (
            <>
              <div className={classes.tableWrapper}>
                <TableWrapper
                  page={moderatorsPagination.page}
                  count={moderatorsPagination.perPage}
                  amount={moderatorsPagination.amount - 1}
                  items={moderators}
                  onChangePage={fetchModerators}
                  onChangePerPage={(newCount) => fetchModerators(1, newCount)}
                  isLoading={isRequestsLoading}
                  columns={columnsModerator}
                  tableType={'moderators'}
                  actions={{
                    openEdit: openEdit,
                    openResetPassword: openResetPassword,
                    openDelete: openDelete,
                    changeChekbox: changeChekbox
                  }}
                />
              </div>
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreateModerator}
                  text="Добавить сотрудника"
                />
              </div>
            </>
          ) : (
            <>
              <NoContent text={'Нет специалистов'} />
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreateModerator}
                  text="Добавить сотрудника"
                />
              </div>
            </>
          )}
        </div>
        <ModalWrapper
          title={'Вы уверены?'}
          isOpened={isOpenedDelete}
          onClose={() => closeDelete()}
        >
          <Delete
            onDelete={() => deleteModerator()}
            onClose={() => closeDelete()}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Запись'}
          isOpened={isOpenedEdit}
          onClose={() => closeEditModerator()}
          style={{ width: '600px' }}
        >
          <Moderator handleSubmit={editModerator} editedObj={moderator} />
        </ModalWrapper>
        <ModalWrapper
          title={'Запись'}
          isOpened={isOpenedResetPassword}
          onClose={() => closeResetPassword()}
          style={{ width: '600px' }}
        >
          <ResetPassword handleSubmit={editModerator} editedObj={moderator} />
        </ModalWrapper>
        <ModalWrapper
          title={'Запись'}
          isOpened={isOpenedCreateModerator}
          onClose={() => closeCreateModerator()}
          style={{ width: '600px' }}
        >
          <Moderator handleSubmit={createModerator} isLoading={isLoading} />
        </ModalWrapper>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
})

export default withStyles(styles)(Moderators)
