import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import editIcon from 'assets/edit.png'
import deleteIcon from 'assets/delete.png'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'

const Moderators = ({ 
  classes, 
  moderators, 
  openDelete, 
  openEdit,
  openResetPassword,
  changeChekbox
}) => {
  let type = ''

  return moderators.map((moderator, index) => (
    <TableRow key={moderator.id}>
      {moderator.status !== 'admin' ? 
        <>
          <TableCell
            style={{ width: '100px', borderColor: palette.black }}
            align="center"
          >
            {moderator.login}
          </TableCell>
          <TableCell
            style={{ width: '100px', borderColor: palette.black }}
            align="center"
          >
            <Button
              text={'Сбросить пароль'}
              otherStyles={{ background: 'none', boxShadow: 'none', margin: '0', color: palette.blue}}
              onClick={() => openResetPassword({ ...moderator })}
            />
          </TableCell>
          <TableCell
            style={{ width: '100px', borderColor: palette.black }}
            align="center"
          >
            <Checkbox 
              isChecked={moderator.isActive} 
              otherStyles={{ display: 'flex', justifyContent: 'center' }} 
              onClick={() => changeChekbox({ ...moderator }, type = 'isActive', index)}
            />
          </TableCell>
          <TableCell
            style={{ width: '100px', borderColor: palette.black }}
            align="center"
          >
            <Checkbox 
              isChecked={moderator.status === 'moderator' ? true : false} 
              otherStyles={{ display: 'flex', justifyContent: 'center' }} 
              onClick={() => changeChekbox({ ...moderator }, type = 'status', index)}
            />
          </TableCell>
          <TableCell
            style={{ width: '20px', borderColor: palette.black }}
            align="center"
          >
            <IconButton
              onClick={() =>
                openEdit({ ...moderator })
              }
            >
              <img src={editIcon} alt="edit" width="15" />
            </IconButton>
          </TableCell>
          <TableCell
            style={{ width: '20px', borderColor: palette.black }}
            align="center"
          >
            <IconButton
              onClick={() => {
                openDelete(moderator)
              }}
            >
              <img src={deleteIcon} alt="delete" width="15" />
            </IconButton>
          </TableCell>
        </>
      : (<></>)}
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(Moderators)