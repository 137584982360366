import React from 'react'
import { withStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'

const Medcard = ({ classes, medcard }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
          Тема приема
        </Typography>
        <Typography variant="subtitle1">{medcard.theme}</Typography>
      </div>
      <div className={classes.block}>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
          Рекомендации
        </Typography>
        <Typography variant="subtitle1" style={{ width: '80%' }}>
          {medcard.recomendation ? medcard.recomendation : 'Нет рекомендаций'}
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
          Анализы
        </Typography>
        {medcard.medicalTests.length > 0 ? (
          <>
            {medcard.medicalTests.map((test, index) => (
              <Typography variant="subtitle1" key={index}>
                {test.title}
              </Typography>
            ))}
          </>
        ) : (
          <Typography variant="subtitle1">Нет анализов</Typography>
        )}
      </div>
      {/* <div className={classes.block}>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">Лекарства</Typography>
        {medcard.medicines.length > 0 ? ( 
          <>
            {medcard.medicines.map((medicine, index) => (
              <Typography variant="subtitle1" key={index}>
                {medicine.title}
              </Typography>
            ))}
          </>
        ) : (
          <Typography variant="subtitle1">Нет лекарств</Typography>
        )}
      </div> */}
    </div>
  )
}

const styles = () => ({
  wrapper: {
    width: '800px'
  },

  block: {
    margin: '1em 0'
  }
})

export default withStyles(styles)(Medcard)
