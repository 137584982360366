import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Calendar from 'arui-feather/calendar'
import Button from 'components/Button'
import { palette } from 'styles/colors'
import { Typography } from '@material-ui/core'
import { getSchedules } from 'redux/modules/specialists/actions'
import { getSchedulesItems, getScheduleId } from 'redux/selectors/specialists'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'components/Input'

const EditDate = ({
  classes,
  formik,
  editedObj,
  specialist,
  times,
  editScheduleReceptions,
  isPatient,
  fetch,
  isChangeTime
}) => {
  const dispatch = useDispatch()

  const [date, setDate] = useState(
    editedObj ? editedObj.receptionDate : new Date()
  )
  const [dateChange, setDateChange] = useState(new Date())
  const [color, setColor] = useState(false)
  const [freeTimes, setFreeTimes] = useState(times ? times : '')
  const [active, setActive] = useState(editedObj.receptionTime)
  const [isLoading, setIsLoading] = useState(false)

  const [error, setError] = useState('')

  const handleDate = (value) => {
    setDate(value)
    setDateChange(new Date(value))
    let newDate = new Date(value)
    formik.setFieldValue(
      'receptionDate',
      newDate.getFullYear() +
        '-' +
        (newDate.getMonth() + 1) +
        '-' +
        newDate.getDate()
    )
    fetchShedules(1, newDate.getFullYear(), newDate.getMonth() + 1)
    formatTime(newDate)
    changeWorkHours(newDate)
    setActive(null)
    formik.setFieldValue('receptionTime', '')
    setError('')
  }

  const handleClick = (event) => {
    setColor(!color)
    setActive(event.currentTarget.value)
    formik.setFieldValue('receptionTime', event.currentTarget.value)
    setError('')
  }

  const formatTime = (date) => {
    setDateChange(
      `${date.getFullYear()}-${
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)
      }-${date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()}`
    )
  }

  const fetchShedules = (page = 1, year, month) => {
    setIsLoading(true)
    dispatch(
      getSchedules(
        { id: specialist.id, year: year, month: month },
        () => {
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)

          if (error.status === 404) {
            setFreeTimes(null)
          }
        },
        {
          page,
          count: 100
        }
      )
    )
  }

  const shedules = useSelector(getSchedulesItems)
  const scheduleId = useSelector(getScheduleId)

  const changeWorkHours = (time) => {
    shedules.map((shedule) => {
      if (shedule.date == time) {
        setFreeTimes(shedule.times)
      }
    })
  }

  useEffect(() => {
    if (!editedObj) {
      fetchShedules(1, dateChange.getFullYear(), dateChange.getMonth() + 1)
      formatTime(dateChange)
      changeWorkHours(dateChange)
      formik.setFieldValue(
        'receptionDate',
        dateChange.getFullYear() +
          '-' +
          (dateChange.getMonth() + 1) +
          '-' +
          dateChange.getDate()
      )
    }
  }, [])

  useEffect(() => {
    changeWorkHours(dateChange)
  }, [shedules])

  console.log(formik)

  return (
    <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
      {isPatient && !isChangeTime && (
        <>
          <Typography
            style={{
              fontWeight: 'bold',
              alignSelf: 'flex-start'
            }}
            variant="subtitle1"
          >
            Жалоба:
          </Typography>
          <Input
            formik={formik}
            fieldName={'complaints'}
            variant={'standard'}
            otherStyles={{ width: '100%', marginBottom: '1em' }}
            standard
          />
          <Typography
            style={{
              fontWeight: 'bold',
              alignSelf: 'flex-start'
            }}
            variant="subtitle1"
          >
            Ваше имя:
          </Typography>
          <Input
            formik={formik}
            fieldName={'moderatorName'}
            variant={'standard'}
            otherStyles={{ width: '100%', marginBottom: '1.5em' }}
            standard
          />
        </>
      )}
      <Calendar
        value={date}
        onValueChange={(newDate) => handleDate(newDate)}
        showToday={true}
      />
      <div className={classes.time}>
        {freeTimes && freeTimes.length > 0 && !isLoading ? (
          freeTimes.map((freeTime, index) => (
            <Button
              text={
                freeTime.time.split(':')[0] + ':' + freeTime.time.split(':')[1]
              }
              otherStyles={{
                backgroundColor:
                  isChangeTime && !freeTime.isFree
                    ? palette.grey
                    : active === freeTime.time
                    ? palette.blue
                    : palette.white,
                border: `1px solid ${palette.blue}`,
                color: active === freeTime.time ? palette.white : palette.blue,
                borderRadius: '30px'
              }}
              onClick={handleClick}
              className={classes.timeButton}
              value={freeTime.time}
              key={index}
              disabled={!freeTime.isFree}
            />
          ))
        ) : isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: '235px',
              alignItems: 'center'
            }}
          >
            <CircularProgress size={20} color="white" />
          </div>
        ) : (
          <Typography
            style={{
              fontWeight: 'bold',
              marginBottom: '1em',
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
            variant="subtitle1"
          >
            Не найдено свободных мест для записи
          </Typography>
        )}
      </div>
      {formik.errors['receptionTime'] && (
        <div className={classes.error}>{formik.errors['receptionTime']}</div>
      )}
      <div>
        <Button
          text={'Записать'}
          otherStyles={{
            backgroundColor: palette.blue,
            border: `1px solid ${palette.blue}`,
            color: palette.white,
            borderRadius: '10px',
            width: '160px',
            height: '40px'
          }}
          type="submit"
        />
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  time: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '350px'
  },
  timeButton: {
    margin: '0 5px',
    width: '22%'
  },
  error: {
    color: palette.red,
    margin: '1em 0'
  },
  '@global': {
    '.calendar': {
      padding: '0 12px'
    },
    '.calendar__day_state_today': {
      border: `1px solid ${palette.blueButton} !important`
    },
    '.calendar__day_state_current': {
      background: `${palette.blueButton} !important`
    },
    '.calendar_theme_alfa-on-white .calendar__day_state_today': {
      boxShadow: 'none'
    },
    '.calendar__name_month, .calendar__name_year': {
      fontSize: '15px'
    }
  }
})

export default withStyles(styles)(EditDate)
