import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import editIcon from 'assets/edit.png'
import deleteIcon from 'assets/delete.png'
import Button from 'components/Button'

const Educations = ({
  classes,
  educations,
  openEditEducation,
  openDelete
}) => {
  let type = ''
  
  return educations.map((education, index) => (
    <TableRow key={index}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {education.typeEducation}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {education.institution}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {education.speciality}
      </TableCell>
      <TableCell
        style={{ width: '80px', borderColor: palette.black }}
        align="center"
      >
        {education.yearGraduated}
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() =>
            openEditEducation({ ...education }, index, type = 'education')
          }
        >
          <img src={editIcon} alt="edit" width="15" />
        </IconButton>
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openDelete(index, type = 'education')
          }}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(Educations)