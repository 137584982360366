import React, { useRef } from 'react'
import Button from './Button'
import { palette } from 'styles/colors'

const UploadXls = ({ exportText, isLoading, handleChange, disabled }) => {
  const onClickExport = () => {
    fileInputRef.current.click()
  }
  const fileInputRef = useRef()

  return (
    <>
      <Button
        text={exportText}
        otherStyles={{
          width: '270px',
          height: '45px',
          backgroundColor: disabled ? "#C7C5C6": palette.blueButton,
          color: palette.white,
          marginRight: '2em',
          marginLeft: '2em',
          marginTop: 0
        }}
        onClick={onClickExport}
        loader={isLoading}
        disabled={disabled}
      />
      <input
        onChange={handleChange}
        multiple={false}
        ref={fileInputRef}
        type="file"
        disabled={disabled}
        hidden
      />
    </>
  )
}

export default UploadXls
