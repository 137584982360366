import axios from '../index'

export const _getPromocodes = (data, paging) =>
  axios.get(
    `/promo_codes/?page=${paging.page}&count=${paging.count}&phrase=${paging.phrase}`,
    data
  )

export const _createPromocode = (data) => axios.post('/promo_codes/', data)

export const _editPromocode = (data) =>
  axios.patch(`/promo_codes/${data.id}`, data)

export const _deletePromocode = (data) =>
  axios.delete(`/promo_codes/${data.id}`, data)
