import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { palette } from 'styles/colors'
import NoContent from 'components/NoContent'
import TableWrapper from 'components/Table'
import Menu from 'components/Menu'
import Button from 'components/Button'
import Clinic from 'components/Modals/Clinic'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Delete from 'components/Modals/Delete'
import { columnsClinics } from 'data/columns'
import ControlBlockExport from 'components/ControlBlockExport'
import ButtonCreate from 'components/ButtonCreate'
import {
  getClinicsItems,
  getIsLoadingClinics,
  getClinicssPagination
} from 'redux/selectors/clinics'
import {
  createClinic as createClinicAction,
  editClinic as editClinicAction,
  deleteClinic as deleteClinicAction,
  uploadMedia,
  uploadFile,
  getClinics
} from 'redux/modules/clinics/actions'

const Clinics = ({ classes }) => {
  const dispatch = useDispatch()

  const clinics = useSelector(getClinicsItems)
  const isRequestsLoading = useSelector(getIsLoadingClinics)
  const clinicsPagination = useSelector(getClinicssPagination)

  const formik = useFormik({
    initialValues: {
      search: ''
    },
    onSubmit: (values, { setFieldError }) => {
      fetchClinics(1)
    }
  })

  const fetchClinics = (page = clinicsPagination.page, newCount) => {
    dispatch(
      getClinics(
        {},
        () => {},
        () => {},
        {
          page,
          count: newCount || clinicsPagination.perPage,
          phrase: formik.values.search
        }
      )
    )
  }

  useEffect(() => {
    fetchClinics(clinicsPagination.page)
  }, [])

  useEffect(() => {
    document.title = 'Клиники'
  }, [])

  const [clinic, setClinic] = useState('')
  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = (editedClinic) => {
    setClinic(editedClinic)
    setIsOpenedEdit(true)
  }
  const closeEdit = () => {
    setIsOpenedEdit(false)
  }

  const [isOpenedDelete, setIsOpenedDelete] = useState(false)
  const [deletedClinic, setDeletedClinic] = useState(null)

  const openDelete = (clinic) => {
    setDeletedClinic(clinic)
    setIsOpenedDelete(true)
  }

  const closeDelete = () => {
    setDeletedClinic(null)
    setIsOpenedDelete(false)
  }

  const [isOpenedCreateClinic, setIsOpenedCreateClinic] = useState(false)

  const openCreateClinic = () => {
    setIsOpenedCreateClinic(true)
  }
  const closeCreateClinic = () => {
    setIsOpenedCreateClinic(false)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingFile, setIsLoadingFile] = useState(false)

  const createClinic = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      createClinicAction(
        {
          title: values.title,
          assignment: values.assignment,
          address: values.address,
          phone: values.phone.replace(/,/g, ''),
          latitude: values.latitude,
          longitude: values.longitude,
          town: values.town
        },
        (data) => {
          if (!values.image || typeof values.image === 'string') {
            fetchClinics(1)
            setIsOpenedCreateClinic(false)
          } else {
            editClinicMedia(data.id, values.image)
          }
          setIsLoading(false)
        },
        (error) => {
          if (error.status === 403) {
            setFieldError('title', 'Такое название уже существует')
          }
          setIsLoading(false)
        }
      )
    )
  }

  const editClinic = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      editClinicAction(
        {
          id: clinic.id,
          title: values.title,
          assignment: values.assignment,
          address: values.address,
          phone: values.phone.replace(/,/g, ''),
          latitude: values.latitude,
          longitude: values.longitude,
          town: values.town
        },
        (data) => {
          if (!values.image || typeof values.image === 'string') {
            fetchClinics(clinicsPagination.page)
            closeEdit()
            setIsLoading(false)
          } else {
            editClinicMedia(data.id, values.image)
          }
        },
        () => {
          setFieldError('title', 'Такое название уже существует')
          setIsLoading(false)
        }
      )
    )
  }

  const editClinicMedia = (id, value) => {
    const formData = new FormData()
    formData.append('image', value)

    dispatch(
      uploadMedia(
        { id: id, image: formData },
        (data) => {
          fetchClinics(1)
          setIsOpenedCreateClinic(false)
          closeEdit()
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const deleteClinic = () => {
    dispatch(
      deleteClinicAction(
        { id: deletedClinic.id },
        () => {
          fetchClinics(1)
          setIsOpenedDelete(false)
        },
        () => {}
      )
    )
  }

  const fileInputRef = useRef()

  const onClickExport = () => {
    fileInputRef.current.click()
  }

  const handleChange = (event) => {
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    setIsLoadingFile(true)

    dispatch(
      uploadFile(
        { file: formData },
        () => {
          fetchClinics(clinicsPagination.page)
          formData.forEach(function (val, key, fD) {
            formData.delete(key)
          })
          setIsLoadingFile(false)
        },
        () => {
          setIsLoadingFile(false)
        }
      )
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.wrapper}>
        <Menu />
        <div className={classes.table}>
          <div className={classes.control}>
            <ControlBlockExport
              formik={formik}
              searchText={'Найти клинику'}
              exportText={'Загрузить EXL или CSV'}
              fieldName={'search'}
              onClickExport={onClickExport}
              handleChange={handleChange}
              fileInputRef={fileInputRef}
              isLoading={isLoadingFile}
            />
          </div>
          {clinics.length > 0 || isRequestsLoading ? (
            <>
              <div className={classes.tableWrapper}>
                <TableWrapper
                  page={clinicsPagination.page}
                  count={clinicsPagination.perPage}
                  amount={clinicsPagination.amount}
                  items={clinics}
                  onChangePage={fetchClinics}
                  onChangePerPage={(newCount) => fetchClinics(1, newCount)}
                  isLoading={isRequestsLoading}
                  columns={columnsClinics}
                  tableType={'clinics'}
                  actions={{
                    openDelete: openDelete,
                    openEdit: openEdit
                  }}
                />
              </div>
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreateClinic}
                  text="Добавить организацию"
                />
              </div>
            </>
          ) : (
            <>
              <NoContent text={'Нет клиник'} />
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreateClinic}
                  text="Добавить организацию"
                />
              </div>
            </>
          )}
        </div>
        <ModalWrapper
          title={'Вы уверены?'}
          isOpened={isOpenedDelete}
          onClose={() => closeDelete()}
        >
          <Delete
            onDelete={() => deleteClinic()}
            onClose={() => closeDelete()}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Карточка организации'}
          isOpened={isOpenedEdit}
          onClose={() => closeEdit()}
          style={{ width: '800px' }}
        >
          <Clinic
            handleSubmit={editClinic}
            editedObj={clinic}
            isLoading={isLoading}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Карточка организации'}
          isOpened={isOpenedCreateClinic}
          onClose={() => closeCreateClinic()}
          style={{ width: '800px' }}
        >
          <Clinic handleSubmit={createClinic} isLoading={isLoading} />
        </ModalWrapper>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  control: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  search: {
    display: 'flex',
    alignItems: 'baseline'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
})

export default withStyles(styles)(Clinics)
