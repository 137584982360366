import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _createPromocode,
  _deletePromocode,
  _editPromocode,
  _getPromocodes
} from 'api/http/promocodes'

export const SET_PROMOCODES = createRequestStatuses('SET_PROMOCOES')
export const getPromocodes = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_PROMOCODES,
    _getPromocodes,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const createPromocode = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_createPromocode, data, callbackSuccess, callbackError)

export const editPromocode = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_editPromocode, data, callbackSuccess, callbackError)

export const deletePromocode = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_deletePromocode, data, callbackSuccess, callbackError)
