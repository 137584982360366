import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import Menu from 'components/Menu'
import ControlBlockExport from 'components/ControlBlockExport'
import { columnsPharmacies } from 'data/columns'
import { palette } from 'styles/colors'
import NoContent from 'components/NoContent'
import TableWrapper from 'components/Table'
import Button from 'components/Button'
import Delete from 'components/Modals/Delete'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Pharmacy from 'components/Modals/Pharmacy'
import ButtonCreate from 'components/ButtonCreate'
import {
  getPharmaciesItems,
  getIsLoadingPharmacies,
  getPharmaciesPagination
} from 'redux/selectors/pharmacies'
import {
  createPharmacy as createPharmacyAction,
  editPharmacy as editPharmacyAction,
  deletePharmacy as deletePharmacyAction,
  uploadMedia,
  uploadFile,
  getPharmacies
} from 'redux/modules/pharmacies/actions'

const Pharmacies = ({ classes }) => {
  const dispatch = useDispatch()

  const pharmacies = useSelector(getPharmaciesItems)
  const isRequestsLoading = useSelector(getIsLoadingPharmacies)
  const pharmaciesPagination = useSelector(getPharmaciesPagination)

  const formik = useFormik({
    initialValues: {
      search: ''
    },
    onSubmit: (values, { setFieldError }) => {
      fetchPharmacies(1)
    }
  })

  const fetchPharmacies = (page = pharmaciesPagination.page, newCount) => {
    dispatch(
      getPharmacies(
        {},
        () => {},
        () => {},
        {
          page,
          count: newCount || pharmaciesPagination.perPage,
          phrase: formik.values.search
        }
      )
    )
  }

  useEffect(() => {
    fetchPharmacies(pharmaciesPagination.page)
  }, [])

  useEffect(() => {
    document.title = 'Аптеки'
  }, [])

  const [pharmacy, setPharmacies] = useState('')
  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = (editedPharmacy) => {
    setPharmacies(editedPharmacy)
    setIsOpenedEdit(true)
  }
  const closeEdit = () => {
    setIsOpenedEdit(false)
  }

  const [isOpenedDelete, setIsOpenedDelete] = useState(false)
  const [deletedPharmacy, setDeletedPharmacy] = useState(null)

  const openDelete = (pharmacy) => {
    setDeletedPharmacy(pharmacy)
    setIsOpenedDelete(true)
  }

  const closeDelete = () => {
    setDeletedPharmacy(null)
    setIsOpenedDelete(false)
  }

  const [isOpenedCreatePharmacy, setIsOpenedCreatePharmacy] = useState(false)

  const openCreatePharmacy = () => {
    setIsOpenedCreatePharmacy(true)
  }
  const closeCreatePharmacy = () => {
    setIsOpenedCreatePharmacy(false)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingFile, setIsLoadingFile] = useState(false)

  const createPharmacy = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      createPharmacyAction(
        {
          title: values.title,
          town: values.town,
          address: values.address,
          longitude: values.longitude,
          latitude: values.latitude,
          phone: values.phone
            .replace(/\s/g, '')
            .replace(/\+/g, '')
            .replace(/-/g, '')
        },
        (data) => {
          if (!values.image || typeof values.image === 'string') {
            fetchPharmacies(pharmaciesPagination.page)
            closeCreatePharmacy()
            setIsLoading(false)
          } else {
            editPharmacyMedia(data.id, values.image)
          }
        },
        () => {
          setIsLoading(false)
          setFieldError('title', 'Такое название уже существует')
        }
      )
    )
  }

  const editPharmacy = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      editPharmacyAction(
        {
          id: pharmacy.id,
          title: values.title,
          town: values.town,
          address: values.address,
          longitude: values.longitude,
          latitude: values.latitude,
          phone: values.phone
        },
        (data) => {
          if (!values.image || typeof values.image === 'string') {
            fetchPharmacies(pharmaciesPagination.page)
            closeEdit()
            setIsLoading(false)
          } else {
            editPharmacyMedia(data.id, values.image)
          }
        },
        () => {
          setFieldError('title', 'Такое название уже существует')
          setIsLoading(false)
        }
      )
    )
  }

  const editPharmacyMedia = (id, value) => {
    const formData = new FormData()
    formData.append('image', value)

    dispatch(
      uploadMedia(
        { id: id, image: formData },
        (data) => {
          fetchPharmacies(1)
          closeCreatePharmacy()
          closeEdit()
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const deletePharmacy = () => {
    dispatch(
      deletePharmacyAction(
        { id: deletedPharmacy.id },
        () => {
          fetchPharmacies(1)
          setIsOpenedDelete(false)
        },
        () => {}
      )
    )
  }

  const fileInputRef = useRef()

  const onClickExport = () => {
    fileInputRef.current.click()
  }

  const handleChange = (event) => {
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    setIsLoadingFile(true)

    dispatch(
      uploadFile(
        { file: formData },
        () => {
          fetchPharmacies(pharmaciesPagination.page)
          setIsLoadingFile(false)
        },
        () => {
          setIsLoadingFile(false)
        }
      )
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.wrapper}>
        <Menu />
        <div className={classes.table}>
          <div className={classes.control}>
            <ControlBlockExport
              formik={formik}
              searchText={'Найти аптеку'}
              exportText={'Загрузить EXL или CSV'}
              fieldName={'search'}
              onClickExport={onClickExport}
              handleChange={handleChange}
              fileInputRef={fileInputRef}
              isLoading={isLoadingFile}
            />
          </div>
          {pharmacies.length > 0 || isRequestsLoading ? (
            <>
              <div className={classes.tableWrapper}>
                <TableWrapper
                  page={pharmaciesPagination.page}
                  count={pharmaciesPagination.perPage}
                  amount={pharmaciesPagination.amount}
                  items={pharmacies}
                  onChangePage={fetchPharmacies}
                  onChangePerPage={(newCount) => fetchPharmacies(1, newCount)}
                  isLoading={isRequestsLoading}
                  columns={columnsPharmacies}
                  tableType={'pharmacies'}
                  actions={{
                    openDelete: openDelete,
                    openEdit: openEdit
                  }}
                />
              </div>
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreatePharmacy}
                  text="Добавить организацию"
                />
              </div>
            </>
          ) : (
            <>
              <NoContent text={'Нет аптек'} />
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreatePharmacy}
                  text="Добавить организацию"
                />
              </div>
            </>
          )}
        </div>
        <ModalWrapper
          title={'Вы уверены?'}
          isOpened={isOpenedDelete}
          onClose={() => closeDelete()}
        >
          <Delete
            onDelete={() => deletePharmacy()}
            onClose={() => closeDelete()}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Карточка организации'}
          isOpened={isOpenedCreatePharmacy}
          onClose={() => closeCreatePharmacy()}
          style={{ width: '800px' }}
        >
          <Pharmacy handleSubmit={createPharmacy} isLoading={isLoading} />
        </ModalWrapper>
        <ModalWrapper
          title={'Карточка организации'}
          isOpened={isOpenedEdit}
          onClose={() => closeEdit()}
          style={{ width: '800px' }}
        >
          <Pharmacy
            handleSubmit={editPharmacy}
            editedObj={pharmacy}
            isLoading={isLoading}
          />
        </ModalWrapper>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  control: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
})

export default withStyles(styles)(Pharmacies)
