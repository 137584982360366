import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import Button from 'components/Button'

const Notifications = ({ 
  classes, 
  notifications, 
  openDelete, 
  openTitle 
}) => {
  return notifications.map((notification) => (
    <TableRow key={notification.id} hover className={classes.table}>
      <TableCell
        style={{ width: '40px', borderColor: palette.black, height: '40px' }}
        align="center"
        onClick={() => openTitle({ ...notification })}
      >
        {notification.status === 'created' || notification.status === 'delivered'? (
          <div className={classes.circle}></div>
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell
        style={{ width: '80px', borderColor: palette.black }}
        align="center"
        onClick={() => openTitle({ ...notification })}
      >
        {notification.messageDate}
      </TableCell>
      <TableCell
        style={{ width: '80px', borderColor: palette.black }}
        align="center"
        onClick={() => openTitle({ ...notification })}
      >
        {notification.title}
      </TableCell>
      <TableCell
        style={{ width: '140px', borderColor: palette.black }}
        align="center"
        onClick={() => openTitle({ ...notification })}
      >
        {notification.body.length > 40 ? notification.body.substr(0, 40) + '...' : notification.body}
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({
  table: {
    cursor:'pointer'
  },
  circle: {
    background: palette.blue,
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    textAlign: 'center',
    margin: '0 auto',
  }
})

export default withStyles(styles)(Notifications)