export const getReceptionsItems = (state) => state.receptions.receptions
export const getReceptionsPagination = (state) => state.receptions.pagination
export const getIsLoadingReceptions = (state) => state.receptions.isLoading

export const getSecondOpinionReceptionsItems = (state) =>
  state.receptions.secondOpinionReceptions
export const getSecondOpinionReceptionsPagination = (state) =>
  state.receptions.secondOpinionPagination
export const getIsLoadingSecondOpinionReceptions = (state) =>
  state.receptions.secondOpinionLoading
