import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import editIcon from 'assets/edit.png'
import Switch from '@material-ui/core/Switch'

const Subscriptions = ({ 
  classes, 
  subscriptions,
  openEdit,
  handleSwitch
}) => {
  return subscriptions.map((subscription, index) => (
    <TableRow key={subscription.id}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black, color: !subscription.isActive ? palette.grey : '' }}
        align="center"
      >
        {subscription.isLimited ? 'Лимитный' : 'Безлимитный'}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black, color: !subscription.isActive ? palette.grey : '' }}
        align="center"
      >
        {subscription.duration}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black, color: !subscription.isActive ? palette.grey : '' }}
        align="center"
      >
        {subscription.receptionLimit ? subscription.receptionLimit : '-'}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black, color: !subscription.isActive ? palette.grey : '' }}
        align="center"
      >
        {subscription.price}
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black, color: !subscription.isActive ? palette.grey : '' }}
        align="center"
      >
        <IconButton
          onClick={() =>
            openEdit({ ...subscription })
          }
          disabled={!subscription.isActive}
        >
          <img src={editIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <Switch
          checked={subscription.isActive}
          onChange={(event) => handleSwitch(event, subscription, index)}
          value="isActive"
          color="primary"
        />
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({
  '@global': {
    '.MuiSwitch-colorPrimary.Mui-checked': {
      color: palette.blue
    },
    '.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      color: palette.blue,
      opacity: '.3'
    },
    '.MuiSwitch-track': {
      color: palette.black,
      opacity: '.08'
    }
  }
})

export default withStyles(styles)(Subscriptions)