import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputMask from 'react-input-mask'
import { Typography } from '@material-ui/core'
import { palette } from 'styles/colors'
import Input from 'components/Input'
import Button from 'components/Button'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ru from 'date-fns/locale/ru'

const Works = ({ 
  classes, 
  formik, 
  handleSubmit,
  editedObj,
  editTableValue,
  deletedEducation,
  type,
  isEdit,
  close
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (editedObj) {
      formik.setFieldValue('placeOfWork', editedObj.placeOfWork)
      formik.setFieldValue('position', editedObj.position)
      formik.setFieldValue('startDate', editedObj.startDate)
      formik.setFieldValue('endDate', editedObj.endDate)
    } else {
      formik.setFieldValue('placeOfWork', '')
      formik.setFieldValue('position', '')
      formik.setFieldValue('startDate', '')
      formik.setFieldValue('endDate', '')
    }

    if (editedObj && editedObj.startDate) {
      setStartDate(new Date(editedObj.startDate))
    }
    if (editedObj && editedObj.endDate) {
      setEndDate(new Date(editedObj.endDate))
    }
  }, [])

  let typeValue = ''

  const onChange = (date, type) => {
    if (type == 'startDate') {
      setStartDate(date)
    } else {
      setEndDate(date)
    }
    formik.setFieldValue(type, date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate())
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.field}>
        <Typography 
          className={classes.title} 
          variant="subtitle1"
          style={{ width: '27%' }}
        >
          Место работы:
        </Typography>
        <Input
          formik={formik}
          fieldName={'placeOfWork'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography 
          className={classes.title} 
          variant="subtitle1"
        >
          Должность:
        </Typography>
        <Input
          formik={formik}
          fieldName={'position'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1" style={{ width: '25%' }}>
          Дата начала:
        </Typography>
        <div style={{ width: '100%' }}>
          <DatePicker
            onChange={(date) => onChange(date, type = 'startDate')}
            maxDate={new Date()}
            selected={startDate}
            dateFormat="dd.MM.yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale={ru}
            withPortal
            customInput={
              <Input 
                formik={formik}
                fieldName={'startDate'}
                variant={'standard'}
                otherStyles={{ width: '100%' }}
                standard
              />
            }
          />
        </div>
      </div>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1" style={{ width: '32%' }}>
          Дата окончания:
        </Typography>
        <div style={{ width: '100%' }}>
          <DatePicker
            onChange={(date) => onChange(date, type = 'endDate')}
            maxDate={new Date()}
            selected={endDate}
            dateFormat="dd.MM.yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale={ru}
            withPortal
            customInput={
              <Input 
                formik={formik}
                fieldName={'endDate'}
                variant={'standard'}
                otherStyles={{ width: '100%' }}
                standard
              />
            }
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          text={'Сохранить'}
          otherStyles={{ 
            backgroundColor: palette.blueButton,
            color: palette.white,
            width: '250px',
            height: '40px',
            borderRadius: '10px',
            boxShadow: 'none',
          }}
          onClick={() => isEdit ? editTableValue(formik, deletedEducation, type, close) : handleSubmit(formik, close)}
        />
      </div>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '600px'
    },
    '.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header': {
      display: 'none'
    },
    '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected': {
      backgroundColor: `${palette.blueButton}`
    },
    '.react-datepicker__header': {
      padding: '2em 0 0 0'
    },
    '.react-datepicker__navigation--previous, .react-datepicker__navigation--next': {
      top: '1.5em'
    }
  }
})

export default withStyles(styles)(Works)