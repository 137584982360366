import React, { useState, useRef, useEffect } from 'react'
import { useFormik } from 'formik'
import Input from 'components/Input'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import * as Yup from 'yup'
import 'yup-phone'
import { palette } from 'styles/colors'
import { Typography } from '@material-ui/core'
import defaultLogo from 'assets/defaultLogo.png'
import { CardMedia, CardActions } from '@material-ui/core'
import InputMask from 'react-input-mask'
import Select from 'components/Select'
import { imageFromServer } from 'api/http/imageFromServer'

const specSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Название слишком короткое')
    .max(60, 'Название слишком длинное')
    .required('Это обязательное поле'),
  assignment: Yup.string()
    .min(2, 'Неверно введен тип')
    .max(20, 'Неверно введен тип')
    .required('Это обязательное поле'),
  address: Yup.string()
    .min(2, 'Неверно введен адрес')
    .max(40, 'Неверно введен адрес')
    .required('Это обязательное поле'),
  phone: Yup.string()
    .min(5, 'Неверно введен телефон')
    .max(40, 'Телефон слишком длинный')
    .required('Это обязательное поле'),
  latitude: Yup.string()
    .min(3, 'Слишком короткое значение')
    .max(13, 'Слишком длинное значение')
    .required('Это обязательное поле'),
  longitude: Yup.string()
    .min(3, 'Слишком короткое значение')
    .max(13, 'Слишком длинное значение')
    .required('Это обязательное поле'),
  town: Yup.string()
    .min(2, 'Название города слишком короткое')
    .max(30, 'Название города слишком длинное')
    .required('Это обязательное поле')
})

const Clinic = ({ classes, editedObj, handleSubmit, isLoading }) => {
  const hiddenFileInput = useRef(null)
  const [image, setImage] = useState('')

  const formik = useFormik({
    initialValues: {
      title: editedObj ? editedObj.title : '',
      assignment: editedObj ? editedObj.assignment : '',
      address: editedObj ? editedObj.address : '',
      phone: editedObj ? editedObj.phone : '',
      latitude: editedObj ? editedObj.latitude : '',
      longitude: editedObj ? editedObj.longitude : '',
      town: editedObj ? editedObj.town : '',
      image: editedObj ? editedObj.image : ''
    },
    validationSchema: specSchema,
    onSubmit: (...props) => handleSubmit(...props)
  })

  let type = ''

  useEffect(() => {
    document.title = editedObj
      ? `Медцентр ${editedObj.title}`
      : 'Создание медцентра'
    return () => (document.title = 'Клиники')
  }, [])

  const onChange = (data, type) => {
    formik.setFieldValue(type, data.target.value)
  }

  const fields = [
    {
      id: 'title',
      title: 'Название:'
    },
    {
      id: 'assignment',
      title: 'Тип:'
    },
    {
      id: 'address',
      title: 'Адрес:'
    },
    {
      id: 'town',
      title: 'Город:'
    },
    {
      id: 'phone',
      title: 'Номер телефона:'
    },
    {
      id: 'latitude',
      title: 'Широта:'
    },
    {
      id: 'longitude',
      title: 'Долгота:'
    }
  ]

  const selectValues = [
    {
      id: 0,
      name: 'Клиника',
      value: 'clinic'
    },
    {
      id: 1,
      name: 'Лаборатория',
      value: 'laboratory'
    }
  ]

  const uploadMedia = (event) => {
    hiddenFileInput.current.click()
  }

  const handleChange = (event) => {
    const imageUrl = URL.createObjectURL(event.target.files[0])
    setImage(imageUrl)
    formik.setFieldValue('image', event.target.files[0])
    // props.handleFile(fileUploaded)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
        <div className={classes.logo}>
          {typeof formik.values.image !== 'object' && formik.values.image ? (
            <CardMedia
              image={imageFromServer(formik.values.image)}
              component="img"
              className={classes.logoImg}
            />
          ) : image ? (
            <CardMedia
              image={image}
              component="img"
              className={classes.logoImg}
            />
          ) : (
            <CardMedia
              image={defaultLogo}
              component="img"
              className={classes.logoImg}
            />
          )}
          <CardActions>
            <input
              type="file"
              accept="image/*"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
              id="icon-button-photo"
              src={defaultLogo}
              multiple
            />
            <label htmlFor="icon-button-photo">
              <Button
                text={'Добавить логотип'}
                otherStyles={{
                  border: 'none',
                  backgroundColor: palette.white,
                  boxShadow: 'none',
                  color: palette.blue
                }}
                onClick={uploadMedia}
              />
            </label>
          </CardActions>
        </div>
        {fields.map((field) => (
          <div className={classes.field} key={field.id}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={field.id === 'phone' ? { width: '35%' } : {}}
            >
              {field.title}
            </Typography>
            {field.id !== 'phone' &&
            field.id !== 'assignment' &&
            field.id !== 'longitude' &&
            field.id !== 'latitude' ? (
              <Input
                formik={formik}
                fieldName={field.id}
                variant={'standard'}
                otherStyles={{ width: '100%' }}
                standard
              />
            ) : field.id === 'assignment' ? (
              <Select
                formik={formik}
                selectValues={selectValues}
                fieldName={'assignment'}
                otherStyles={{ width: '100%', margin: 0, height: '60px' }}
                variant={'standard'}
              />
            ) : field.id === 'latitude' || field.id === 'longitude' ? (
              <InputMask
                mask={'99.9999999999'}
                maskPlaceholder={''}
                value={formik.values[field.id].toString()}
                onChange={(data) => onChange(data, (type = field.id))}
              >
                <Input
                  formik={formik}
                  fieldName={field.id}
                  variant={'standard'}
                  otherStyles={{ width: '100%' }}
                  standard
                />
              </InputMask>
            ) : (
              <Input
                formik={formik}
                fieldName={'phone'}
                variant={'standard'}
                otherStyles={{ width: '100%' }}
                standard
              />
            )}
          </div>
        ))}
        <div className={classes.buttons}>
          <Button
            text={'Сохранить'}
            otherStyles={{
              width: '280px',
              height: '45px',
              backgroundColor: palette.blueButton,
              color: palette.white,
              margin: '2em 1em'
            }}
            type={'submit'}
            loader={isLoading}
          />
        </div>
      </form>
    </>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoImg: {
    width: '100px',
    height: '100px',
    borderRadius: '100px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  buttons: {
    textAlign: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    },
    'label + .MuiInput-formControl': {
      marginTop: 0
    },
    '.MuiFormHelperText-root': {
      margin: '-21px 0',
      marginTop: '3px'
    }
  }
})

export default withStyles(styles)(Clinic)
