import React, { useState } from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import MenuOptions from 'components/MenuOptions'
import deleteIcon from 'assets/delete.png'

const SecondOpinionReceptionsTable = ({
  classes,
  secondOpinionReceptions,
  openMedcard,
  openDelete
}) => {
  const formatDate = (date) => {
    let receptionDate =
      date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0]
    return receptionDate
  }

  const formatTime = (time) => {
    let receptionTime = time.split(':')[0] + ':' + time.split(':')[1]
    return receptionTime
  }

  return secondOpinionReceptions.map((reciption) => (
    <TableRow key={reciption.id}>
      <TableCell
        style={{ width: '40px', borderColor: palette.black, height: '40px' }}
        align="center"
      >
        {formatDate(reciption.receptionDate)}
      </TableCell>
      <TableCell
        style={{ width: '40px', borderColor: palette.black }}
        align="center"
      >
        {formatTime(reciption.receptionTime)}
      </TableCell>
      <TableCell
        style={{ width: '50px', borderColor: palette.black }}
        align="center"
      >
        {reciption.specialist.fullName}
      </TableCell>
      <TableCell
        style={{ width: '50px', borderColor: palette.black }}
        align="center"
      >
        {reciption.user.fullName}
      </TableCell>
      <TableCell
        style={{ width: '50px', borderColor: palette.black }}
        align="center"
      >
        {reciption.isCardFilled ? (
          <span style={{ color: palette.status.done }}>Заполнена</span>
        ) : (
          <span style={{ color: palette.status.failed }}>Не заполнена</span>
        )}
      </TableCell>
      <TableCell
        style={{ width: '50px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openDelete(reciption)
          }}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
      <TableCell
        style={{ width: '70px', borderColor: palette.black }}
        align="right"
      >
        <MenuOptions openMedcard={openMedcard} reciption={reciption} />
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(SecondOpinionReceptionsTable)
