import { createReducer } from '@reduxjs/toolkit'
import { SET_MODERATORS, SET_ACTIVE, SET_MODERATOR } from './actions'
import { SUCCESS, REQUEST, FAILURE } from '../../constants/statuses'

const initialState = {
  moderators: [],
  pagination: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  isLoading: false,
  isError: false
}

export const moderatorsReducer = createReducer(initialState, {
  [SET_MODERATORS[SUCCESS]]: (state, action) => {
    state.moderators = action.payload.data.moderators
    state.pagination.amount = action.payload.data.amount
    state.pagination.page = action.payload.paging.page
    state.pagination.perPage = action.payload.paging.count
    state.isLoading = false
  },

  [SET_MODERATORS[REQUEST]]: (state, action) => {
    state.isError = false
    state.isLoading = true
  },

  [SET_MODERATORS[FAILURE]]: (state, action) => {
    state.isLoading = false
    state.isError = true
  },

  SET_ACTIVE: (state, action) => {
    let newArray = state.moderators
    newArray = [...state.moderators, {
      fullName: action.payload.editModerator.fullName,
      id: action.payload.editModerator.id,
      isActive: !action.payload.editModerator.isActive,
      isActivePassword: action.payload.editModerator.isActivePassword,
      login: action.payload.editModerator.login,
      status: action.payload.editModerator.status
    }]
    newArray.splice(action.payload.index, 1)

    state.moderators = newArray
  },

  SET_MODERATOR: (state, action) => {
    let newArray = state.moderators
    newArray = [...state.moderators, {
      fullName: action.payload.editModerator.fullName,
      id: action.payload.editModerator.id,
      isActive: action.payload.editModerator.isActive,
      isActivePassword: action.payload.editModerator.isActivePassword,
      login: action.payload.editModerator.login,
      status: action.payload.editModerator.status === 'call' 
        ? 'moderator' 
        : 'call' 
    }]
    newArray.splice(action.payload.index, 1)

    state.moderators = newArray
  }
})