import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _getModerators,
  _createModerator,
  _editModerator,
  _deleteModerator
} from 'api/http/moderators'

export const SET_MODERATORS = createRequestStatuses('SET_MODERATORS')
export const getModerators = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_MODERATORS,
    _getModerators,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const createModerator = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _createModerator, 
    data, 
    callbackSuccess, 
    callbackError
  )

export const editModerator = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _editModerator, 
    data, 
    callbackSuccess, 
    callbackError
  )

export const deleteModerator = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _deleteModerator, 
    data, 
    callbackSuccess, 
    callbackError
  )

export const SET_ACTIVE = 'SET_ACTIVE' 
export const SET_MODERATOR = 'SET_MODERATOR'