import React, { useState, useRef, useEffect } from 'react'
import { useFormik } from 'formik'
import Input from 'components/Input'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import * as Yup from 'yup'
import 'yup-phone'
import { palette } from 'styles/colors'
import { Typography, InputAdornment, IconButton } from '@material-ui/core'
import load from 'assets/load.png'
import file from 'assets/file.png'
import deleteIcon from 'assets/delete.png'
import config from 'api/config'

const specSchema = Yup.object().shape({
  theme: Yup.string()
    .min(2, 'Тема слишком короткая')
    .max(40, 'Тема слишком длинная')
    .required('Это обязательное поле')
    .nullable('Это обязательное поле'),
  recomendation: Yup.string()
    .min(10, 'Рекомендация слишком короткая')
    .required('Это обязательное поле')
    .nullable('Это обязательное поле'),
  medicalTestsRecomendation: Yup.string()
    .min(10, 'Рекомендация слишком короткая')
    .nullable('Это обязательное поле')
})

const MedcardReception = ({
  classes,
  editedObj,
  handleSubmit,
  isLoading,
  secondOpinion = false
}) => {
  const formik = useFormik({
    initialValues: {
      theme: editedObj ? editedObj.theme : '',
      recomendation: editedObj ? editedObj.recomendation : '',
      medicalTests: editedObj ? editedObj.medicalTests : [],
      medicalTestsRecomendation: editedObj
        ? editedObj.medicalTestsRecomendation
        : ''
      // medicines: editedObj ? editedObj.medicines : []
    },
    validationSchema: specSchema,
    onSubmit: (...props) => handleSubmit(...props)
  })

  const fields = secondOpinion
    ? [
        {
          id: 'theme',
          title: 'Тема приема:'
        },
        {
          id: 'recomendation',
          title: 'Рекомендации:'
        }
        // {
        //   id: 'medicines',
        //   title: 'Лекарства:'
        // }
      ]
    : [
        {
          id: 'theme',
          title: 'Тема приема:'
        },
        {
          id: 'recomendation',
          title: 'Рекомендации:'
        },
        {
          id: 'medicalTests',
          title: 'Анализы:'
        },
        {
          id: 'medicalTestsRecomendation',
          title: 'Рекомендации к анализам:'
        }
        // {
        //   id: 'medicines',
        //   title: 'Лекарства:'
        // }
      ]

  const addMed = () => {
    // let meds = [
    //   ...formik.values.medicines,
    //   {
    //     title: '',
    //     amount: '',
    //     dosage: '',
    //     dosageUnit: '',
    //     period: '',
    //     periodUnit: ''
    //   }
    // ]
    // formik.setFieldValue('medicines', meds)
  }

  // const deleteMed = (index) => {
  //   let meds = [...formik.values.medicines]
  //   meds.splice(index, 1)
  //   formik.setFieldValue('medicines', meds)
  // }

  const addMedicalTest = () => {
    let medicalTests = [
      ...formik.values.medicalTests,
      {
        title: '',
        fileName: '',
        filePath: '',
        isNew: true
      }
    ]
    formik.setFieldValue('medicalTests', medicalTests)
  }

  const deleteAnalysys = (index) => {
    let analysys = [...formik.values.medicalTests]
    analysys.splice(index, 1)
    formik.setFieldValue('medicalTests', analysys)
  }

  const downloadTest = (analysys) => {
    let a = document.createElement('a')
    a.href = `${config.axios.baseImgUrl}/${analysys.filePath}`
    a.download = `${analysys.fileName}`
    a.target = '_blank'
    a.click()
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
        {secondOpinion && (
          <>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ width: '100%', textAlign: 'center' }}
            >
              Информация о приеме
            </Typography>
            <div className={classes.field}>
              <Typography
                className={classes.title}
                variant="subtitle1"
                style={{ width: '30%' }}
              >
                Файлы пацента:
              </Typography>
              <div className={classes.analyzes}>
                {editedObj.specialDocuments.length > 0 ? (
                  editedObj.specialDocuments.map((analysys, index) => (
                    <div className={classes.analysys} key={analysys.id}>
                      <div className={classes.analysysName}>
                        {analysys.filePath && (
                          <img
                            src={file}
                            style={{ width: '25px', marginRight: '10px' }}
                            alt="file"
                          />
                        )}
                        <div>{analysys.title}</div>
                      </div>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => downloadTest(analysys)}
                      >
                        <img src={load} style={{ width: '25px' }} alt="load" />
                      </IconButton>
                    </div>
                  ))
                ) : (
                  <div className={classes.noAnalyzes}>Файлы не добавлены</div>
                )}
              </div>
            </div>

            <div
              className={classes.field}
              style={{ alignItems: 'center', justifyContent: 'normal' }}
            >
              <Typography
                style={{ width: '30%' }}
                className={classes.title}
                variant="subtitle1"
              >
                Что беспокоит пациента:
              </Typography>
              <Input
                formik={formik}
                value={editedObj.specialComment}
                placeholder={'Комментарий'}
                variant={'standard'}
                otherStyles={{ width: '100%' }}
                standard
                multiline
              />
            </div>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ width: '100%', textAlign: 'center' }}
            >
              Медкарта приема
            </Typography>
          </>
        )}
        {fields.map((field) => (
          <div className={classes.field} key={field.id}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ width: '30%' }}
            >
              {field.title}
            </Typography>
            {field.id === 'medicalTests' ? (
              <div className={classes.analyzes}>
                {formik.values.medicalTests.length > 0 ? (
                  formik.values.medicalTests.map((analysys, index) => (
                    <div className={classes.analysys} key={analysys.id}>
                      <div className={classes.analysysName}>
                        {analysys.filePath && (
                          <img
                            src={file}
                            style={{ width: '25px', marginRight: '10px' }}
                            alt="file"
                          />
                        )}
                        {!analysys.isNew ? (
                          <div>{analysys.title}</div>
                        ) : (
                          <Input
                            formik={formik}
                            value={formik.values.medicalTests[index].title}
                            fieldName={`medicalTests[${index}].title`}
                            placeholder={'Название анализа'}
                            variant={'standard'}
                            otherStyles={{ width: '100%' }}
                            standard
                            required
                          />
                        )}
                      </div>
                      <div>
                        {analysys.filePath && (
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => downloadTest(analysys)}
                          >
                            <img
                              src={load}
                              style={{ width: '25px' }}
                              alt="load"
                            />
                          </IconButton>
                        )}
                        <IconButton
                          style={{ padding: 0, marginLeft: '10px' }}
                          onClick={() => deleteAnalysys(index)}
                        >
                          <img
                            src={deleteIcon}
                            style={{ width: '20px' }}
                            alt="delete"
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={classes.noAnalyzes}>Не добавлены анализы</div>
                )}
                <button
                  onClick={addMedicalTest}
                  type="button"
                  className={classes.addTest}
                >
                  + Добавить анализ
                </button>
              </div>
            ) : field.id === 'medicines' ? (
              <></>
            ) : // <div className={classes.meds}>
            //   {formik.values.medicines.map((med, index) => (
            //     <div key={index}>
            //       <Input
            //         formik={formik}
            //         value={formik.values.medicines[index].title}
            //         fieldName={`medicines[${index}].title`}
            //         placeholder={'Лекарство'}
            //         variant={'standard'}
            //         otherStyles={{ width: '100%' }}
            //         standard
            //         required
            //         endAdornment={
            //           <InputAdornment position="end">
            //             <IconButton
            //               onClick={() => deleteMed(index)}
            //               style={{ color: palette.blue }}
            //             >
            //               <img
            //                 src={deleteIcon}
            //                 style={{ width: '15px' }}
            //                 alt="delete"
            //               />
            //             </IconButton>
            //           </InputAdornment>
            //         }
            //       />
            //       <div className={classes.medsInfo}>
            //         <Input
            //           formik={formik}
            //           value={formik.values.medicines[index].amount}
            //           fieldName={`medicines[${index}].amount`}
            //           placeholder={'Количество'}
            //           variant={'standard'}
            //           otherStyles={{ width: '50%', marginRight: '15px' }}
            //           standard
            //           required
            //         />
            //         <Input
            //           formik={formik}
            //           value={formik.values.medicines[index].dosage}
            //           fieldName={`medicines[${index}].dosage`}
            //           placeholder={'Дозировка'}
            //           variant={'standard'}
            //           otherStyles={{ width: '50%' }}
            //           standard
            //           required
            //         />
            //         <Input
            //           formik={formik}
            //           value={formik.values.medicines[index].period}
            //           fieldName={`medicines[${index}].period`}
            //           placeholder={'Период'}
            //           variant={'standard'}
            //           otherStyles={{ width: '50%', marginLeft: '15px' }}
            //           standard
            //           required
            //         />
            //       </div>
            //     </div>
            //   ))}
            //   <button
            //     onClick={addMed}
            //     type="button"
            //     className={classes.addMed}
            //   >
            //     + Добавить лекарство
            //   </button>
            // </div>
            field.id === 'medicalTestsRecomendation' ||
              field.id === 'recomendation' ? (
              <Input
                formik={formik}
                fieldName={field.id}
                multiline
                variant={'standard'}
                otherStyles={{ width: '100%' }}
                standard
              />
            ) : (
              <Input
                formik={formik}
                fieldName={field.id}
                variant={'standard'}
                otherStyles={{ width: '100%' }}
                standard
              />
            )}
          </div>
        ))}
        <div className={classes.buttons}>
          <Button
            text={'Сохранить'}
            otherStyles={{
              width: '280px',
              height: '45px',
              backgroundColor: palette.blueButton,
              color: palette.white,
              margin: '2em 1em'
            }}
            type={'submit'}
            loader={isLoading}
          />
        </div>
      </form>
    </>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoImg: {
    width: '100px',
    height: '100px',
    borderRadius: '100px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '10px 20px 0 0'
  },
  subTitle: {
    margin: '10px 20px 0 0'
  },
  buttons: {
    textAlign: 'center'
  },
  analyzes: {
    width: '100%',
    marginTop: '10px'
  },
  analysys: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px'
  },
  analysysName: {
    display: 'flex',
    alignItems: 'center',
    width: '75%'
  },
  meds: {
    width: '100%'
  },
  medsInfo: {
    display: 'flex'
  },
  addMed: {
    background: 'none',
    border: 'none',
    color: '#22509DCC',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: '20px'
  },
  addTest: {
    background: 'none',
    border: 'none',
    color: '#22509DCC',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginBottom: '20px'
  },
  noAnalyzes: {
    marginTop: '5px'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    },
    '.MuiFormHelperText-root': {
      margin: '-21px 0',
      marginTop: '3px'
    },
    '.MuiInputBase-input::placeholder': {
      fontSize: '12px'
    }
  }
})

export default withStyles(styles)(MedcardReception)
