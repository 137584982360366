import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Calendar from 'arui-feather/calendar'
import Divider from '@material-ui/core/Divider'
import InputMask from 'react-input-mask'
import TableWrapper from 'components/Table'
import { columnsAppointments } from 'data/columns'
import { palette } from 'styles/colors'
import Input from 'components/Input'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import EditAppointment from 'components/Modals/EditAppointment'
import ModalWrapper from 'components/Modals/ModalWrapper'
import NoContent from 'components/NoContent'
import {
  createSchedule as createScheduleAction,
  createDutySchedule as createDutyScheduleAction,
  editSchedule as editScheduleAction,
  editDutySchedule as editDutyScheduleAction,
  getSchedules,
  getScheduleReceptions
} from 'redux/modules/specialists/actions'
import {
  getSchedulesItems,
  getScheduleReceptionsItems,
  getScheduleId,
  getAllSpecialistsItems
} from 'redux/selectors/specialists'
import * as yup from 'yup'
import { getUser } from 'redux/selectors/auth'

const Shedule = ({ classes, editedObj, setEditedObj }) => {
  const dispatch = useDispatch()

  console.log(editedObj)

  const allSpecialists = useSelector(getAllSpecialistsItems)

  const [date, setDate] = useState(Date.now())
  const [dateChange, setDateChange] = useState(new Date())
  const dateNow = new Date(date)
  const [time, setTime] = useState('')
  const [freeTimes, setFreeTimes] = useState([])
  const [today, setToday] = useState(new Date())
  const [month, setMonth] = useState(Date.now())

  useEffect(() => {
    const checkedDate = new Date()
    checkedDate.setMilliseconds(0)
    checkedDate.setSeconds(0)
    checkedDate.setMinutes(0)
    checkedDate.setHours(0)
    calendarChange(checkedDate)
  }, [])

  const [arrayDates, setArrayDates] = useState([])

  const [noContent, setNoContent] = useState('Нет записей')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const user = useSelector(getUser)

  const formatTime = (date) => {
    setTime(
      `${date.getFullYear()}-${
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)
      }-${date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()}`
    )
  }

  const formatDates = () => {
    let datesArray = []
    arrayDates.map((date) => {
      let dateFormat = new Date(date)
      datesArray.push(
        `${dateFormat.getFullYear()}-${
          dateFormat.getMonth() + 1
        }-${dateFormat.getDate()}`
      )
    })

    return datesArray
  }

  const schema = yup.object().shape({
    period: yup.number().integer().min(1, 'Некорректное время')
  })

  const formik = useFormik({
    initialValues: {
      date: '',
      start: '',
      end: '',
      period: '',
      // dutyDoctorMode: '',
      isActive: '',
      isSelectionMode: false
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (...params) => {
      if (receivedError !== 404) {
        editSchedule(...params, false)
      } else {
        createSchedule()
      }
    }
  })

  const [receivedError, setReceivedError] = useState()

  const saveAndGoNext = () => {
    if (receivedError !== 404) {
      editSchedule(formik.values, { setFieldError: formik.setFieldError }, true)
    } else {
      createSchedule(true)
    }
  }

  useEffect(() => {
    fetchShedules(1, dateChange.getFullYear(), dateChange.getMonth() + 1)
  }, [editedObj])

  const fetchShedules = (page = 1, year, month) => {
    dispatch(
      getSchedules(
        { id: editedObj.id, year: year, month: month },
        () => {
          setNoContent('Нет записей')
        },
        (error) => {
          if (error.status === 404) {
            setReceivedError(error.status)
            setNoContent('Нет расписания на месяц')
          }
        },
        {
          page,
          count: 100
        }
      )
    )
  }

  const shedules = useSelector(getSchedulesItems)
  const scheduleId = useSelector(getScheduleId)

  const fetchScheduleReceptions = (page = 1, scheduleDate) => {
    if (scheduleId !== 0) {
      setIsLoading(true)
      dispatch(
        getScheduleReceptions(
          { scheduleId: scheduleId, scheduleDate: scheduleDate },
          () => {
            setIsLoading(false)
          },
          () => {
            setIsLoading(false)
          },
          {
            page,
            count: 100
          }
        )
      )
    }
  }

  useEffect(() => {
    fetchShedules(1, dateNow.getFullYear(), dateNow.getMonth() + 1)
    formatTime(dateNow)
  }, [])

  useEffect(() => {
    setReceivedError(null)
    const scheduleDate =
      dateNow.getFullYear() +
      '-' +
      (dateNow.getMonth() + 1) +
      '-' +
      dateNow.getDate()
    if (!editedObj.isDuty) fetchScheduleReceptions(1, scheduleDate)
  }, [shedules])

  const scheduleReceptions = useSelector(getScheduleReceptionsItems)

  const changeWorkHours = (time) => {
    let scheduleValues = {}

    shedules.map((shedule) => {
      if (shedule.date == time && shedule.isActive !== false) {
        scheduleValues = shedule
        setFreeTimes(shedule.times)
      }
    })

    if (
      scheduleValues.isActive !== false &&
      Object.keys(scheduleValues).length > 0
    ) {
      formik.setFieldValue(
        'start',
        scheduleValues.start.split(':')[0] +
          ':' +
          scheduleValues.start.split(':')[1]
      )
      formik.setFieldValue(
        'end',
        scheduleValues.end.split(':')[0] +
          ':' +
          scheduleValues.end.split(':')[1]
      )
      if (!editedObj.isDuty)
        formik.setFieldValue('period', scheduleValues.period.toString())
      // formik.setFieldValue('dutyDoctorMode', scheduleValues.dutyDoctorMode)
      formik.setFieldValue('isActive', scheduleValues.isActive)
    } else {
      formik.setFieldValue('start', '')
      formik.setFieldValue('end', '')
      if (!editedObj.isDuty) formik.setFieldValue('period', '')
      // formik.setFieldValue('dutyDoctorMode', false)
      formik.setFieldValue('isActive', false)
    }
  }

  useEffect(() => {
    changeWorkHours(time)
  }, [shedules])

  const createSchedule = (goNext = false) => {
    let daysInMonth = new Date(
      dateChange.getFullYear(),
      dateChange.getMonth() + 1,
      0
    ).getDate()
    let dates = []

    for (let i = 0; i < daysInMonth; i++) {
      dates = [
        ...dates,
        {
          date:
            dateChange.getFullYear() +
            '-' +
            (dateChange.getMonth() + 1) +
            '-' +
            (i + 1),
          isActive: false
          // dutyDoctorMode: formik.values.dutyDoctorMode
        }
      ]
    }

    formatDates().map((date) => {
      dates.map((value, index) => {
        if (date == value.date) {
          dates.splice(index, 1)
          dates = [
            ...dates,
            {
              date: date,
              start: !formik.values.isActive
                ? null
                : formik.values.start + ':00',
              end: !formik.values.isActive ? null : formik.values.end + ':00',
              period: editedObj.isDuty ? null : Number(formik.values.period),
              // dutyDoctorMode: formik.values.dutyDoctorMode,
              isActive: formik.values.isActive
            }
          ]
        }
      })
    })

    dates.sort(function (prev, next) {
      let prevDate = new Date(
        prev.date.split('-')[0],
        prev.date.split('-')[1],
        prev.date.split('-')[2]
      )
      let nextDate = new Date(
        next.date.split('-')[0],
        next.date.split('-')[1],
        next.date.split('-')[2]
      )
      return prevDate < nextDate ? -1 : 1
    })

    let actionCreate = editedObj.isDuty
      ? createDutyScheduleAction
      : createScheduleAction

    dispatch(
      actionCreate(
        {
          specialistId: editedObj.id,
          year: dateChange.getFullYear(),
          month: dateChange.getMonth() + 1,
          scheduleData: { dates: [...dates] }
        },
        () => {
          if (formik.values.isSelectionMode) setArrayDates([])
          setReceivedError(null)
          if (goNext) {
            findNextSpecialist()
          } else {
            fetchShedules(
              1,
              dateChange.getFullYear(),
              dateChange.getMonth() + 1
            )
            closeEdit()
          }
        },
        (error) => {
          if (error.status === 406) {
            setError('Невозможно создать расписание на данную дату')
          }
        }
      )
    )
  }

  const findNextSpecialist = () => {
    const index = allSpecialists.findIndex((item) => item.id === editedObj.id)
    if (allSpecialists[index + 1]) {
      setEditedObj(allSpecialists[index + 1])
      formik.setValues({
        date: '',
        start: '',
        end: '',
        period: '',
        isActive: '',
        isSelectionMode: false
      })
    } else {
      setError('Больше нет специалистов')
    }
  }

  const editSchedule = (values, { setFieldError }, goNext) => {
    let actionEdit = editedObj.isDuty
      ? editDutyScheduleAction
      : editScheduleAction

    dispatch(
      actionEdit(
        {
          schedule_id: scheduleId,
          dates: formatDates(),
          start: !values.isActive ? null : values.start,
          end: !values.isActive ? null : values.end,
          period: !values.isActive || editedObj.isDuty ? null : values.period,
          // dutyDoctorMode: values.dutyDoctorMode,
          isActive: values.isActive
        },
        () => {
          setError('')
          if (goNext) {
            findNextSpecialist()
          } else {
            fetchShedules(1, dateNow.getFullYear(), dateNow.getMonth() + 1)
            closeEdit()
          }
          if (formik.values.isSelectionMode) setArrayDates([])
        },
        (error) => {
          if (error.status === 403) {
            setError('На этот день есть запись')
          }
          if (error.status === 422) {
            setError('Данные заполнены некорректно')
          }
        }
      )
    )
  }

  const [appointments, setAppointments] = useState('')
  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = (editedAppointments) => {
    setAppointments(editedAppointments)
    setIsOpenedEdit(true)
  }
  const closeEdit = (editedRecording) => {
    // setSpecialist(editedRecording)
    setIsOpenedEdit(false)
  }

  const calendarChange = (value) => {
    setDate(value)
    setDateChange(new Date(value))
    let newDate = new Date(value)
    let selectedDate = new Date(arrayDates[0])

    if (newDate.getMonth() + 1 !== selectedDate.getMonth() + 1) {
      setArrayDates([])
    }
    if (formik.values.isSelectionMode) {
      if (!arrayDates.includes(value)) {
        setArrayDates((prevValues) => [...prevValues, value])
      } else {
        setArrayDates(arrayDates.filter((date) => date !== value))
      }
    } else {
      setArrayDates([value])
    }

    fetchShedules(1, newDate.getFullYear(), newDate.getMonth() + 1)
    formatTime(newDate)
    changeWorkHours(newDate)
    setError('')
  }

  useEffect(() => {
    arrayDates.sort(function (prev, next) {
      return prev < next ? -1 : 1
    })
  }, [arrayDates])

  const changeMode = () => {
    formik.setFieldValue('isSelectionMode', !formik.values.isSelectionMode)
    setArrayDates([date])
  }

  let type = ''
  const onChange = (data, type) => {
    formik.setFieldValue(type, data.target.value)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.wrapper}>
        <div className={classes.block}>
          <div className={classes.calendarBlock}>
            <p className={classes.blockWorkTitle}>Выберите дату</p>
            {formik.values.isSelectionMode ? (
              <Calendar
                value={arrayDates[0]}
                onValueChange={(newDate) => calendarChange(newDate)}
                onMonthChange={(newMonth) => setMonth(newMonth)}
                eventDays={arrayDates}
                theme="alfa-light"
                className={classes.calendar}
                month={month}
                showToday={true}
              />
            ) : (
              <Calendar
                value={date}
                onValueChange={(newDate) => calendarChange(newDate)}
                onMonthChange={(newMonth) => setMonth(newMonth)}
                theme="alfa-light"
                className={classes.calendar}
                month={month}
                showToday={true}
              />
            )}
          </div>
          <div className={classes.checkboxDays}>
            <Checkbox
              label="Режим выбора нескольких дней"
              formik={formik}
              fieldName={'isSelectionMode'}
              onClick={() => changeMode()}
            />
          </div>
          <div className={classes.work}>
            <p className={classes.blockWorkTitle}>Рабочее время</p>
            <div className={classes.blockWork}>
              <p className={classes.blockWorkText}>С</p>
              <InputMask
                mask={'99:99'}
                maskPlaceholder={''}
                value={formik.values.start}
                onChange={(data) => onChange(data, (type = 'start'))}
              >
                <Input
                  formik={formik}
                  fieldName={'start'}
                  otherStyles={{ width: '130px' }}
                />
              </InputMask>
              <p className={classes.blockWorkText}>до</p>
              <InputMask
                mask={'99:99'}
                maskPlaceholder={''}
                value={formik.values.end}
                onChange={(data) => onChange(data, (type = 'end'))}
              >
                <Input
                  formik={formik}
                  fieldName={'end'}
                  otherStyles={{ width: '130px' }}
                />
              </InputMask>
            </div>
            {!editedObj.isDuty ? (
              <>
                <div className={classes.blockWork}>
                  <p className={classes.blockWorkText}>Окна записи:</p>
                  <Input
                    formik={formik}
                    fieldName={'period'}
                    otherStyles={{ width: '130px' }}
                    type="number"
                  />
                  <p className={classes.blockWorkText}>минут</p>
                </div>
                {/* <div className={classes.checkbox}>
                  <Checkbox
                    label="Режим дежурного врача"
                    formik={formik}
                    fieldName={'dutyDoctorMode'}
                  />
                </div> */}
              </>
            ) : (
              <></>
            )}
            <div className={classes.checkbox}>
              <Checkbox
                label="Рабочий день"
                formik={formik}
                fieldName={'isActive'}
              />
            </div>
          </div>
          <div className={classes.error}>{error}</div>
          <Button
            text={'Сохранить'}
            otherStyles={{
              width: '300px',
              height: '45px',
              backgroundColor: palette.blue,
              color: palette.white,
              borderRadius: '10px',
              margin: '0'
            }}
            type="submit"
            disabled={
              !arrayDates.length ||
              arrayDates.filter((date) => {
                const checkedDate = today
                checkedDate.setMilliseconds(0)
                checkedDate.setSeconds(0)
                checkedDate.setMinutes(0)
                checkedDate.setHours(0)

                return date < checkedDate
              }).length ||
              user.status === 'call'
            }
          />
          <Button
            text={'Сохранить и перейти к следующему'}
            handeAction={saveAndGoNext}
            otherStyles={{
              width: '300px',
              height: '45px',
              backgroundColor: palette.blue,
              color: palette.white,
              borderRadius: '10px',
              marginTop: '10px'
            }}
            disabled={
              !arrayDates.length ||
              arrayDates.filter((date) => {
                const checkedDate = today
                checkedDate.setMilliseconds(0)
                checkedDate.setSeconds(0)
                checkedDate.setMinutes(0)
                checkedDate.setHours(0)

                return date < checkedDate
              }).length ||
              user.status === 'call'
            }
          />
        </div>
        {!editedObj.isDuty ? (
          <>
            <Divider orientation={'vertical'} className={classes.divider} />
            <div className={classes.scheduleReceptions}>
              <p className={classes.blockWorkTitle}>Записи</p>
              {scheduleReceptions.length > 0 || isLoading ? (
                <div className={classes.table}>
                  <TableWrapper
                    items={scheduleReceptions}
                    isLoading={isLoading}
                    columns={columnsAppointments}
                    tableType={'appointments'}
                    actions={{
                      editAppointment: openEdit
                    }}
                  />
                </div>
              ) : (
                <div className={classes.noContent}>
                  <NoContent text={noContent} />
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <ModalWrapper
          title={'Запись'}
          isOpened={isOpenedEdit}
          onClose={() => closeEdit()}
          style={{ width: '800px' }}
        >
          <EditAppointment
            appointments={appointments}
            specialist={editedObj}
            freeTimes={freeTimes}
            close={closeEdit}
            fetch={fetchScheduleReceptions}
            fetchShedules={fetchShedules}
          />
        </ModalWrapper>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  calendarBlock: {
    backgroundColor: palette.calendar,
    border: `1px solid ${palette.calendar}`,
    borderRadius: '20px',
    width: '100%',
    paddingTop: '20px'
  },
  calendar: {
    borderRadius: '20px',
    width: '100%'
  },
  checkboxDays: {
    margin: '15px'
  },
  divider: {
    height: '43em',
    backgroundColor: palette.black,
    margin: '0 20px'
  },
  table: {
    width: '100%'
  },
  noContent: {
    width: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px'
  },
  work: {
    backgroundColor: palette.calendar,
    borderRadius: '20px',
    padding: '15px'
    // margin: '20px 0'
  },
  blockWork: {
    display: 'flex',
    alignItems: 'center'
  },
  scheduleReceptions: {
    width: '400px'
  },
  blockWorkTitle: {
    fontWeight: '600',
    textAlign: 'center'
  },
  blockWorkText: {
    margin: '0 5px'
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em 0'
  },
  error: {
    color: palette.red,
    marginBottom: '1em',
    fontSize: '14px'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '900px !important'
    },
    '.calendar__day_state_today': {
      border: `1px solid ${palette.blueButton} !important`
    },
    '.calendar__day_state_current': {
      background: `${palette.blueButton} !important`
    },
    '.calendar_theme_alfa-light .calendar__day_state_today': {
      boxShadow: 'none'
    },
    '.calendar_theme_alfa-light .calendar__name_month, .calendar_theme_alfa-light .calendar__name_year':
      {
        backgroundColor: palette.calendar
      },
    '.calendar__name_month, .calendar__name_year': {
      fontSize: '15px'
    },
    '.calendar__event': {
      visibility: 'hidden'
    },
    '.calendar__day_event': {
      background: `${palette.blueButton} !important`,
      color: palette.white
    }
  }
})

export default withStyles(styles)(Shedule)
