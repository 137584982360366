/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Menu from 'components/Menu'
import ControlBlockExport from 'components/ControlBlockExport'
import TableWrapper from 'components/Table'
import { columnsReciptions } from 'data/columns'
import NoContent from 'components/NoContent'
import MedcardReception from 'components/Modals/MedcardReception'
import DeclineReception from 'components/Modals/MedcardModals/DeclineReception'
import ModalWrapper from 'components/Modals/ModalWrapper'
import ReviewReception from 'components/Modals/MedcardModals/ReviewReception'
import ExportReceptions from 'components/ExportReceptions'
import {
  getReceptionsItems,
  getIsLoadingReceptions,
  getReceptionsPagination
} from 'redux/selectors/receptions'
import {
  editReception as editReceptionAction,
  getReceptions,
  declineReception
} from 'redux/modules/receptions/actions'
import Complaints from 'components/Modals/MedcardModals/Complaints'
import Refferal from 'components/Modals/MedcardModals/Refferal'
import AdminMark from 'components/Modals/MedcardModals/AdminMark'
import { useLocation } from 'react-router-dom'
import { getSpecialists } from 'redux/modules/specialists/actions'
import { getSpecialistsItems } from 'redux/selectors/specialists'
import EditDate from 'components/Modals/EditDate'
import { editScheduleReceptions as editScheduleReceptionsAction } from 'redux/modules/specialists/actions'

const Receptions = ({ classes }) => {
  const dispatch = useDispatch()

  const location = useLocation()

  const receptions = useSelector(getReceptionsItems)
  const isRequestsLoading = useSelector(getIsLoadingReceptions)
  const receptionsPagination = useSelector(getReceptionsPagination)

  const specialists = useSelector(getSpecialistsItems)

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenedMedcard, setIsOpenedMedcard] = useState(false)
  const [isOpenedReview, setIsOpenedReview] = useState(false)
  const [isOpenedExport, setIsOpenedExport] = useState(false)
  const [isOpenedComplaints, setIsOpenedComplaints] = useState(false)
  const [isOpenedRefferal, setIsOpenedRefferal] = useState(false)
  const [isOpenedChangeTime, setIsOpenedChangeTime] = useState(false)
  const [isOpenedMark, setIsOpenedMark] = useState(false)
  const [isOpenedDeclineReception, setIsOpenedDeclineReception] =
    useState(false)

  const formik = useFormik({
    initialValues: {
      search: '',
      specialistId: location.state?.id ?? '',
      isReferraled: '',
      success: '',
      today: '',
      receptionType: 'planned'
    },
    onSubmit: (values, { setFieldError }) => {
      fetchReceptions(1)
    }
  })

  const formikReception = useFormik({
    initialValues: {},
    onSubmit: (values, { setFieldError }) => changeTime(setFieldError)
  })

  useEffect(() => {
    fetchReceptions(1)
  }, [
    formik.values.specialistId,
    formik.values.isReferraled,
    formik.values.success,
    formik.values.today,
    formik.values.receptionType
  ])

  const fetchReceptions = (page = receptionsPagination.page, newCount) => {
    let params = {}

    if (formik.values.specialistId) {
      params.specialistId = formik.values.specialistId
    }
    if (formik.values.isReferraled) {
      params.isReferraled = formik.values.isReferraled
    }
    if (formik.values.success) {
      params.success = formik.values.success
    }
    if (formik.values.today) {
      params.today = 'yes'
    }
    if (formik.values.receptionType) {
      params.receptionType = formik.values.receptionType
    }

    dispatch(
      getReceptions(
        { ...params },
        () => {},
        () => {},
        {
          page,
          count: newCount || receptionsPagination.perPage,
          phrase: formik.values.search
        }
      )
    )
  }

  useEffect(() => {
    fetchReceptions(receptionsPagination.page)
    fetchSpecialists()
  }, [])

  useEffect(() => {
    document.title = 'Приемы'
  }, [])

  const fetchSpecialists = () => {
    dispatch(
      getSpecialists(
        {},
        () => {},
        () => {},
        { page: 1, count: 99999, phrase: '' }
      )
    )
  }

  const onClickExport = () => {
    setIsOpenedExport(true)
  }

  const closeExport = () => {
    setIsOpenedExport(false)
  }

  const [reception, setReception] = useState(null)

  const openMedcard = (values) => {
    setReception(values)
    setIsOpenedMedcard(true)
  }

  const openChangeTime = (values) => {
    setReception(values)
    setIsOpenedChangeTime(true)
  }

  const openDeclineReception = (values) => {
    setReception(values)
    setIsOpenedDeclineReception(true)
  }

  const openComplaintsReception = (values) => {
    setReception(values)
    setIsOpenedComplaints(true)
  }

  const openRefferalReception = (values) => {
    setReception(values)
    setIsOpenedRefferal(true)
  }

  const openAdminMark = (values) => {
    setReception(values)
    setIsOpenedMark(true)
  }

  const closeMedcard = () => {
    setIsOpenedMedcard(false)
  }

  const openReviews = (values) => {
    setReception(values)
    setIsOpenedReview(true)
  }

  const closeReview = () => {
    setIsOpenedReview(false)
  }

  const editMedcard = (values, { setFieldError }) => {
    // let medicines = [...values.medicines]
    // medicines = medicines.map((medicine) => {
    //   const dosage = medicine.dosage.split(' ')
    //   const period = medicine.period.split(' ')
    //   return {
    //     ...medicine,
    //     dosage: dosage[0],
    //     dosageUnit: dosage.length > 1 ? dosage[1].trim() : '',
    //     period: period[0],
    //     periodUnit: period.length > 1 ? period[1].trim() : '',
    //     amount: medicine.amount
    //   }
    // })
    setIsLoading(true)
    dispatch(
      editReceptionAction(
        {
          id: reception.id,
          status: reception.status,
          userId: reception.user.id,
          specialistId: reception.specialist.id,
          mode: 'medical_card',
          theme: values.theme,
          recomendation: values.recomendation,
          medicalTests: values.medicalTests,
          medicalTestsRecomendation: values.medicalTestsRecomendation,
          // medicines,
          appointmentDescription: reception.appointmentDescription
        },
        (data) => {
          fetchReceptions(receptionsPagination.page)
          closeMedcard()
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const editReview = (values) => {
    setIsLoading(true)
    dispatch(
      editReceptionAction(
        {
          id: reception.id,
          status: reception.status,
          userId: reception.user.id,
          specialistId: reception.specialist.id,
          mode: values ? 'review' : 'reception_dt',
          appointmentDescription:
            values && values.appointmentDescription
              ? values.appointmentDescription
              : null,
          appointmentMark:
            values && values.appointmentMark ? values.appointmentMark : null,
          medicalTestsRecomendation: reception.medicalTestsRecomendation,
          recomendation: reception.recomendation,
          theme: reception.theme
        },
        (data) => {
          closeReview()
          setIsLoading(false)
          fetchReceptions(receptionsPagination.page)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const declineReceptionAction = () => {
    setIsLoading(true)
    dispatch(
      declineReception(
        {
          receptionId: reception.id
        },
        (data) => {
          fetchReceptions(receptionsPagination.page)
          setIsOpenedDeclineReception(false)
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const changeTime = (setError) => {
    dispatch(
      editScheduleReceptionsAction(
        {
          mode: 'reception_dt',
          receptionId: reception.id,
          userId: reception.user.id,
          specialistId: reception.specialist.id,
          receptionDate: formikReception.values.receptionDate,
          receptionTime: formikReception.values.receptionTime
        },
        () => {
          fetchReceptions(receptionsPagination.page)
          setIsOpenedChangeTime(false)
        },
        () => {
          setError('receptionDate', 'Невозможно записать на эту дату')
        }
      )
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.wrapper}>
        <Menu />
        <div className={classes.table}>
          <div className={classes.control}>
            <ControlBlockExport
              formik={formik}
              searchText={'Найти'}
              exportText={'Экспорт в XLS'}
              fieldName={'search'}
              onClickExport={onClickExport}
              specialists={specialists}
              withActions
            />
          </div>

          {receptions.length > 0 || isRequestsLoading ? (
            <>
              <div className={classes.tableWrapper}>
                <TableWrapper
                  page={receptionsPagination.page}
                  count={receptionsPagination.perPage}
                  amount={receptionsPagination.amount}
                  items={receptions}
                  onChangePage={fetchReceptions}
                  onChangePerPage={(newCount) => fetchReceptions(1, newCount)}
                  isLoading={isRequestsLoading}
                  columns={columnsReciptions}
                  tableType={'reciptions'}
                  actions={{
                    openMedcard: openMedcard,
                    openReviews: openReviews,
                    openDeclineReception: openDeclineReception,
                    openComplaints: openComplaintsReception,
                    openRefferal: openRefferalReception,
                    openMark: openAdminMark,
                    openChangeTime: openChangeTime
                  }}
                />
              </div>
            </>
          ) : (
            <NoContent text={'Нет приемов'} />
          )}
        </div>
        <ModalWrapper
          title={'Медкарта приема'}
          isOpened={isOpenedMedcard}
          onClose={() => closeMedcard()}
          style={{ width: '800px' }}
        >
          <MedcardReception
            handleSubmit={editMedcard}
            editedObj={reception}
            isLoading={isLoading}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Отзыв'}
          isOpened={isOpenedReview}
          onClose={() => closeReview()}
          style={{ width: '800px' }}
        >
          <ReviewReception
            handleSubmit={editReview}
            editedObj={reception}
            isLoading={isLoading}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Жалоба'}
          isOpened={isOpenedComplaints}
          onClose={() => setIsOpenedComplaints(false)}
          style={{ width: '800px' }}
        >
          <Complaints
            onClose={(withFetch) => {
              if (withFetch) fetchReceptions(receptionsPagination.page)
              setIsOpenedComplaints(false)
            }}
            reception={reception}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Направление'}
          isOpened={isOpenedRefferal}
          onClose={() => setIsOpenedRefferal(false)}
          style={{ width: '800px' }}
        >
          <Refferal
            onClose={(withFetch) => {
              if (withFetch) fetchReceptions(receptionsPagination.page)
              setIsOpenedRefferal(false)
            }}
            reception={reception}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Перенести прием'}
          isOpened={isOpenedChangeTime}
          onClose={() => setIsOpenedChangeTime(false)}
          style={{ width: '800px' }}
        >
          <EditDate
            formik={formikReception}
            editedObj={''}
            specialist={reception ? reception.specialist : {}}
            editScheduleReceptions={changeTime}
            isPatient
            isChangeTime
          />
          {/* <ChangeTime
            reception={reception}
            onClose={(withFetch) => {
              if (withFetch) fetchReceptions(receptionsPagination.page)
              setIsOpenedChangeTime(false)
            }}
          /> */}
        </ModalWrapper>
        <ModalWrapper
          title={'Оценка'}
          isOpened={isOpenedMark}
          onClose={() => setIsOpenedMark(false)}
          style={{ width: '800px' }}
        >
          <AdminMark
            onClose={(withFetch) => {
              if (withFetch) fetchReceptions(receptionsPagination.page)
              setIsOpenedMark(false)
            }}
            reception={reception}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Отменить прием'}
          isOpened={isOpenedDeclineReception}
          onClose={() => setIsOpenedDeclineReception(false)}
          style={{ width: '800px' }}
        >
          <DeclineReception
            onClose={() => setIsOpenedDeclineReception(false)}
            onDelete={declineReceptionAction}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Экспорт в XLS'}
          isOpened={isOpenedExport}
          onClose={() => closeExport()}
          style={{ width: '800px' }}
        >
          <ExportReceptions />
        </ModalWrapper>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  control: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: '3em',
    right: '0'
  }
})

export default withStyles(styles)(Receptions)
