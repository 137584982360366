import axios from '../index'

export const _getSpecialists = (data, paging) =>
  axios.get(
    `/specialists/?page=${paging.page}&count=${paging.count}&phrase=${paging.phrase}`,
    data
  )

export const _getSpecialist = (data) =>
  axios.get(`/specialists/${data.id}`, data)

export const _createSpecialist = (data) => axios.post(`/specialists/`, data)

export const _editSpecialist = (data) =>
  axios.patch(`/specialists/${data.id}`, data)

export const _getSpecialities = (data) => axios.get('/specialities/', data)

export const _uploadMedia = (data) =>
  axios.post(`/specialists/${data.id}/photo`, data.photo, {
    'Content-Type': 'multipart/form-data'
  })

export const _deleteSpecialist = (data) =>
  axios.delete(`/specialists/${data.id}`, data)

export const _getSchedules = (data) =>
  axios.get(
    `/specialists/${data.id}/schedule?year=${data.year}&month=${data.month}`,
    data
  )

export const _createSchedule = (data) =>
  axios.post(`/specialists/${data.specialistId}/schedule`, data)

export const _createScheduleFullYear = (data) =>
  axios.post(`/specialists/${data.specialistId}/planned_schedule`, data)

export const _createScheduleFullYearDuty = (data) =>
  axios.post(`/specialists/${data.specialistId}/planned_duty_schedule`, data)

export const _createDutySchedule = (data) =>
  axios.post(`/specialists/${data.specialistId}/duty_schedule`, data)

export const _changeCarouselNumber = (data) =>
  axios.patch(`/specialists/${data.specialistId}/carousel`, data)

export const _editSchedule = (data) =>
  axios.patch(`/schedules/${data.schedule_id}`, data)

export const _editDutySchedule = (data) =>
  axios.patch(`/duty_schedule/${data.schedule_id}`, data)

export const _getScheduleReceptions = (data) =>
  axios.get(
    `/schedules/${data.scheduleId}?schedule_date=${data.scheduleDate}`,
    data
  )

export const _createScheduleReceptions = (data) =>
  axios.post(`/receptions`, data)

export const _editScheduleReceptions = (data) =>
  axios.patch(`/receptions/${data.receptionId}?change_mode=${data.mode}`, data)

export const _deleteScheduleReception = (data) =>
  axios.delete(`/receptions/${data.receptionId}`, data)

export const _deleteSpecialistSignature = (data) =>
  axios.delete(`/specialists/${data.id}/signature`, data)

export const _getRegions = (data) => axios.get(`/regions`, data)
