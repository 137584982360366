import React from 'react'
import { withStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'

const Notification = ({ classes, notification }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <Typography style={{ fontWeight: 'bold', marginRight: '1em' }} variant="subtitle1">Кому:</Typography>
        <Typography variant="subtitle1">{(!notification.user && !notification.specialist) ? 'Всем' :  notification.user ? notification.user.fullName : notification.specialist.fullName}</Typography>
      </div>
      <div className={classes.block}>
        <Typography style={{ fontWeight: 'bold', marginRight: '1em' }} variant="subtitle1">Дата:</Typography>
        <Typography variant="subtitle1">{notification.messageDate}</Typography>
      </div>
      <div className={classes.block}>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">Текст письма:</Typography>
      </div>
      <Typography variant="subtitle1">{notification.body}</Typography>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    width: '550px',
    margin: '2em 0'
  },

  block: {
    margin: '1em 0',
    display: 'flex'
  }
})

export default withStyles(styles)(Notification)