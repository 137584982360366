import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _getSpecialists,
  _getSpecialist,
  _getSpecialities,
  _createSpecialist,
  _editSpecialist,
  _deleteSpecialist,
  _uploadMedia,
  _getSchedules,
  _createSchedule,
  _createDutySchedule,
  _editSchedule,
  _editDutySchedule,
  _getScheduleReceptions,
  _createScheduleReceptions,
  _editScheduleReceptions,
  _deleteScheduleReception,
  _changeCarouselNumber,
  _deleteSpecialistSignature,
  _getRegions
} from 'api/http/specialists'

export const SET_SPECIALISTS = createRequestStatuses('SET_SPECIALISTS')
export const SET_ALL_SPECIALISTS = createRequestStatuses('SET_ALL_SPECIALISTS')
export const SET_SPECIALIST = createRequestStatuses('SET_SPECIALIST')
export const SET_SPECIALITIES = createRequestStatuses('SET_SPECIALITIES')
export const SET_SHEDULES = createRequestStatuses('SET_SHEDULES')
export const SET_SCHEDULE_RECEPTIONS = createRequestStatuses(
  'SET_SCHEDULE_RECEPTIONS'
)
export const SET_REGIONS = createRequestStatuses('SET_REGIONS')

export const getRegions = (data, callbackSuccess, callbackError) =>
  makeRequest(
    SET_REGIONS,
    _getRegions,
    data,
    callbackSuccess,
    callbackError
  )

export const getSpecialists = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_SPECIALISTS,
    _getSpecialists,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const getAllSpecialists = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_ALL_SPECIALISTS,
    _getSpecialists,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const getSpecialist = (data, callbackSuccess, callbackError) =>
  makeRequest(
    SET_SPECIALIST,
    _getSpecialist,
    data,
    callbackSuccess,
    callbackError
  )

export const getSpecialities = (data, callbackSuccess, callbackError) =>
  makeRequest(
    SET_SPECIALITIES,
    _getSpecialities,
    data,
    callbackSuccess,
    callbackError
  )

export const createSpecialist = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_createSpecialist, data, callbackSuccess, callbackError)

export const editSpecialist = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_editSpecialist, data, callbackSuccess, callbackError)

export const deleteSpecialist = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_deleteSpecialist, data, callbackSuccess, callbackError)

export const uploadMedia = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_uploadMedia, data, callbackSuccess, callbackError)

export const getSchedules = (data, callbackSuccess, callbackError) =>
  makeRequest(SET_SHEDULES, _getSchedules, data, callbackSuccess, callbackError)

export const createSchedule = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_createSchedule, data, callbackSuccess, callbackError)

export const createDutySchedule = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_createDutySchedule, data, callbackSuccess, callbackError)

export const changeCarouselNumber = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_changeCarouselNumber, data, callbackSuccess, callbackError)

export const editSchedule = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_editSchedule, data, callbackSuccess, callbackError)

export const editDutySchedule = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_editDutySchedule, data, callbackSuccess, callbackError)

export const getScheduleReceptions = (data, callbackSuccess, callbackError) =>
  makeRequest(
    SET_SCHEDULE_RECEPTIONS,
    _getScheduleReceptions,
    data,
    callbackSuccess,
    callbackError
  )

export const createScheduleReceptions = (
  data,
  callbackSuccess,
  callbackError
) =>
  makeSimpleRequest(
    _createScheduleReceptions,
    data,
    callbackSuccess,
    callbackError
  )

export const editScheduleReceptions = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _editScheduleReceptions,
    data,
    callbackSuccess,
    callbackError
  )

export const deleteScheduleReception = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(
    _deleteScheduleReception,
    data,
    callbackSuccess,
    callbackError
  )

export const deleteSpecialistSignature = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_deleteSpecialistSignature, data, callbackSuccess, callbackError)