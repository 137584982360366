import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputMask from 'react-input-mask'
import { Typography } from '@material-ui/core'
import { palette } from 'styles/colors'
import Checkbox from 'components/Checkbox'
import Input from 'components/Input'
import Select from 'components/Select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import { useSelector } from 'react-redux'
import { getClinicsItems } from 'redux/selectors/clinics'
import { getRegionItems } from 'redux/selectors/specialists'

const FormCard = ({
  classes,
  formik,
  isPatient,
  isSpecialist,
  isCreate,
  editedObj
}) => {
  let type = ''
  const [birthDate, setBirthDate] = useState('')

  useEffect(() => {
    if (editedObj) {
      formik.setFieldValue('birthDate', editedObj.birthDate)
    } else {
      formik.setFieldValue('birthDate', '')
    }

    if (editedObj && editedObj.birthDate) {
      setBirthDate(new Date(editedObj.birthDate))
    }
  }, [])

  const onChange = (data, type) => {
    formik.setFieldValue(type, data.target.value)
  }

  const onChangeDate = (date) => {
    setBirthDate(date)
    formik.setFieldValue(
      'birthDate',
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    )
  }

  const selectSex = [
    {
      id: 0,
      name: 'Женский',
      value: 'female'
    },
    {
      id: 1,
      name: 'Мужской',
      value: 'male'
    }
  ]

  const clinics = useSelector(getClinicsItems)

  const regions = useSelector(getRegionItems)
  
  return (
    <>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          ФИО:
        </Typography>
        <Input
          formik={formik}
          fieldName={'fullName'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography
          className={classes.title}
          variant="subtitle1"
          style={{ width: '32%' }}
        >
          Номер телефона:
        </Typography>
        <InputMask
          mask={'+\\9\\96 999 999-999'}
          maskPlaceholder={''}
          value={formik.values.phone}
          onChange={(data) => onChange(data, (type = 'phone'))}
        >
          <Input
            formik={formik}
            fieldName={'phone'}
            variant={'standard'}
            otherStyles={{ width: '100%' }}
            standard
          />
        </InputMask>
      </div>
      <div className={classes.field}>
        <Typography
          className={classes.title}
          variant="subtitle1"
          style={{ width: '30%' }}
        >
          Номер паспорта:
        </Typography>
        <InputMask
          mask={'*********'}
          maskPlaceholder={''}
          value={formik.values.passport}
          onChange={(data) => onChange(data, (type = 'passport'))}
        >
          <Input
            formik={formik}
            fieldName={'passport'}
            variant={'standard'}
            otherStyles={{ width: '100%' }}
            standard
          />
        </InputMask>
      </div>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          ИНН:
        </Typography>
        <InputMask
          mask={'99999999999999'}
          maskPlaceholder={''}
          value={formik.values.inn}
          onChange={(data) => onChange(data, (type = 'inn'))}
        >
          <Input
            formik={formik}
            fieldName={'inn'}
            variant={'standard'}
            otherStyles={{ width: '100%' }}
            standard
          />
        </InputMask>
      </div>
      {isPatient ? (
        <>
          <div className={classes.field}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ width: '28%' }}
            >
              Дата рождения:
            </Typography>
            <div style={{ width: '100%' }}>
              <DatePicker
                onChange={(date) => onChangeDate(date)}
                maxDate={new Date()}
                selected={birthDate}
                dateFormat="dd.MM.yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={ru}
                withPortal
                customInput={
                  <Input
                    formik={formik}
                    fieldName={'birthDate'}
                    variant={'standard'}
                    otherStyles={{ width: '100%' }}
                    standard
                  />
                }
              />
            </div>
          </div>
          {/* <div className={classes.field}>
            <Typography className={classes.title} variant="subtitle1" style={{ margin: '0 20px 5px 0' }}>
              Клиент:
            </Typography>
            <Select 
              formik={formik}
              selectValues={selectSource}
              fieldName={'source'}
              variant={'standard'}
              otherStyles={{ width: '100%', margin: 0 }}
            />
          </div> */}
        </>
      ) : (
        <div className={classes.field}>
          <Typography
            className={classes.title}
            variant="subtitle1"
            style={{ width: '33%' }}
          >
            Стоимость приема:
          </Typography>
          <InputMask
            mask={'99999'}
            maskPlaceholder={''}
            value={formik.values.price.toString()}
            onChange={(data) => onChange(data, (type = 'price'))}
          >
            <Input
              formik={formik}
              fieldName={'price'}
              variant={'standard'}
              otherStyles={{ width: '100%' }}
              standard
            />
          </InputMask>
        </div>
      )}
      {isSpecialist && (
        <>
          <div className={classes.field}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ width: '20%' }}
            >
              О враче (ru):
            </Typography>
            <Input
              formik={formik}
              fieldName={'descriptionRu'}
              variant={'standard'}
              otherStyles={{ width: '100%' }}
              standard
            />
          </div>
          <div className={classes.field}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ width: '20%' }}
            >
              О враче (kg):
            </Typography>
            <Input
              formik={formik}
              fieldName={'descriptionKg'}
              variant={'standard'}
              otherStyles={{ width: '100%' }}
              standard
            />
          </div>
          <div className={classes.field}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ margin: '0 20px 5px 0' }}
            >
              Регион:
            </Typography>
            <Select
              formik={formik}
              selectValues={regions}
              fieldName={'regionId'}
              variant={'standard'}
              selectorType={'region'}
              otherStyles={{ width: '100%', margin: 0 }}
            />
          </div>
          <div className={classes.field}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ margin: '0 20px 5px 0' }}
            >
              Пол:
            </Typography>
            <Select
              formik={formik}
              selectValues={selectSex}
              fieldName={'sex'}
              variant={'standard'}
              otherStyles={{ width: '100%', margin: 0 }}
            />
          </div>
          <div className={classes.field}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ margin: '0 20px 5px 0' }}
            >
              Клиника:
            </Typography>
            <Select
              formik={formik}
              selectValues={clinics}
              fieldName={'medicalInstitution'}
              selectorType="clinics"
              variant={'standard'}
              otherStyles={{ width: '100%', margin: 0 }}
            />
          </div>
          {/* <Typography
            className={classes.title}
            variant="subtitle1"
            style={{ margin: '25px 10px 0px 0', textAlign: 'center' }}
          >
            Клиника
          </Typography>
          <div className={classes.field}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ margin: '0 20px 5px 0' }}
            >
              Название:
            </Typography>
            <Input
              formik={formik}
              fieldName={'clinicTitle'}
              variant={'standard'}
              otherStyles={{ width: '100%' }}
              standard
            />
          </div>
          <div className={classes.field}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ margin: '0 20px 5px 0' }}
            >
              Адрес:
            </Typography>
            <Input
              formik={formik}
              fieldName={'clinicAddress'}
              variant={'standard'}
              otherStyles={{ width: '100%' }}
              standard
            />
          </div> */}
        </>
      )}

      {isSpecialist && (
        <>
          <div className={classes.checkbox}>
            {isCreate ? (
              <Checkbox
                label="Дежурный специалист"
                formik={formik}
                fieldName={'isDuty'}
              />
            ) : (
              <Checkbox
                label="Дежурный специалист"
                fieldName={'isDuty'}
                isChecked={formik.values.isDuty}
              />
            )}
          </div>
          <div className={classes.checkbox}>
            {isCreate ? (
              <Checkbox
                label="Коммуникация через чат"
                formik={formik}
                fieldName={'isSpecial'}
              />
            ) : (
              <Checkbox
                label="Коммуникация через чат"
                fieldName={'isSpecial'}
                isChecked={formik.values.isSpecial}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

const styles = () => ({
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  checkbox: {
    marginTop: '30px'
  },
  '@global': {
    'label + .MuiInput-formControl': {
      marginTop: 0
    },
    '.react-datepicker': {
      borderRadius: '20px'
    },
    '.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header':
      {
        display: 'none'
      },
    '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected':
      {
        backgroundColor: palette.blueButton
      },
    '.react-datepicker__header': {
      padding: '2em 0 0 0',
      borderRadius: '20px 20px 0 0',
      border: 'none',
      backgroundColor: palette.calendar
    },
    '.react-datepicker__month': {
      backgroundColor: palette.calendar,
      padding: '20px',
      margin: '0',
      borderRadius: '0 0 20px 20px'
    },
    '.react-datepicker__month-select, .react-datepicker__year-select': {
      border: 'none',
      backgroundColor: palette.calendar,
      textTransform: 'capitalize',
      fontSize: '14px',
      margin: '0 10px'
    },
    '.react-datepicker__day--outside-month': {
      color: palette.calendar,
      visibility: 'hidden'
    },
    '.react-datepicker__header:not(.react-datepicker__header--has-time-select)':
      {
        borderTopRightRadius: '20px'
      },
    '.react-datepicker__navigation--previous, .react-datepicker__navigation--next':
      {
        top: '1.5em'
      },
    '.react-datepicker__header__dropdown': {
      marginBottom: '20px'
    },
    '.react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover':
      {
        backgroundColor: palette.blueButton
      },
    '.react-datepicker__portal .react-datepicker__day': {
      lineHeight: '35px',
      borderRadius: '10px'
    },
    '.react-datepicker__day': {
      fontSize: '16px'
    },
    '.react-datepicker__day-name': {
      color: palette.daysDisables,
      fontSize: '14px',
      fontWeight: '200',
      fontFamily: 'Inter',
      textTransform: 'capitalize'
    }
  }
})

export default withStyles(styles)(FormCard)
