import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputMask from 'react-input-mask'
import { Typography } from '@material-ui/core'
import { palette } from 'styles/colors'
import Input from 'components/Input'
import Button from 'components/Button'

const Education = ({ 
  classes, 
  formik, 
  handleSubmit, 
  editedObj, 
  type, 
  deletedEducation, 
  editTableValue, 
  isEdit,
  close
}) => {
  let typeField = ''

  const onChange = (data, type) => {
    formik.setFieldValue(type, data.target.value)
  }

  useEffect(() => {
    if (editedObj) {
      formik.setFieldValue('typeEducation', editedObj.typeEducation)
      formik.setFieldValue('institution', editedObj.institution)
      formik.setFieldValue('specialityEducation', editedObj.speciality)
      formik.setFieldValue('yearGraduated', editedObj.yearGraduated.toString())
    } else {
      formik.setFieldValue('typeEducation', '')
      formik.setFieldValue('institution', '')
      formik.setFieldValue('specialityEducation', '')
      formik.setFieldValue('yearGraduated', '')
    }
  }, [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.field}>
        <Typography 
          className={classes.title} 
          variant="subtitle1"
          style={{ width: '40%' }}
        >
          Тип образования:
        </Typography>
        <Input
          formik={formik}
          fieldName={'typeEducation'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography 
          className={classes.title} 
          variant="subtitle1"
          style={{ width: '28%' }}
        >
          Вуз/клиника:
        </Typography>
        <Input
          formik={formik}
          fieldName={'institution'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography 
          className={classes.title} 
          variant="subtitle1"
          style={{ width: '32%' }}
        >
          Специальность:
        </Typography>
        <Input
          formik={formik}
          fieldName={'specialityEducation'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography
          className={classes.title}
          variant="subtitle1"
          style={{ width: '32%' }}
        >
          Год окончания:
        </Typography>
        <InputMask
          mask={'9999'}
          maskPlaceholder={''}
          value={formik.values.yearGraduated}
          onChange={(data) => onChange(data, (typeField = 'yearGraduated'))}
        >
          <Input
            formik={formik}
            fieldName={'yearGraduated'}
            variant={'standard'}
            otherStyles={{ width: '100%' }}
            standard
          />
        </InputMask>
      </div>
      <div className={classes.buttons}>
        <Button
          text={'Сохранить'}
          otherStyles={{ 
            backgroundColor: palette.blueButton,
            color: palette.white,
            width: '250px',
            height: '40px',
            borderRadius: '10px',
            boxShadow: 'none',
          }}
          onClick={() => isEdit ? editTableValue(formik, deletedEducation, type, close) : handleSubmit(formik, close)}
        />
      </div>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '600px'
    }
  }
})

export default withStyles(styles)(Education)