import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import CircularProgress from '@material-ui/core/CircularProgress'

const Btn = ({
  text,
  classes,
  active,
  loader,
  otherStyles,
  handeAction = () => {},
  ...props
}) => {
  return (
    <Button
      style={{ marginTop: '20px', ...otherStyles }}
      classes={{
        root: active ? classes.activeBtn : classes.subBtn,
        label: classes.label
      }}
      fullWidth
      variant="contained"
      onClick={() => handeAction()}
      {...props}
    >
      {!loader ? text : <CircularProgress size={20} color="white" />}
    </Button>
  )
}

const styles = () => ({
  activeBtn: {
    backgroundColor: palette.blue,
    color: palette.white,
    textTransform: 'capitalize'
  },
  subBtn: {
    backgroundColor: palette.greyAccent,
    color: palette.textAccent,
    textTransform: 'none'
  },
  label: {},
  '@global': {
    '.MuiCardActions-spacing > :not(:first-child)': {
      marginLeft: 0
    }
  }
})

export default withStyles(styles)(Btn)
