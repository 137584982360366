import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import { _getNotifications, _createNotification, _uploadBanner } from 'api/http/notifications'

export const SET_NOTIFICATIONS = createRequestStatuses('SET_NOTIFICATIONS')
export const getNotifications = (
  data,
  callbackSuccess,
  callbackError,
  paging
) =>
  makeRequest(
    SET_NOTIFICATIONS,
    _getNotifications,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const createNotification = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_createNotification, data, callbackSuccess, callbackError)

export const uploadBanner = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_uploadBanner, data, callbackSuccess, callbackError)
