/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import MenuOptions from 'components/MenuOptions'
import CallIcon from '@material-ui/icons/Call'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Button from 'components/Button'
import { _downloadReception } from 'api/http/receptions'

const Reciptions = ({
  classes,
  reciptions,
  openMedcard,
  openReviews,
  openComplaints,
  openRefferal,
  openMark,
  openDeclineReception,
  openChangeTime
}) => {
  const formatDate = (date) => {
    let receptionDate =
      date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0]
    return receptionDate
  }

  const formatTime = (time) => {
    let receptionTime = time.split(':')[0] + ':' + time.split(':')[1]
    return receptionTime
  }

  const handleDownload = async (reception) => {
    const { data } = await _downloadReception({ id: reception.id })
    let a = document.createElement('a')
    a.href = data.redirectTo
    a.download = `${reception.id}_reception_video.mp4`
    a.target = '_blank'
    a.click()
  }

  const medcardStatus = (reception) => {
    if (reception.isCardFilled) {
      return (
        <span style={{ color: palette.status.done }}>Медкарта заполнена</span>
      )
    }

    switch (reception.quality) {
      case 'duty':
        return 'Дежурный'
      case 'unfinished':
        return 'Предстоящий'
      case 'success':
        return (
          <span style={{ color: palette.status.done }}>
            Медкарта не заполнена
          </span>
        )
      case 'success_call':
        return (
          <span style={{ color: palette.status.done }}>
            Медкарта не заполнена
          </span>
        )
      case 'fail_call':
        return <span style={{ color: palette.status.failed }}>Не успешно</span>
      case 'fail':
        return <span style={{ color: palette.status.failed }}>Не успешно</span>

      default:
        return ''
    }
  }

  return reciptions.map((reciption) => {
    const [showPhone, setShowPhone] = useState(false)

    return (
      <TableRow key={reciption.id}>
        <TableCell
          style={{ width: '20px', borderColor: palette.black, height: '40px' }}
          align="center"
        >
          {formatDate(reciption.receptionDate)}
        </TableCell>
        <TableCell
          style={{ width: '20px', borderColor: palette.black }}
          align="center"
        >
          {formatTime(reciption.receptionTime)}
        </TableCell>
        <TableCell
          style={{ width: '50px', borderColor: palette.black }}
          align="center"
        >
          {reciption.specialist.fullName}
        </TableCell>
        <TableCell
          style={{ width: '30px', borderColor: palette.black }}
          align="center"
        >
          <IconButton
            aria-label="delete"
            onClick={() => setShowPhone(!showPhone)}
          >
            {showPhone ? <AccountCircle /> : <CallIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          style={{ width: '110px', borderColor: palette.black }}
          align="center"
        >
          {showPhone ? '+' + reciption.user.phone : reciption.user.fullName}
        </TableCell>
        <TableCell
          style={{ width: '20px', borderColor: palette.black }}
          align="center"
        >
          {reciption.isPayed ? (
            <span style={{ color: palette.status.done, fontSize: '1.5em' }}>
              ✔
            </span>
          ) : (
            <span style={{ color: palette.status.failed, fontSize: '0.9em' }}>
              ❌
            </span>
          )}
        </TableCell>
        <TableCell
          style={{ width: '40px', borderColor: palette.black }}
          align="center"
        >
          {reciption.isReferraled ? (
            'Направлен'
          ) : (
            <span style={{ color: palette.status.failed }}>
              {reciption.refferal && reciption.refferal.length > 12
                ? reciption.refferal.substring(0, 12).trim() + '...'
                : reciption.refferal}
            </span>
          )}
        </TableCell>

        <TableCell
          style={{ width: '40px', borderColor: palette.black }}
          align="center"
        >
          <span
            style={{
              color: !reciption.complaints
                ? palette.status.failed
                : palette.black
            }}
          >
            {reciption.complaints ?? 'Не заполнена'}
          </span>
        </TableCell>
        <TableCell
          style={{ width: '30px', borderColor: palette.black }}
          align="center"
        >
          {medcardStatus(reciption)}
        </TableCell>

        <TableCell
          style={{ width: '40px', borderColor: palette.black }}
          align="center"
        >
          {reciption.moderatorName ? reciption.moderatorName : 'Не указано'}
        </TableCell>
        <TableCell
          style={{ width: '30px', borderColor: palette.black }}
          align="center"
        >
          {reciption.adminMark}
        </TableCell>
        <TableCell
          style={{ width: '30px', borderColor: palette.black }}
          align="center"
        >
          {reciption.videoStatus === 'not_yet' ? 'Прием идет' : reciption.videoStatus === 'in_progress' ? 'В обработке' :     
            <Button
              text={'Скачать'}
              otherStyles={{ background: 'none', boxShadow: 'none', margin: '0' }}
              onClick={() => handleDownload(reciption)}
            />
        }
        </TableCell>
        <TableCell
          style={{ width: '70px', borderColor: palette.black }}
          align="center"
        >
          <MenuOptions
            openMedcard={openMedcard}
            openReviews={openReviews}
            openComplaints={openComplaints}
            openRefferal={openRefferal}
            openMark={openMark}
            openDeclineReception={openDeclineReception}
            reciption={reciption}
            openChangeTime={openChangeTime}
          />
        </TableCell>
      </TableRow>
    )
  })
}
const styles = () => ({})

export default withStyles(styles)(Reciptions)
