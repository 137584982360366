import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const NoContent = ({ classes, text = 'No data', withoutMargin }) => {
  return (
    <div
      className={classes.container}
      style={{ marginTop: withoutMargin ? 0 : '-10em' }}
    >
      <Typography variant="h6" color="textSecondary">
        {text}
      </Typography>
    </div>
  )
}

const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
})

export default withStyles(styles)(NoContent)
