import axios from '../index'

export const _getPatients = (data, paging) =>
  axios.get(
    `/users/?page=${paging.page}&count=${paging.count}&phrase=${paging.phrase}&source=${paging.source}`,
    data
  )

export const _createPatient = (data) => axios.post('/users/', data)

export const _editPatient = (data) => axios.patch(`/users/${data.id}`, data)

export const _deletePatient = (data) => axios.delete(`/users/${data.id}`, data)

export const _getPatentReceptions = (data, paging) =>
  axios.get(
    `/users/${data.id}/receptions?page=${paging.page}&count=${paging.count}`,
    data
  )

export const _getLoadResult = (data) => axios.get('/users/load_result', data)

export const _deleteLastLoad = (data) => axios.delete('/users/', data)

export const _uploadUsersTable = (data) =>
  axios.post('/users/download', data.file, {
    'Content-Type': 'multipart/form-data'
  })
