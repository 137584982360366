import Login from 'views/Login'
import NewPassword from 'views/NewPassword'
import Notifications from 'views/Notifications'
import Specialists from 'views/Specialists'
import Patients from 'views/Patients'
import Clinics from 'views/Clinics'
import Pharmacies from 'views/Pharmacies'
import Moderators from 'views/Moderators'
import Subscriptions from 'views/Subscriptions'
import CashRequests from 'views/CashRequests'
import Receptions from 'views/Receptions'
import SecondOpinion from 'views/SecondOpinion'
import PromoCodes from 'views/PromoCodes'

const routes = [
  {
    path: '/',
    component: Login,
    protected: false,
    admin: false
  },
  // {
  //   path: '/new-password',
  //   component: NewPassword,
  //   protected: true,
  //   admin: false
  // },
  {
    path: '/notifications',
    component: Notifications,
    protected: true,
    admin: false
  },
  {
    path: '/specialists',
    component: Specialists,
    protected: true,
    admin: false
  },
  {
    path: '/patients',
    component: Patients,
    protected: true,
    admin: false
  },
  {
    path: '/clinics',
    component: Clinics,
    protected: true,
    admin: false
  },
  {
    path: '/pharmacies',
    component: Pharmacies,
    protected: true,
    admin: false
  },
  {
    path: '/moderators',
    component: Moderators,
    protected: true,
    admin: false
  },
  // {
  //   path: '/subscriptions',
  //   component: Subscriptions,
  //   protected: true,
  //   admin: false
  // },
  // {
  //   path: '/cash-requests',
  //   component: CashRequests,
  //   protected: true,
  //   admin: false
  // },
  {
    path: '/receptions',
    component: Receptions,
    protected: true,
    admin: false
  },
  {
    path: '/second-opinion',
    component: SecondOpinion,
    protected: true,
    admin: false
  },
  {
    path: '/promocodes',
    component: PromoCodes,
    protected: true,
    admin: false
  }
]

export default routes
