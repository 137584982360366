import axios from '../index'

export const _getSubscriptions = (data, paging) =>
  axios.get(
    encodeURI(
      `/tariffs/?page=${paging.page}&count=${paging.count}`
    ),
    data
  )

export const _createSubscription = (data) =>
  axios.post('/tariffs/', data)

export const _editSubscription = (data) =>
  axios.patch(`/tariffs/${data.id}`, data)