import { createReducer } from '@reduxjs/toolkit'
import { SET_CLINICS } from './actions'
import { SUCCESS, REQUEST, FAILURE } from '../../constants/statuses'

const initialState = {
  clinics: [
  ],
  pagination: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  isLoading: false,
  isError: false
}

export const clinicsReducer = createReducer(initialState, {
  [SET_CLINICS[SUCCESS]]: (state, action) => {
    state.clinics = action.payload.data.medicalInstitutions
    state.pagination.amount = action.payload.data.amount
    state.pagination.page = action.payload.paging.page
    state.pagination.perPage = action.payload.paging.count
    state.isLoading = false
  },

  [SET_CLINICS[REQUEST]]: (state, action) => {
    state.isError = false
    state.isLoading = true
  },

  [SET_CLINICS[FAILURE]]: (state, action) => {
    state.isLoading = false
    state.isError = true
  }
})