export const palette = {
  blue: '#012869',
  blueLight: '#009CCD',
  blueButton: '#0066FF',
  backdrop: '#00152A',
  black: '#000000',
  white: '#ffffff',
  grey: '#C7C5C6',
  greyAccent: '#F1F2F6',
  daysDisables: '#A5A5A5',
  textAccent: '#585858',
  calendar: '#f3f4f5',
  red: '#C40505',
  green: '#0077057A',
  greyBack: '#21212114',
  status: {
    process: '#009CCD',
    done: '#24a654',
    failed: '#bb2124',
    sended: '#0e4378'
  },
  panelBackground: '#FAFAFA',
  panelBorder: '#D9D9D9'
}
