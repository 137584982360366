import React from 'react'
import { withStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { palette } from 'styles/colors'
import Input from 'components/Input'
import Button from 'components/Button'
import { useDispatch } from 'react-redux'
import { createNotification } from 'redux/modules/notifications/actions'

const createSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Заголовок слишком короткий')
    .max(100, 'Заголовок слишком длинный')
    .required('Заголок обязательное поле'),
  body: Yup.string()
    .min(2, 'Текст слишком короткий')
    .max(500, 'Текст слишком длинный')
})

const CreateNotification = ({ classes, onClose = () => {} }) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      body: ''
    },
    enableReinitialize: true,
    validationSchema: createSchema,
    onSubmit: (values, { setFieldError }) =>
      createNotificationAction(values, setFieldError)
  })

  const dispatch = useDispatch()

  const createNotificationAction = (values, setFieldError) => {
    dispatch(
      createNotification(
        values,
        () => {
          onClose()
        },
        () => {
          setFieldError('title', 'Ошибка при создании уведомления')
        }
      )
    )
  }

  return (
    <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          Заголовок уведомления:
        </Typography>
        <Input
          formik={formik}
          fieldName={'title'}
          variant={'standard'}
          otherStyles={{ width: '60%' }}
          type={'text'}
          standard
        />
      </div>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          Текст уведомления:
        </Typography>
        <Input
          formik={formik}
          fieldName={'body'}
          otherStyles={{ width: '60%' }}
          variant={'standard'}
          type={'text'}
          standard
        />
      </div>
      <div className={classes.buttons}>
        <Button
          text={'Создать уведомление'}
          otherStyles={{
            width: '220px',
            height: '40px',
            backgroundColor: palette.white,
            border: `1px solid ${palette.blue}`,
            borderRadius: '10px',
            color: palette.blue,
            marginRight: '1em',
            marginLeft: '1em',
            marginBottom: '1em'
          }}
          type={'submit'}
        />
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  buttons: {
    textAlign: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    }
  }
})

export default withStyles(styles)(CreateNotification)
