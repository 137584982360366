import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import editIcon from 'assets/edit.png'
import deleteIcon from 'assets/delete.png'

const Promocodes = ({ classes, promocodes, openDelete, openEdit }) => {
  return promocodes.map((promocode, index) => (
    <TableRow key={promocode.id}>
      <>
        <TableCell
          style={{ width: '100px', borderColor: palette.black }}
          align="center"
        >
          {promocode.code}
        </TableCell>
        <TableCell
          style={{ width: '100px', borderColor: palette.black }}
          align="center"
        >
          {promocode.title}
        </TableCell>
        <TableCell
          style={{ width: '100px', borderColor: palette.black }}
          align="center"
        >
          {promocode.isActive ? 'Активен' : 'Не активен'}
        </TableCell>
        <TableCell
          style={{ width: '20px', borderColor: palette.black }}
          align="center"
        >
          <IconButton onClick={() => openEdit({ ...promocode })}>
            <img src={editIcon} alt="edit" width="15" />
          </IconButton>
        </TableCell>
        <TableCell
          style={{ width: '20px', borderColor: palette.black }}
          align="center"
        >
          <IconButton
            onClick={() => {
              openDelete(promocode)
            }}
          >
            <img src={deleteIcon} alt="delete" width="15" />
          </IconButton>
        </TableCell>
      </>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(Promocodes)
