import React from 'react'
import {
  Dialog,
  Typography,
  DialogContent,
  IconButton
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'

const ModalWrapper = ({ classes, title, isOpened, onClose, ...props }) => {
  const DialogTitle = () => {
    return (
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <div style={{ width: '1.5em' }}></div>
        <Typography style={{ fontWeight: 'bold' }} variant="h6">
          {title}
        </Typography>
        {onClose && (
          <IconButton
            style={{ width: '1.5em', height: '1.5em' }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        )}
      </MuiDialogTitle>
    )
  }

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle />
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  )
}

const styles = () => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export default withStyles(styles)(ModalWrapper)
