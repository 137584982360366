import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _getReceptions,
  _editReception,
  _declineReception,
  _getSecondOpinionReceptions,
  _editSecondOpinionReception,
  _editReceptionFields,
  _deleteSecondOpinion
} from 'api/http/receptions'

export const SET_RECEPTIONS = createRequestStatuses('SET_RECEPTIONS')
export const getReceptions = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_RECEPTIONS,
    _getReceptions,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const editReception = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_editReception, data, callbackSuccess, callbackError)

export const editReceptionFields = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_editReceptionFields, data, callbackSuccess, callbackError)

export const declineReception = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_declineReception, data, callbackSuccess, callbackError)

export const SET_SECOND_OPINION_RECEPTIONS = createRequestStatuses(
  'SET_SECOND_OPINION_RECEPTIONS'
)

export const getSecondOpinionReceptions = (
  data,
  callbackSuccess,
  callbackError,
  paging
) =>
  makeRequest(
    SET_SECOND_OPINION_RECEPTIONS,
    _getSecondOpinionReceptions,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const editSecondOpinionReception = (
  data,
  callbackSuccess,
  callbackError
) =>
  makeSimpleRequest(
    _editSecondOpinionReception,
    data,
    callbackSuccess,
    callbackError
  )

export const deleteSecondOpinion = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_deleteSecondOpinion, data, callbackSuccess, callbackError)
