import React, { useState } from 'react'
import { login as signIn } from 'redux/modules/auth/actions'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Input from 'components/Input'
import Btn from 'components/Button'
import logo from 'assets/logo.png'
import { useHistory } from 'react-router-dom'
import { palette } from 'styles/colors'

const signInSchema = Yup.object().shape({
  login: Yup.string()
    .min(2, 'Login is too Short!')
    .max(50, 'Login is too Long!')
    .required('Login field is required'),
  password: Yup.string()
    .min(2, 'Password is too Short!')
    .max(30, 'Password is too Long!')
    .required('Password field is required')
})

// TODO Обработка ошибок при логине

const Login = ({ classes }) => {
  const router = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      login: '',
      password: ''
    },
    validationSchema: signInSchema,
    onSubmit: (values, { setFieldError }) => {
      dispatch(
        signIn(
          values,
          (data) => {
            localStorage.JWT_KEY = JSON.stringify({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken
            })
            router.push('/recordings')
          },
          (error) => {
            if (error.status === 404) setFieldError('login', 'Login not found')
            if (error.status === 401)
              setFieldError('password', 'Incorrect password')
          }
        )
      )
    }
  })

  return (
    <div className={classes.wrapper}>
      <img src={logo} alt="logo" className={classes.logo} />
      <div className={classes.text}>
          Ваш пароль был сброшен администратором. 
          Пожалуйста создайте новый пароль.
        </div>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Input
          formik={formik}
          fieldName={'password'}
          label={'Пароль'}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                style={{ color: palette.blue}}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Btn 
          active 
          type="submit" 
          fullWidth 
          text={'Войти'} 
          otherStyles={{ 
            'height': '52px',
            'backgroundColor': palette.blueButton
          }} 
        />
      </form>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  form: {
    width: '25%'
  },
  logo: {
    width: '30%',
    marginBottom: '3em'
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '20px',
    lineHeight: '140%',
    width: '30%',
    textAlign: 'center', 
    marginBottom: '1.5em'
  }
})

export default withStyles(styles)(Login)