import { createReducer } from '@reduxjs/toolkit'
import {
  SET_LOAD_RESULT,
  SET_PATIENTS,
  SET_PATIENT_RECEPTIONS
} from './actions'
import { SUCCESS, REQUEST, FAILURE } from '../../constants/statuses'

const initialState = {
  patients: [],
  receptions: [],
  pagination: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  paginationReception: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  isLoading: false,
  isError: false,
  loadResult: null
}

export const patientsReducer = createReducer(initialState, {
  [SET_PATIENTS[SUCCESS]]: (state, action) => {
    state.patients = action.payload.data.users
    state.pagination.amount = action.payload.data.amount
    state.pagination.page = action.payload.paging.page
    state.pagination.perPage = action.payload.paging.count
    state.isLoading = false
  },

  [SET_PATIENTS[REQUEST]]: (state, action) => {
    state.isError = false
    state.isLoading = true
  },

  [SET_PATIENTS[FAILURE]]: (state, action) => {
    state.isLoading = false
    state.isError = true
  },

  [SET_PATIENT_RECEPTIONS[SUCCESS]]: (state, action) => {
    state.receptions = action.payload.data.receptions
    state.paginationReception.amount = action.payload.data.amount
    state.paginationReception.page = action.payload.paging.page
    state.paginationReception.perPage = action.payload.paging.count
  },

  [SET_LOAD_RESULT[SUCCESS]]: (state, action) => {
    state.loadResult = action.payload.data
  }
})
