import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Menu from 'components/Menu'
import TableWrapper from 'components/Table'
import { columnsSecondOpinionReciptions } from 'data/columns'
import NoContent from 'components/NoContent'
import ModalWrapper from 'components/Modals/ModalWrapper'
import {
  getIsLoadingSecondOpinionReceptions,
  getSecondOpinionReceptionsItems,
  getSecondOpinionReceptionsPagination
} from 'redux/selectors/receptions'
import {
  deleteSecondOpinion,
  getSecondOpinionReceptions
} from 'redux/modules/receptions/actions'
import { editSecondOpinionReception as editReceptionAction } from 'redux/modules/receptions/actions'
import MedcardReception from 'components/Modals/MedcardReception'
import Delete from 'components/Modals/Delete'

const SecondOpinion = ({ classes }) => {
  const dispatch = useDispatch()

  const receptions = useSelector(getSecondOpinionReceptionsItems)
  const isRequestsLoading = useSelector(getIsLoadingSecondOpinionReceptions)
  const receptionsPagination = useSelector(getSecondOpinionReceptionsPagination)

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenedMedcard, setIsOpenedMedcard] = useState(false)

  const [isOpenedDelete, setOpenedDelete] = useState(false)

  const fetchReceptions = (page = receptionsPagination.page, newCount) => {
    dispatch(
      getSecondOpinionReceptions(
        {},
        () => {},
        () => {},
        {
          page,
          count: newCount || receptionsPagination.perPage
        }
      )
    )
  }

  useEffect(() => {
    fetchReceptions(receptionsPagination.page)
  }, [])

  useEffect(() => {
    document.title = 'Второе мнение'
  }, [])

  const [reception, setReception] = useState(null)

  const openMedcard = (values) => {
    setReception(values)
    setIsOpenedMedcard(true)
  }

  const closeMedcard = () => {
    setIsOpenedMedcard(false)
  }

  const editMedcard = (values, { setFieldError }) => {
    // let medicines = [...values.medicines]
    // medicines = medicines.map((medicine) => {
    //   const dosage = medicine.dosage.split(' ')
    //   const period = medicine.period.split(' ')
    //   return {
    //     ...medicine,
    //     dosage: dosage[0],
    //     dosageUnit: dosage.length > 1 ? dosage[1].trim() : '',
    //     period: period[0],
    //     periodUnit: period.length > 1 ? period[1].trim() : '',
    //     amount: medicine.amount
    //   }
    // })
    setIsLoading(true)
    dispatch(
      editReceptionAction(
        {
          id: reception.id,
          status: 'finished',
          userId: reception.user.id,
          specialistId: reception.specialist.id,
          mode: 'medical_card',
          theme: values.theme,
          recomendation: values.recomendation
          // medicines
        },
        (data) => {
          fetchReceptions(receptionsPagination.page)
          closeMedcard()
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const openDelete = (reception) => {
    setReception(reception)
    setOpenedDelete(true)
  }

  const closeDelete = () => {
    setOpenedDelete(false)
  }

  const deleteReception = () => {
    console.log(reception)

    dispatch(
      deleteSecondOpinion(
        { id: reception.id },
        () => {
          fetchReceptions(1)
          setOpenedDelete(false)
        },
        () => {}
      )
    )
  }

  return (
    <div className={classes.wrapper}>
      <Menu />
      <div className={classes.table}>
        {receptions.length > 0 || isRequestsLoading ? (
          <>
            <div className={classes.tableWrapper}>
              <TableWrapper
                page={receptionsPagination.page}
                count={receptionsPagination.perPage}
                amount={receptionsPagination.amount}
                items={receptions}
                onChangePage={fetchReceptions}
                onChangePerPage={(newCount) => fetchReceptions(1, newCount)}
                isLoading={isRequestsLoading}
                columns={columnsSecondOpinionReciptions}
                tableType={'secondOpinionReceptions'}
                actions={{
                  openMedcard: openMedcard,
                  openDelete: openDelete
                }}
              />
            </div>
          </>
        ) : (
          <NoContent text={'Нет приемов'} />
        )}
      </div>
      <ModalWrapper
        title={'Текстовый прием'}
        isOpened={isOpenedMedcard}
        onClose={() => closeMedcard()}
        style={{ width: '800px' }}
      >
        <MedcardReception
          handleSubmit={editMedcard}
          editedObj={reception}
          isLoading={isLoading}
          secondOpinion
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Вы уверены?'}
        isOpened={isOpenedDelete}
        onClose={() => closeDelete()}
      >
        <Delete
          onDelete={() => deleteReception()}
          onClose={() => closeDelete()}
        />
      </ModalWrapper>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  control: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: '3em',
    right: '0'
  }
})

export default withStyles(styles)(SecondOpinion)
