import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { palette } from 'styles/colors'
import * as Yup from 'yup'
import Button from 'components/Button'
import Select from 'components/Select'

const specSchema = Yup.object().shape({
  status: Yup.string().required('Это обязательное поле')
})

const ChangeStatus = ({ classes, handleSubmit, editedObj }) => {
  const formik = useFormik({
    initialValues: {
      id: editedObj.id,
      status: editedObj.status
    },
    validationSchema: specSchema,
    onSubmit: (...props) => handleSubmit(...props)
  })

  const selectValues = [
    {
      id: 0,
      name: 'Создан',
      value: 'created'
    },
    {
      id: 1,
      name: 'Принят',
      value: 'approved'
    },
    {
      id: 2,
      name: 'Отклонен',
      value: 'rejected'
    }
  ]

  return (
    <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          Статус:
        </Typography>
        <Select 
          formik={formik}
          selectValues={selectValues}
          fieldName={'status'}
          otherStyles={{ width: '100%', margin: '0 0 5px 0', height: '60px' }}
          variant={'standard'}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          text={'Сменить статус'}
          otherStyles={{
            width: '220px',
            height: '40px',
            backgroundColor: palette.blue,
            borderRadius: '10px',
            color: palette.white,
            marginRight: '1em',
            marginLeft: '1em'
          }}
          type={'submit'}
        />
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  buttons: {
    textAlign: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    }
  }
})

export default withStyles(styles)(ChangeStatus)