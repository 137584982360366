import React, { useEffect } from 'react'
import { Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/selectors/auth'
import { getCurrentUser } from 'redux/modules/auth/actions'

const Menu = ({ classes }) => {
  const menuItems = [
    {
      title: 'Уведомления',
      route: '/notifications'
    },
    {
      title: 'Специалисты',
      route: '/specialists'
    },
    {
      title: 'Пациенты',
      route: '/patients'
    },
    {
      title: 'Клиники',
      route: '/clinics'
    },
    {
      title: 'Аптека',
      route: '/pharmacies'
    },
    {
      title: 'Модераторы',
      route: '/moderators'
    },
    // {
    //   title: 'Абонементы',
    //   route: '/subscriptions'
    // },
    // {
    //   title: 'Вывод средств',
    //   route: '/cash-requests'
    // },
    {
      title: 'Приемы',
      route: '/receptions'
    },
    {
      title: 'Второе мнение',
      route: '/second-opinion'
    },
    {
      title: 'Промокоды',
      route: '/promocodes'
    }
  ]

  const menuItemsCall = [
    {
      title: 'Специалисты',
      route: '/specialists'
    },
    {
      title: 'Пациенты',
      route: '/patients'
    },
    {
      title: 'Приемы',
      route: '/receptions'
    },
    {
      title: 'Второе мнение',
      route: '/second-opinion'
    }
  ]

  const menuItemsModerator = [
    {
      title: 'Специалисты',
      route: '/specialists'
    },
    {
      title: 'Пациенты',
      route: '/patients'
    },
    {
      title: 'Клиники',
      route: '/clinics'
    },
    {
      title: 'Аптеки',
      route: '/pharmacies'
    }
  ]

  const location = useLocation()
  const router = useHistory()
  const dispatch = useDispatch()

  const logout = () => {
    localStorage.JWT_KEY = null
    router.push('/')
  }

  const user = useSelector(getUser)

  useEffect(() => {
    dispatch(getCurrentUser())
  }, [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.menuItems}>
          {user && user.status === 'admin' ? (
            <>
              {menuItems.map((item) => (
                <p
                  key={item.title}
                  onClick={() => router.push(item.route)}
                  className={`${classes.item} ${
                    location.pathname === item.route ? classes.activeItem : ''
                  }`}
                >
                  {item.title}
                </p>
              ))}
            </>
          ) : (
            <></>
          )}
          {user && user.status === 'call' ? (
            <>
              {menuItemsCall.map((item) => (
                <p
                  key={item.title}
                  onClick={() => router.push(item.route)}
                  className={`${classes.item} ${
                    location.pathname === item.route ? classes.activeItem : ''
                  }`}
                >
                  {item.title}
                </p>
              ))}
            </>
          ) : (
            <></>
          )}
          {user && user.status === 'moderator' ? (
            <>
              {menuItemsModerator.map((item) => (
                <p
                  key={item.title}
                  onClick={() => router.push(item.route)}
                  className={`${classes.item} ${
                    location.pathname === item.route ? classes.activeItem : ''
                  }`}
                >
                  {item.title}
                </p>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={classes.logout} onClick={() => logout()}>
        <p className={classes.logoutText}>Выход</p>
      </div>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '18%',
    minHeight: '100vh',
    backgroundColor: palette.backdrop,
    justifyContent: 'space-between',
    zIndex: 1
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  menuItems: {
    marginTop: '3em',
    width: '100%'
  },
  item: {
    color: palette.white,
    fontSize: '1.1em',
    margin: '1em 0',
    padding: '0.5em 2em',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeItem: {
    backgroundColor: palette.blueLight
  },
  logout: {
    marginBottom: '2em',
    backgroundColor: palette.blueLight,
    width: '100%',
    padding: '0.5em 0',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  logoutText: {
    color: palette.white,
    marginRight: '0.5em',
    fontSize: '1.3em',
    paddingLeft: '2em'
  }
})

export default withStyles(styles)(Menu)
