import React, { useState } from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import Button from 'components/Button'
import ImportStatus from './ImportStatus'
import moment from 'moment'
import TreeViewList from './TreeView'
import { useSelector, useDispatch } from 'react-redux'
import { getLoadResultInfo } from 'redux/selectors/patients'
import {
  deleteLastLoad,
  getLoadResult,
  getPatients
} from 'redux/modules/patients/actions'
import { getPatientsPagination } from 'redux/selectors/patients'

const LastImportInfo = ({ isShowDrawer = false, setIsShowDrawer, classes }) => {
  const dispatch = useDispatch()

  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const deleteLastImport = () => {
    setIsLoadingDelete(true)
    dispatch(
      deleteLastLoad(
        {},
        () => {
          dispatch(getLoadResult())
          setIsShowDrawer(false)
          setIsLoadingDelete(false)
          dispatch(
            getPatients(
              {},
              () => {},
              () => {},
              {
                page: 1,
                count: patientsPagination.perPage,
                phrase: '',
                source: ''
              }
            )
          )
        },
        () => {
          setIsLoadingDelete(false)
        }
      )
    )
  }

  const info = useSelector(getLoadResultInfo)

  const patientsPagination = useSelector(getPatientsPagination)

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={isShowDrawer}
      onClose={() => setIsShowDrawer(false)}
      onOpen={() => setIsShowDrawer(true)}
    >
      {info && (
        <div className={classes.wrapper}>
          <h1 className={classes.title}>Последний импорт</h1>
          <p className={classes.info}>
            <span className={classes.infoTitle}>Статус:</span>{' '}
            <ImportStatus status={info.status} />
          </p>
          <p className={classes.info}>
            <span className={classes.infoTitle}>Дата:</span>{' '}
            <span>{moment(info.createdAt).format('DD.MM.YYYY')}</span>
          </p>
          <p className={classes.info}>
            <span className={classes.infoTitle}>Время:</span>{' '}
            <span>{info.createdAt.split('T')[1].substring(0, 5)}</span>
          </p>
          <p className={classes.info}>
            <span className={classes.infoTitle}>Добавлено пользователей:</span>{' '}
            <span>{info.newUsers}</span>
          </p>
          <p className={classes.info}>
            <span className={classes.infoTitle}>Обновлено пользователей:</span>{' '}
            <span>{info.updatedUsers}</span>
          </p>
          <h1 className={`${classes.title} ${classes.changes}`}>
            Измененые данные
          </h1>
          <TreeViewList
            data={info.loadInfo.deletedUsers}
            title={`Удаленные пользователи: ${info.loadInfo.deletedUsers.length}`}
          />
          <h1 className={`${classes.title} ${classes.errorTitle}`}>Ошибки</h1>
          <p className={`${classes.info} ${classes.errorDesc}`}>
            <span className={classes.infoTitle}>Описание ошибки:</span>{' '}
            <span>
              {info.loadInfo.description === 'headers'
                ? 'Колонки таблицы имеют неверный формат'
                : info.loadInfo.description === 'duplicate'
                ? 'Есть дублирующие записи'
                : 'Загружено без ошибок'}
            </span>
          </p>

          {info.loadInfo.description !== 'nothing' && (
            <TreeViewList
              data={info.loadInfo.duplicateInns}
              title={`Дубликаты ИНН: ${info.loadInfo.duplicateInns.length}`}
            />
          )}

          {info.loadInfo.description !== 'nothing' && (
            <TreeViewList
              data={info.loadInfo.duplicatePhones}
              title={`Дубликаты телефонов: ${info.loadInfo.duplicatePhones.length}`}
            />
          )}

          {info.loadInfo.description !== 'nothing' && (
            <TreeViewList
              data={info.loadInfo.failedCreate}
              title={`Не созданные пользователи: ${info.loadInfo.failedCreate.length}`}
            />
          )}

          <Button
            text={'Удалить'}
            otherStyles={{
              width: '270px',
              height: '45px',
              backgroundColor: palette.red,
              color: palette.white
            }}
            onClick={() => deleteLastImport()}
            loader={isLoadingDelete}
          />
        </div>
      )}
    </SwipeableDrawer>
  )
}

const styles = () => ({
  wrapper: {
    margin: '1em',
    minWidth: 500
  },
  status: {
    color: 'green'
  },
  info: {
    padding: '0.5em 0',
    display: 'flex',
    alignItems: 'center'
  },
  infoTitle: {
    marginRight: '0.4em'
  },
  title: {
    marginBottom: '0.5em',
    marginTop: '0.5em',
    fontSize: '1.3em'
  },
  errorTitle: {
    marginTop: '1.5em'
  },
  errorDesc: {
    marginBottom: '0.4em'
  },
  changes: {
    marginBottom: '0.5em'
  }
})

export default withStyles(styles)(LastImportInfo)
