import {
  makeRequest,
  makeSimpleRequest,
  createRequestStatuses
} from 'redux/utils/redux-utils'
import {
  _getPatients,
  _createPatient,
  _editPatient,
  _deletePatient,
  _getPatentReceptions,
  _uploadUsersTable,
  _getLoadResult,
  _deleteLastLoad
} from 'api/http/patients'

export const SET_PATIENTS = createRequestStatuses('SET_PATIENTS')
export const SET_PATIENT_RECEPTIONS = createRequestStatuses(
  'SET_PATIENT_RECEPTIONS'
)

export const getPatients = (data, callbackSuccess, callbackError, paging) =>
  makeRequest(
    SET_PATIENTS,
    _getPatients,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const createPatient = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_createPatient, data, callbackSuccess, callbackError)

export const editPatient = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_editPatient, data, callbackSuccess, callbackError)

export const deletePatient = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_deletePatient, data, callbackSuccess, callbackError)

export const getPatientReceprions = (
  data,
  callbackSuccess,
  callbackError,
  paging
) =>
  makeRequest(
    SET_PATIENT_RECEPTIONS,
    _getPatentReceptions,
    data,
    callbackSuccess,
    callbackError,
    paging
  )

export const uploadUsersTable = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_uploadUsersTable, data, callbackSuccess, callbackError)

export const SET_LOAD_RESULT = createRequestStatuses('SET_LOAD_RESULT')

export const getLoadResult = (data, callbackSuccess, callbackError) =>
  makeRequest(
    SET_LOAD_RESULT,
    _getLoadResult,
    data,
    callbackSuccess,
    callbackError
  )

export const deleteLastLoad = (data, callbackSuccess, callbackError) =>
  makeSimpleRequest(_deleteLastLoad, data, callbackSuccess, callbackError)
