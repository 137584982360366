import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import Button from 'components/Button'
import Input from 'components/Input'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { editReceptionFields } from 'redux/modules/receptions/actions'

const editComplaintsSchema = Yup.object().shape({
  complaints: Yup.string().nullable()
})

const Complaints = ({ classes, onClose, reception }) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      complaints: reception.complaints
    },
    validationSchema: editComplaintsSchema,
    onSubmit: (values, { setFieldError }) => {
      dispatch(
        editReceptionFields(
          {
            complaints: values.complaints,
            receptionId: reception.id
          },
          (data) => {
            onClose(true)
          },
          (error) => {
            console.log('error', error)
            setFieldError('complaints', 'Ошибка при сохранении')
          }
        )
      )
    }
  })

  return (
    <form onSubmit={formik.handleSubmit} className={classes.root}>
      <Input
        formik={formik}
        label={'Жалоба'}
        fieldName={'complaints'}
        otherStyles={{ width: '100%' }}
      />
      <div className={classes.button}>
        <Button
          text={'Сохранить'}
          type="submit"
          fullWidth
          otherStyles={{
            width: '300px',
            height: '40px',
            backgroundColor: palette.blue,
            color: palette.white,
            borderRadius: '10px',
            margin: '10px'
          }}
        />
      </div>
    </form>
  )
}

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px'
  }
})

export default withStyles(styles)(Complaints)
