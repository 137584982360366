import axios from '../index'

export const _getClaims = (data, paging) =>
  axios.get(
    encodeURI(
      `/claims/?page=${paging.page}&count=${paging.count}`
    ),
    data
  )

export const _editClaim = (data, paging) => axios.patch(`/claims/${data.id}`, data)