import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { palette } from 'styles/colors'
import * as Yup from 'yup'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Input from 'components/Input'
import Button from 'components/Button'
import generator from 'generate-password'

const specSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, 'Пароль слишком короткий')
    .max(20, 'Пароль слишком длинный')
})

const ResetPassword = ({ classes, handleSubmit, editedObj }) => {
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      login: editedObj.login,
      password: generator.generate({
        length: 10,
        numbers: true
      }),
      fullName: editedObj.fullName,
      status: editedObj.status
    },
    validationSchema: specSchema,
    onSubmit: (...props) => handleSubmit(...props)
  })

  return (
    <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
      <div className={classes.field}>
        <Typography className={classes.title} variant="subtitle1">
          Пароль:
        </Typography>
        <Input
          formik={formik}
          fieldName={'password'}
          variant={'standard'}
          otherStyles={{ width: '100%' }}
          type={showPassword ? 'text' : 'password'}
          standard
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                style={{ color: palette.blue }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div className={classes.buttons}>
        <Button
          text={'Сбросить пароль'}
          otherStyles={{
            width: '220px',
            height: '40px',
            backgroundColor: palette.white,
            border: `1px solid ${palette.blue}`,
            borderRadius: '10px',
            color: palette.blue,
            marginRight: '1em',
            marginLeft: '1em'
          }}
          type={'submit'}
        />
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    width: '600px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  buttons: {
    textAlign: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    }
  }
})

export default withStyles(styles)(ResetPassword)