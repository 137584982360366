import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { palette } from 'styles/colors'
import TableWrapper from 'components/Table'

const useStyles = makeStyles({
  root: {
    maxWidth: 400
  },
  item: {
    margin: '0.5em 0',
    borderBottom: '1px solid grey',
    padding: '0.3em'
  },
  heading: {
    backgroundColor: palette.panelBackground
  },
  border: {
    border: `1px solid ${palette.panelBorder}`
  }
})

const columns = [
  {
    id: 'phone',
    label: 'Телефон',
    align: 'center'
  },
  {
    id: 'inn',
    label: 'ИНН',
    align: 'center'
  },
  {
    id: 'num',
    label: 'Строка',
    align: 'center'
  },
  {
    id: 'fullName',
    label: 'ФИО',
    align: 'center'
  }
]

const TreeViewList = ({ data, title }) => {
  const classes = useStyles()

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={`${classes.heading} ${classes.border}`}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.border}>
        {data.length > 0 && (
          <div className={classes.tableWrapper}>
            <TableWrapper
              items={data}
              columns={columns}
              tableType={'importPatients'}
            />
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default TreeViewList
