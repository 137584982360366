import React from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import editIcon from 'assets/edit.png'
import deleteIcon from 'assets/delete.png'

const Courses = ({
  classes,
  courses,
  openDelete,
  openEditCourse
}) => {
  let type = ''

  return courses.map((course, index) => (
    <TableRow key={index} className={classes.wrapper}>
      <TableCell
        style={{ width: '150px', borderColor: palette.black }}
        align="center"
      >
        {course.courseName}
      </TableCell>
      <TableCell
        style={{ width: '150px', borderColor: palette.black }}
        align="center"
      >
        {course.year}
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() =>
            openEditCourse({ ...course }, index, type = 'courses')
          }
        >
          <img src={editIcon} alt="edit" width="15" />
        </IconButton>
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openDelete(index, type = 'courses')
          }}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({
  // wrapper: {
  //   width: '70%',
  // }
})

export default withStyles(styles)(Courses)